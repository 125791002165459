const timeZones = [
  'Pacific/Midway',
  'Pacific/Honolulu',
  'America/Adak',
  'Pacific/Marquesas',
  'America/Anchorage',
  'America/Tijuana',
  'America/Vancouver',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Ojinaga',
  'America/Denver',
  'America/Guatemala',
  'America/Winnipeg',
  'America/Chicago',
  'Pacific/Easter',
  'America/Mexico_City',
  'America/Regina',
  'America/Bogota',
  'America/Cancun',
  'America/New_York',
  'America/Port-au-Prince',
  'America/Havana',
  'America/Asuncion',
  'America/Halifax',
  'America/Caracas',
  'America/Manaus',
  'America/Puerto_Rico',
  'America/Santiago',
  'America/Grand_Turk',
  'America/St_Johns',
  'America/Sao_Paulo',
  'America/Cayenne',
  'America/Argentina/Buenos_Aires',
  'America/Nuuk',
  'America/Montevideo',
  'America/Miquelon',
  'America/Noronha',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Europe/Dublin',
  'Africa/Monrovia',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Brussels',
  'Europe/Sarajevo',
  'Africa/Bangui',
  'Africa/Casablanca',
  'Africa/Windhoek',
  'Asia/Amman',
  'Europe/Athens',
  'Asia/Beirut',
  'Africa/Cairo',
  'Asia/Damascus',
  'Asia/Hebron',
  'Africa/Harare',
  'Europe/Helsinki',
  'Asia/Jerusalem',
  'Europe/Kaliningrad',
  'Africa/Tripoli',
  'Asia/Baghdad',
  'Europe/Istanbul',
  'Asia/Kuwait',
  'Europe/Minsk',
  'Europe/Moscow',
  'Africa/Nairobi',
  'Asia/Tehran',
  'Asia/Dubai',
  'Europe/Samara',
  'Asia/Baku',
  'Indian/Mauritius',
  'Asia/Tbilisi',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Asia/Tashkent',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Kolkata',
  'Asia/Colombo',
  'Asia/Kathmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Yangon',
  'Asia/Novosibirsk',
  'Asia/Bangkok',
  'Asia/Hovd',
  'Asia/Shanghai',
  'Asia/Irkutsk',
  'Asia/Kuala_Lumpur',
  'Australia/Perth',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Asia/Pyongyang',
  'Australia/Eucla',
  'Asia/Chita',
  'Asia/Tokyo',
  'Asia/Seoul',
  'Asia/Chita',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Sydney',
  'Pacific/Guam',
  'Asia/Vladivostok',
  'Australia/Lord_Howe',
  'Pacific/Bougainville',
  'Asia/Sakhalin',
  'Pacific/Norfolk',
  'Pacific/Guadalcanal',
  'Asia/Kamchatka',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Pacific/Chatham',
  'Pacific/Tongatapu',
  'Pacific/Midway',
  'Pacific/Kiritimati',
];

export default timeZones;
