import styled from 'styled-components';

import { StyledH1, StyledP } from './elements/typography';
import StyledCard from './StyledCard';

const StyledAnnouncementCard = { ...StyledCard };

StyledAnnouncementCard.Container = styled(StyledCard.Container)`
  margin-bottom: 0;
  min-height: 0;
`;

StyledAnnouncementCard.Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span[role='img'] {
    font-size: 1.5rem;
  }
`;

StyledAnnouncementCard.H1 = styled(StyledH1)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-width: 400px;
  padding-bottom: 0;
`;

StyledAnnouncementCard.SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.3rem 0 1rem;

  ${StyledP} {
    margin-bottom: 0.25rem;
    max-width: 450px;
  }

  span[role='img'] {
    font-size: 1.5rem;
  }
`;

export default StyledAnnouncementCard;
