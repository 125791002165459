import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkDirective from 'remark-directive';

import User from '+/models/User';
import { StyledHr } from '+/styledComponents/elements/decorations';
import { H1, H2, H3, H4, H5, Li, Ol, P, Strong, Ul } from '+/styledComponents/elements/markdown';
import { StyledInTextLink, StyledInTextAnchor } from '+/styledComponents/StyledLinks';
import PropTypes from '+/instances/propTypesInstance';
import TextReader from '+/utils/TextReader';
import directiveParser from './directiveParser';

const { t } = new TextReader();

const OlorUl = ({ ordered, children }) => (ordered ? <Ol>{children}</Ol> : <Ul>{children}</Ul>);

OlorUl.propTypes = {
  ordered: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const blankA = (props) => {
  const isExternal = /^https?:\/\//.test(props.href);

  return isExternal ? (
    <StyledInTextAnchor {...props} href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </StyledInTextAnchor>
  ) : (
    <StyledInTextLink {...props} to={props.href}>
      {props.children}
    </StyledInTextLink>
  );
};

blankA.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

const Heading = ({ level, children }) => {
  const headings = { H1, H2, H3, H4, H5 };
  const H = headings[`H${1 + level}`];
  return <H padded>{children}</H>;
};

Heading.propTypes = {
  level: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

const interpolate = (source, currentUser) => {
  if (!currentUser) return source;
  return (source || '')
    .replace(/%firstName/g, currentUser.firstName)
    .replace(/%fullName/g, currentUser.name())
    .replace(/%today/g, t('-days')[new Date().getDay()]);
};
export { interpolate };

// eslint-disable-next-line react/prop-types
export const passChildren = Renderer => ({ children }) => <Renderer>{children}</Renderer>;

const defaultRenderers = {
  // eslint-disable-next-line react/prop-types
  paragraph: passChildren(P),
  list: OlorUl,
  listItem: passChildren(Li),
  strong: passChildren(Strong),
  link: blankA,
  heading: Heading,
  thematicBreak: passChildren(StyledHr),
  textDirective: directiveParser,
};

export const Markdown = ({ source, renderers, currentUser }) => (
  <ReactMarkdown plugins={[remarkDirective]} renderers={{ ...defaultRenderers, ...renderers }}>
    {interpolate(source, currentUser)}
  </ReactMarkdown>
);

Markdown.propTypes = {
  source: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  renderers: PropTypes.object,
  currentUser: PropTypes.models.User,
};

Markdown.defaultProps = {
  renderers: {},
  currentUser: new User(),
};
