import React from 'react';

const LazySetInitialPreferences = React.lazy(() => import('./SetInitialPreferences.container.js'));
const SuspendedSetInitialPreferences = () => (
  <React.Suspense
    fallback={
      <div />
    }
  >
    <LazySetInitialPreferences />
  </React.Suspense>
);
export default SuspendedSetInitialPreferences;
