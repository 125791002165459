import SubRegionsStore from '+/models/SubRegionsStore';

const defaultStore = { all: new SubRegionsStore([]) };

// eslint-disable-next-line default-param-last
const subRegionsStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
    case 'SET_ALL_SUB_REGIONS': {
      const subRegionsData = action.payload;
      const newSubRegions = new SubRegionsStore(subRegionsData);
      Object.assign(newStore, { all: newSubRegions });
      break;
    }
    case 'ADD_NEW_SUB_REGION': {
      const subRegionsData = [...store.all.store, action.payload];
      const newSubRegions = new SubRegionsStore(subRegionsData);
      Object.assign(newStore, { all: newSubRegions });
      break;
    }
    default:
  }
  return newStore;
};

export default subRegionsStore;
