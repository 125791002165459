//
// Used in the redux and components to organize tag related data and provide helper methods.
//

import Tag from './Tag';

class TagsStore {
  constructor(tagsData) {
    this.loading = !tagsData || tagsData.loading;
    this.store = ((tagsData && tagsData.store) || tagsData || []).map(datum => new Tag(datum));
  }

  isEmpty() {
    return this.store.length === 0;
  }

  dup() {
    return new TagsStore(this);
  }

  first() {
    return this.store[0];
  }

  map(func) {
    return this.store.map(func);
  }

  filter(func) {
    return this.store.filter(func);
  }

  find(id) {
    return this.store.find(tag => tag.id === id);
  }

  remove(tag) {
    this.store = this.filter(element => element.name !== tag.name);
  }

  namesById(ids) {
    return this.filter(tag => ids.indexOf(tag.id)).map(tag => tag.name);
  }
}

export default TagsStore;
