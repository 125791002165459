// ====================================================================
// Theme Settings (CSS)
// ====================================================================

// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
//
// It extends _themeBase and is extended by _theme
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  // Font Families
  fontSourceSansPro: '\'Source Sans Pro\', sans-serif',
  fontMuli: 'Muli, sans-serif',
  fontOswald: 'Oswald, sans-serif',

  // Colors
  colorDarkGrey: '#3B3C43',
  colorLightGrey: '#C3C3C3',
  colorMediumLightGrey: '#d3d3d3',
});

