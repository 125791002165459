const resultsFrom = async (fetchPromise) => {
  try {
    // All responses expected in JSON.
    const json = await fetchPromise.json();
    if ([200, 201].includes(fetchPromise.status)) {
      return json.results || json;
    } 
    return { errors: json.errors || ['Something went wrong!'] };
    
  } catch (error) {
    return { errors: ['Something went wrong!'] };
  }
};

// Can either take a single value, to be inserted at the :id in the url,
// or an object of keys and values
export const buildGET = url => async (identifier) => {
  try {
    const keys = Object.keys(identifier || {});
    const transformedUrl =
      typeof identifier === 'string' || identifier instanceof String ?
        url.replace(':id', identifier) :
        keys.reduce((accum, key) => accum.replace(`:${key}`, identifier[key]), url);
    const response = await fetch(transformedUrl, { credentials: 'include' });
    return resultsFrom(response);
  } catch (error) {
    return error;
  }
};

export const buildPOST = url => async (data, keys = {}) => {
  let modifiedUrl = url;
  Object.keys(keys).forEach((key) => {
    modifiedUrl = modifiedUrl.replace(`:${key}`, keys[key]);
  });

  try {
    const response = await fetch(modifiedUrl, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    });
    return resultsFrom(response);
  } catch (error) {
    return error;
  }
};

export const buildPATCH = url => async (id, data, keys = {}) => {
  let modifiedUrl = url.replace(':id', id);
  Object.keys(keys).forEach((key) => {
    modifiedUrl = modifiedUrl.replace(`:${key}`, keys[key]);
  });
  try {
    const response = await fetch(modifiedUrl, {
      method: 'PATCH',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return resultsFrom(response);
  } catch (error) {
    return error;
  }
};

export const buildDELETE = url => async (id) => {
  try {
    const response = await fetch(url.replace(':id', id), {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    });
    return resultsFrom(response);
  } catch (error) {
    return error;
  }
};
