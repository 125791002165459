import React from 'react';

const LazyAdminRouter = React.lazy(() => import('./AdminRouter.container.js'));
const SuspendedAdminRouter = () => (
  <React.Suspense
    fallback={
      <div />
    }
  >
    <LazyAdminRouter />
  </React.Suspense>
);
export default SuspendedAdminRouter;
