import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';

import { App } from '+/components/App';
import store from '+/utils/store';

import appConfig from './appConfig';
// import registerServiceWorker, { unregister } from "./registerServiceWorker";
import registerServiceWorker from './registerServiceWorker';

WebFont.load({ google: { families: appConfig.googleFonts } });

const appWrapper = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(appWrapper, document.getElementById('root'));
registerServiceWorker();
// unregister();
