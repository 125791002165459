// ====================================================================
// Theme Settings Assignments (CSS)
// ====================================================================
//
// This file contains assignments of the base palette to specific roles.
//
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  // Theme-based colors
  colorBackground: variables.colorWhite,
  colorBackgroundShade: variables.colorLightGrey,
  colorPrimary: variables.colorYellow,
  colorDarkPrimary: variables.colorDarkYellow,
  colorDanger: variables.colorRed,
  colorDarkDanger: variables.colorDarkRed,
  colorWarning: variables.colorMediumGrey,
  colorDarkWarning: variables.colorBlack,
  colorSecondary: variables.colorMediumGrey,
  colorDarkSecondary: variables.colorBlack,
  colorDisabled: variables.colorLightGrey,
  colorDarkDisabled: variables.colorGrey,
  colorAccent: variables.colorRed,
  colorPrimaryInvert: variables.colorWhite,

  fontReadable: variables.fontOpenSans,
  fontFormal: variables.fontMontserrat,

  // Text colors
  textColorDefault: variables.colorDarkGrey,
  textColorPrimary: variables.colorDarkGrey, // On top of primary color
  textColorDanger: variables.colorWhite, // On top of danger color
  textColorWarning: variables.colorWhite, // On top of warning color
  textColorSecondary: variables.colorWhite, // On top of secondary color
  textColorDisabled: variables.colorFadedDarkGrey,
  textColorFaded: variables.colorGrey,
  textColorExtraFaded: variables.colorLightGrey,

  // Font sizes

  fontSizeParagraphSmall: variables.sizeTiny,
  fontSizeParagraphRegular: variables.sizeSmall,
  fontSizeParagraphLarge: variables.sizeRegular,
  fontSizeHeadingTiny: variables.sizeTiny,
  fontSizeHeadingSmall: variables.sizeRegularPlus,
  fontSizeHeadingRegular: variables.sizeLarge,
  fontSizeHeadingLarge: variables.sizeVeryLarge,
  fontSizeHeadingHuge: variables.sizeHuge,
  fontSizeHeadingTitanic: variables.sizeVeryHuge,
});
