/*
 * This module puts children into a special component included in a separate DOM
 * node under a separate root div. It does this in order to handle z-index issues.
 *
 * It puts the content in a styled box by default. This can be removed with noBox prop.
 * It closes the modal when a user clicks outside of the modal.
 * This can be removed with forceManualClsoe prop.
 * It accepts a triggerHolder prop that is an object with 'open' and 'close' keys.
 * It adds a func to these keys that can be used to open and close the modals.
 * These can be passed into the props of the children,
 * or into other components to trigger the modals.
 */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import FocusTrap from 'focus-trap-react';

import PropTypes from '+/instances/propTypesInstance';
import StyledModal from '+/styledComponents/StyledModal';

const modalContainerVariants = {
  hidden: { opacity: 0, transition: { duration: 0.1 } },
  visible: { opacity: 1, transition: { duration: 0.1 } },
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.7, transition: { duration: 0.2 } },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.2, delay: 0.1, type: 'spring' },
  },
};

const ModalBox = ({ 'data-idf': dataIdf, narrow, wide, children, close, closable }) => (
  <StyledModal.Box
    narrow={narrow}
    wide={wide}
    data-idf={dataIdf}
    variants={modalVariants}
    as={motion.div}
    initial="hidden"
    animate="visible"
    exit="hidden"
  >
    {closable && (
      <StyledModal.CloseButton data-idf="button-close-modal" onClick={close}>
        &times;
      </StyledModal.CloseButton>
    )}
    {children}
  </StyledModal.Box>
);

ModalBox.propTypes = {
  children: PropTypes.node.isRequired,
  'data-idf': PropTypes.string.isRequired,
  close: PropTypes.func,
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
  closable: PropTypes.bool,
};

ModalBox.defaultProps = {
  close: null,
  narrow: false,
  wide: false,
  closable: true,
};

export class Modal extends Component {
  constructor(props) {
    super();
    this.state = { opened: props.open || false };
    this.triggerHolder = props.triggerHolder || {};
    this.triggerHolder.open = this.open;
    this.triggerHolder.close = this.close;
    this.el = document.createElement('div');
    this.modalRoot = document.querySelector('#modal-root');
  }

  componentDidMount() {
    if (this.modalRoot) this.modalRoot.appendChild(this.el);
    if (this.state.opened) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      if (document.getElementById('root')) {
        document.getElementById('root').tabIndex = -1;
      }
    }
    this.updateViewportHeight();
    window.addEventListener('resize', this.updateViewportHeight.bind(this));
  }

  componentWillUnmount() {
    // This is needed to keep the DOM clean as these are added and removed.
    if (this.modalRoot) this.modalRoot.removeChild(this.el);
    document.documentElement.style.overflow = 'unset';
    document.body.style.overflow = 'unset';
    if (document.getElementById('root')) {
      document.getElementById('root').removeAttribute('tabindex');
    }
    window.removeEventListener('resize', this.updateViewportHeight.bind(this));
  }

  render() {
    // Skip createPortal on tests due to lack of support.
    if (typeof jest !== 'undefined') return null;
    return ReactDOM.createPortal(this.renderModalContainer(), this.el);
  }

  renderModalContainer() {
    return (
      <AnimatePresence exitBeforeEnter>
        <FocusTrap active={this.state.opened}>
          <StyledModal.Container
            variants={modalContainerVariants}
            as={motion.div}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="modalContainer"
          >
            {this.state.opened && (
              <StyledModal.Overlay
                onClick={this.props.forceManualClose && !this.props.closable ? null : this.close}
              />
            )}
            {this.renderModal()}
          </StyledModal.Container>
        </FocusTrap>
      </AnimatePresence>
    );
  }

  renderModal() {
    if (!this.state.opened) return null;
    const Wrapper = this.props.noBox ? StyledModal.NoBox : ModalBox;
    return (
      <Wrapper
        close={this.close}
        narrow={this.props.narrow}
        wide={this.props.wide}
        data-idf="modal"
        closable={this.props.closable}
        variants={modalVariants}
        as={motion.div}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {this.props.children}
      </Wrapper>
    );
  }

  updateViewportHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  open = () => {
    if (this.state.opened) return;
    this.setState({ opened: true });
  };

  close = () => {
    this.setState({ opened: false });
    if (this.props.onClose) this.props.onClose();
  };
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  noBox: PropTypes.bool,
  open: PropTypes.bool,
  forceManualClose: PropTypes.bool,
  triggerHolder: PropTypes.shape({ open: PropTypes.func }),
  narrow: PropTypes.bool,
  wide: PropTypes.bool,
  closable: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: null,
  noBox: false,
  open: false,
  forceManualClose: false,
  triggerHolder: {},
  narrow: false,
  wide: false,
  closable: true,
};

export default Modal;
