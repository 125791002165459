import styled, { keyframes } from 'styled-components';

import themeVars from '+/utils/themeVars';
import media from '+/utils/media';

const StyledAnnouncementBar = {};

const EnterAnimation = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

StyledAnnouncementBar.Container = styled.div`
  padding: 0.875rem 3rem 0.875rem 1.25rem;
  width: 100%;
  background: ${themeVars('announcementBarBackground')};
  text-align: center;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.16);
  animation: ${EnterAnimation} 0.5s 0.5s ${themeVars('easeOutExpo')} both;
`;

StyledAnnouncementBar.Message = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${themeVars('fontMontserrat')};
  font-size: 1rem;
  letter-spacing: 0.01em;
  line-height: 1.3;
  text-transform: uppercase;
`;

StyledAnnouncementBar.TeaserShort = styled.span`
  ${media.md`
    display: none;
`}
`;

StyledAnnouncementBar.TeaserLong = styled.span`
  display: none;

  ${media.md`
    display: inline;
`}
`;

StyledAnnouncementBar.DismissButton = styled.button`
  padding: 0 0.5rem;
  position: absolute;
  right: 0.625rem;
  top: 50%;

  background: none;
  border: 0;

  transform: translateY(-50%);

  font-size: 1.875rem;
  line-height: 1;

  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;

StyledAnnouncementBar.Link = styled.span`
  color: inherit;
  font-weight: 700;
  text-decoration: underline;

  cursor: pointer;
`;

export default StyledAnnouncementBar;
