import { useSelector } from 'react-redux';
import * as actions from '+/actions';
import * as apiCalls from '+/utils/apiCalls';
import useFetch from './useFetch';

const fetchRoomData = async(dispatch, path) => {
  const roomData = await apiCalls.getRoom(path);
  if (roomData.errors) return;
  dispatch(actions.setRoom(roomData, path));
};

const useRoom = (path, refetch = false) => {
  const room = useSelector(store => store.rooms.individual[path]);

  const shouldFetch = refetch || !room || (room.isEmpty && room.isEmpty());
  useFetch(fetchRoomData, shouldFetch, path);

  return room;
};

export default useRoom;
