import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import Action from '+/models/Action';
import ActionsStore from '+/models/ActionsStore';
import ActionTypeTemplate from '+/models/ActionTypeTemplate';
import ActionTypeTemplatesStore from '+/models/ActionTypeTemplatesStore';
import Announcement from '+/models/Announcement';
import AnnouncementsStore from '+/models/AnnouncementsStore';
import Campaign from '+/models/Campaign';
import CampaignsStore from '+/models/CampaignsStore';
import Dismissal from '+/models/Dismissal';
import Message from '+/models/Message';
import ToastsStore from '+/models/ToastsStore';
import MessagesStore from '+/models/MessagesStore';
import PushSubscriptionsStore from '+/models/PushSubscriptionsStore';
import Tag from '+/models/Tag';
import TagsStore from '+/models/TagsStore';
import TakenAction from '+/models/TakenAction';
import TakenActionsStore from '+/models/TakenActionsStore';
import User from '+/models/User';
import Room from '+/models/Room';
import RoomsStore from '+/models/RoomsStore';
import Region from '+/models/Region';
import RegionsStore from '+/models/RegionsStore';
import SubRegion from '+/models/SubRegion';
import SubRegionsStore from '+/models/SubRegionsStore';
import SiteConfig from '+/models/SiteConfig';
import UsersStore from '+/models/UsersStore';
import UpdateForApproval from '+/models/UpdateForApproval';
import UpdatesForApprovalStore from '+/models/UpdatesForApprovalStore';
import EveryActionError from '+/models/EveryActionError';
import EveryActionErrorsStore from '+/models/EveryActionErrorsStore';

PropTypes.models = {};
PropTypes.objects = {};

PropTypes.yupValidators = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array,
});

PropTypes.location = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
});

PropTypes.match = PropTypes.object;

PropTypes.objects.actionProfile = PropTypes.shape({
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
});

PropTypes.objects.actionShape = PropTypes.shape({
  actionType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  enabled: PropTypes.bool,
  tags: PropTypes.arrayOf(Tag),
});

PropTypes.objects.actionTypeTemplateShape = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  inputLabel: PropTypes.string,
  iconSVGUrl: PropTypes.string,
  iconSVGCode: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  actionPhrase: PropTypes.string,
  preferenceActionPhrase: PropTypes.string,
});

PropTypes.objects.roomShape = PropTypes.shape({
  name: PropTypes.string,
  path: PropTypes.string,
});

PropTypes.objects.regionShape = PropTypes.shape({
  name: PropTypes.string,
  subRegions: PropTypes.arrayOf(PropTypes.number),
});

PropTypes.objects.subRegionShape = PropTypes.shape({
  name: PropTypes.string,
  regions: PropTypes.arrayOf(PropTypes.number),
});

PropTypes.objects.siteConfigShape = PropTypes.shape({
  dailyActionEmailSendTime: PropTypes.string,
  sendActionAlerts: PropTypes.bool,
  emailSenderName: PropTypes.string,
  emailSenderGenericName: PropTypes.string,
  emailSenderAddress: PropTypes.string,
  userGroups: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  defaultUserGroup: PropTypes.string,
});

PropTypes.objects.auditShape = PropTypes.shape({
  userId: PropTypes.number,
  changes: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    from: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    to: PropTypes.object,
  }),
});

PropTypes.models.User = PropTypes.instanceOf(User);
PropTypes.models.Room = PropTypes.instanceOf(Room);
PropTypes.models.RoomsStore = PropTypes.instanceOf(RoomsStore);
PropTypes.models.Region = PropTypes.instanceOf(Region);
PropTypes.models.RegionsStore = PropTypes.instanceOf(RegionsStore);
PropTypes.models.SubRegion = PropTypes.instanceOf(SubRegion);
PropTypes.models.SubRegionsStore = PropTypes.instanceOf(SubRegionsStore);
PropTypes.models.SiteConfig = PropTypes.instanceOf(SiteConfig);
PropTypes.models.ActionTypeTemplate = PropTypes.instanceOf(ActionTypeTemplate);
PropTypes.models.ActionTypeTemplatesStore = PropTypes.instanceOf(ActionTypeTemplatesStore);
PropTypes.models.Tag = PropTypes.instanceOf(Tag);
PropTypes.models.Dismissal = PropTypes.instanceOf(Dismissal);
PropTypes.models.Campaign = PropTypes.instanceOf(Campaign);
PropTypes.models.UsersStore = PropTypes.instanceOf(UsersStore);
PropTypes.models.Action = PropTypes.instanceOf(Action);
PropTypes.models.TakenAction = PropTypes.instanceOf(TakenAction);
PropTypes.models.Announcement = PropTypes.instanceOf(Announcement);
PropTypes.models.TagsStore = PropTypes.instanceOf(TagsStore);
PropTypes.models.MessagesStore = PropTypes.instanceOf(MessagesStore);
PropTypes.models.TakenActionsStore = PropTypes.instanceOf(TakenActionsStore);
PropTypes.models.ActionsStore = PropTypes.instanceOf(ActionsStore);
PropTypes.models.AnnouncementsStore = PropTypes.instanceOf(AnnouncementsStore);
PropTypes.models.CampaignsStore = PropTypes.instanceOf(CampaignsStore);
PropTypes.models.RegionsStore = PropTypes.instanceOf(RegionsStore);
PropTypes.models.Message = PropTypes.instanceOf(Message);
PropTypes.models.PushSubscriptionsStore = PropTypes.instanceOf(PushSubscriptionsStore);
PropTypes.models.ToastsStore = PropTypes.instanceOf(ToastsStore);
PropTypes.models.UpdatesForApprovalStore = PropTypes.instanceOf(UpdatesForApprovalStore);
PropTypes.models.UpdateForApproval = PropTypes.instanceOf(UpdateForApproval);
PropTypes.models.EveryActionError = PropTypes.instanceOf(EveryActionError);
PropTypes.models.EveryActionErrorsStore = PropTypes.instanceOf(EveryActionErrorsStore);

PropTypes.history = ReactRouterPropTypes.history;

export default PropTypes;
