import React from 'react';

import { StyledLoadingIndicator } from '+/styledComponents/StyledLoadingIndicator';

import TextReader from '+/utils/TextReader';

const { t } = new TextReader('actionFeed');

export const LoadingMessage = () => <StyledLoadingIndicator aria-label={t('loadingMessageText')} />;

LoadingMessage.propTypes = {};
