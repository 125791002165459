import FrontEndStore from './FrontEndStore';
import UpdateForApproval from './UpdateForApproval';

export default class UpdatesForApprovalStore extends FrontEndStore {
  constructor(updateForApprovalData) {
    super(updateForApprovalData);
    this.storeType = UpdatesForApprovalStore;
    this.store = updateForApprovalData ?
      updateForApprovalData.store ||
        updateForApprovalData.map(d => new UpdateForApproval(d)) :
      [];
  }
}
