import FrontEndModel from './FrontEndModel';
import User from './User';

export default class UpdatesForApproval extends FrontEndModel {
  constructor(data) {
    super(data);
    this.type = 'updatesForApproval';
    this.creator = new User(data.creator);
  }
}
