import React from 'react';

import gtm from '+/instances/googleTagManager';
import PropTypes from '+/instances/propTypesInstance';
import appConfig from '+/appConfig';
import { capitalize } from '+/models/modelHelpers';
import { explodeIcons } from '+/utils/animations';

class Button extends React.PureComponent {
  render() {
    const {
      primary,
      secondary,
      danger,
      muted,
      warning,
      action,
      leftIcon,
      rightIcon,
      inlineIcon,
      block,
      type,
      clear,
      micro,
      small,
      large,
      checkToggle,
      ...otherProps
    } = this.props;
    return (
      <button
        {...otherProps}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={this.handleClick}
      />
    );
  }
  // handlers

  handleClick = e => {
    const { 'data-idf': dataIdf, action, user, onClick, celebrate } = this.props;

    if (celebrate) {
      const buttonElement = e.currentTarget;
      const buttonLocation = buttonElement.getBoundingClientRect();
      const buttonCenterX = buttonLocation.left + buttonElement.offsetWidth / 2;
      const buttonCenterY = buttonLocation.top + buttonElement.offsetHeight / 2;
      explodeIcons(appConfig.animationIcon, buttonCenterX, buttonCenterY);
    }

    if (onClick) onClick(e);
    gtm.push({
      elementType: 'Button',
      event: 'Click',
      element: dataIdf
        .replace('button-', '')
        .split('-')
        .map(w => capitalize(w))
        .join(' '),
      actionId: action && action.identifier(),
      userId: user && user.identifier(),
    });
  };
}

Button.propTypes = {
  'data-idf': PropTypes.string,
  action: PropTypes.models.Action,
  user: PropTypes.models.User,
  onClick: PropTypes.func,
  warning: PropTypes.bool,
  block: PropTypes.bool,
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
  inlineIcon: PropTypes.bool,
  clear: PropTypes.bool,
  micro: PropTypes.bool,
  checkToggle: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  muted: PropTypes.bool,
  celebrate: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  'data-idf': 'unknown',
  action: null,
  user: null,
  onClick: null,
  warning: null,
  block: null,
  leftIcon: null,
  rightIcon: null,
  inlineIcon: null,
  clear: null,
  micro: null,
  large: null,
  small: null,
  secondary: null,
  checkToggle: null,
  primary: null,
  danger: null,
  muted: null,
  celebrate: null,
  type: 'button',
};

export default Button;
