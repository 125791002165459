import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Room } from './Room.component';
import * as actions from '+/actions';

import useRoom from '+/utils/hooks/useRoom';
import useDisplayedActionsForRoom from '+/utils/hooks/useDisplayedActionsForRoom';

const mapDispatchToProps = dispatch => ({
  addActionsDisplayedForRoom: (pathAndActions) => {
    dispatch(actions.addDisplayedActionsForRoom(pathAndActions));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(
  withRouter((props) => {
    const { roomPath } = props.match.params;
    const room = useRoom(roomPath);
    const displayedActionsForRoom = useDisplayedActionsForRoom(roomPath);
    return <Room {...props} room={room} displayedActionsForRoom={displayedActionsForRoom} />;
  }),
);
