import { formatDate, truncate } from './modelHelpers';

class Message {
  constructor(messageData) {
    Object.assign(this, messageData);
  }

  createdAtString() {
    return new Date(Date.parse(this.createdAt)).toDateString();
  }

  createdAtStringLong() {
    return formatDate(new Date(Date.parse(this.createdAt)));
  }

  seenAtStringLong() {
    return formatDate(new Date(Date.parse(this.seenAt)));
  }

  shortSubject() {
    return truncate(this.subject, 50);
  }

  dup() {
    return new Message(this);
  }
}

export default Message;
