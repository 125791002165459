const defaultStore = {
  user: [],
};

const badgesStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
  case 'SET_USER_BADGES': {
    const badges = action.payload;
    Object.assign(newStore, { user: badges });
    break;
  }
  default:
  }
  return newStore;
};

export default badgesStore;
