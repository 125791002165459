import React, { Component } from 'react';
import { FaTrophy } from 'react-icons/fa';

import appConfig from '+/appConfig';
import NavLink from '+/components/Links/NavLink';
import PropTypes from '+/instances/propTypesInstance';
import TextReader from '+/utils/TextReader';
import { path } from '+/utils/pathFinder';
import StyledHeader from '+/styledComponents/StyledHeader';
import { SlideOutMenu } from '+/components/SlideOutMenu';
import { AwardList } from '+/components/AwardList';
import { Modal } from '+/components/Modal';
import { StyledStatsBlock, StyledStatItem } from '+/styledComponents/StyledStatsBlock';

const { t } = new TextReader('header');

export class Header extends Component {
  constructor() {
    super();
    this.state = { condensed: true, showAwardDetails: false };
  }

  componentDidMount() {
    this.setDocumentScrollbar();
  }

  componentDidUpdate() {
    this.setDocumentScrollbar();
  }

  render() {
    return (
      <StyledHeader.Container sticky={!this.routeHasTabs()}>
        {this.isPlain() ? (
          this.renderLogoLink()
        ) : (
          <>
            {this.isSignedIn() && [this.renderBurgerMenu(), this.renderLogoIcon()]}
            {this.isSignedIn() || this.renderLogoLink()}
            {this.isSignedIn() && this.renderStatBlock()}
            {this.isSignedIn() && this.renderSlideOutMenu()}
            {this.isSignedIn() && [this.renderAwardButton(), this.renderAwardDetails()]}
          </>
        )}
      </StyledHeader.Container>
    );
  }

  // PARTIALS

  renderBurgerMenu() {
    return (
      <StyledHeader.BurgerMenu
        key="Burger Menu"
        onClick={this.toggleCondensed}
        aria-label={t('bugerMenuLabel')}
      >
        <StyledHeader.BurgerFilling />
      </StyledHeader.BurgerMenu>
    );
  }

  renderLogoIcon() {
    const linkLocation = this.props.currentUser.isEmpty() ? path('signIn') : path('home');
    return (
      // React wants this to have a key for some reason
      <StyledHeader.LogoIcon key="Logo Icon" to={linkLocation}>
        <StyledHeader.LogoMobile aria-labelledby="app-title" src={appConfig.headerIcon} />
        <StyledHeader.LogoDesktop aria-labelledby="app-title" src={appConfig.headerLogo} />
        <StyledHeader.HiddenTitle id="app-title">
          {`${appConfig.organizationTitle} ${appConfig.siteTitle}`}
        </StyledHeader.HiddenTitle>
      </StyledHeader.LogoIcon>
    );
  }

  renderSlideOutMenu() {
    return (
      <SlideOutMenu
        key="Slide Out Menu"
        toggleCondensed={this.toggleCondensed}
        isOpen={!this.state.condensed}
        areUnseenMessages={this.props.areUnseenMessages}
        handleShowAwardDetails={this.handleShowAwardDetails}
      />
    );
  }

  // renderHomeButton() {
  //   return (
  //     <NavLink to={path('home')} key="home" data-idf="link-header-home">
  //       <StyledHeader.FlatLink>{t('homeLinkText')}</StyledHeader.FlatLink>
  //     </NavLink>
  //   );
  // }

  renderLogoLink() {
    const linkLocation = this.props.currentUser.isEmpty() ? path('signIn') : path('home');
    return (
      <StyledHeader.IconBox>
        <NavLink to={linkLocation}>
          <StyledHeader.Icon src={appConfig.headerLogo} />
          <StyledHeader.HiddenTitle>
            {`${appConfig.organizationTitle} ${appConfig.siteTitle}`}
          </StyledHeader.HiddenTitle>
        </NavLink>
      </StyledHeader.IconBox>
    );
  }

  renderStatBlock() {
    const { userPointSum, currentStreakCount } = this.props;
    return (
      <StyledHeader.StatBlock key="Stat Block">
        <StyledStatsBlock noWrap>
          <StyledStatItem
            key={t('stats.streak')}
            label={t('stats.streak')}
            number={currentStreakCount}
          />
          <StyledStatItem
            key={t('stats.action')}
            label={t('stats.action')}
            number={typeof userPointSum !== 'undefined' ? userPointSum : 0}
          />
        </StyledStatsBlock>
      </StyledHeader.StatBlock>
    );
  }

  renderAwardButton() {
    return (
      <StyledHeader.IconButton
        key="Award Button"
        onClick={this.handleShowAwardDetails}
        aria-label={t('awards')}
        title={t('awards')}
      >
        <FaTrophy size="1.5rem" />
      </StyledHeader.IconButton>
    );
  }

  renderAwardDetails() {
    if (this.state.showAwardDetails) {
      return (
        <Modal key="Award Details" open wide onClose={this.handleCloseAwardDetails}>
          <AwardList />
        </Modal>
      );
    }
    return null;
  }

  // HANDLERS

  handleShowAwardDetails = e => {
    e.preventDefault();
    this.setState({ showAwardDetails: true });
  };

  handleCloseAwardDetails = () => {
    setTimeout(() => this.setState({ showAwardDetails: false }), 200);
  };

  // HELPERS

  toggleCondensed = () => {
    this.setState(prevState => ({ condensed: !prevState.condensed }));
  };

  setDocumentScrollbar = () => {
    const overflow = this.state.condensed ? 'unset' : 'hidden';
    document.documentElement.style.overflow = overflow;
    document.body.style.overflow = overflow;
  };

  isPlain = () => {
    const { forcePlain } = this.props;
    return !this.isSignedIn() || forcePlain;
  };

  isSignedIn = () => this.props.currentUser.isLoaded();

  // isAdmin = () => this.isSignedIn() && this.props.currentUser.isAdmin();

  routeHasTabs = () => {
    const pathsWithTabs = [
      path('preferences'),
      path('notifications'),
      path('signUp'),
      path('signIn'),
    ];

    return pathsWithTabs.some(pathLocation => window.location.pathname.includes(pathLocation));
  };
}

Header.propTypes = {
  currentUser: PropTypes.models.User.isRequired,
  userPointSum: PropTypes.number.isRequired,
  currentStreakCount: PropTypes.number.isRequired,
  forcePlain: PropTypes.bool,
  areUnseenMessages: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  forcePlain: false,
};
