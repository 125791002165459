// Takes Campaign data from server and stores it in object with helper methods for accessing it.

import FrontEndModel from './FrontEndModel';

export default class Campaign extends FrontEndModel {
  constructor(campaignData) {
    super({ parentCampaignId: null, ...campaignData });
  }

  isEnabled() {
    return this.status === 'active';
  }

  wasWon() {
    return this.status === 'victory';
  }

  isParent() {
    return !this.parentCampaignId;
  }
}
