import ActionTypeTemplatesStore from '+/models/ActionTypeTemplatesStore';

const defaultStore = {
  all: new ActionTypeTemplatesStore(),
};

const actionTypeTemplatesStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
  case 'SET_ALL_ACTION_TYPE_TEMPLATES': {
    const actionTypeTemplatesData = action.payload;
    const newActionTypeTemplatesStore = new ActionTypeTemplatesStore(actionTypeTemplatesData);
    Object.assign(newStore, { all: newActionTypeTemplatesStore });
    break;
  }
  case 'UPDATE_ACTION_TYPE_TEMPLATE': {
    const actionTypeTemplateData = action.payload;
    const newAllActionTypeTemplates = store.all.dup();
    newAllActionTypeTemplates.updateActionTypeTemplate(actionTypeTemplateData);
    Object.assign(newStore, { all: newAllActionTypeTemplates });
    break;
  }
  case 'SIGN_OUT_USER': {
    Object.assign(newStore, {
      all: new ActionTypeTemplatesStore(),
    });
    break;
  }
  default:
  }
  return newStore;
};

export default actionTypeTemplatesStore;
