/**
 * Exports Sub-region Store class
 * @module SubRegionsStore
 */

import SubRegion from './SubRegion';
import FrontEndStore from './FrontEndStore';

export default class SubRegionsStore extends FrontEndStore {
  constructor(subRegionData) {
    super();
    this.status = subRegionData ? 'loaded' : 'unloaded';
    this.store = ((subRegionData && subRegionData.store) || subRegionData || []).map(
      c => new SubRegion(c),
    );
    this.store.sort((a, b) => a.name.localeCompare(b.name));
    this.storeType = SubRegionsStore;
  }

  all() {
    return this.store;
  }

  filter(options) {
    let subRegions = this.all();
    if (options.search !== '') {
      subRegions = subRegions.filter(subRegion => subRegion.contentMatches(options.search));
    }
    return new SubRegionsStore(subRegions);
  }
}
