export const signInUser = payload => ({
  payload,
  type: 'SIGN_IN_USER',
});

export const addDisplayedActions = () => ({ type: 'ADD_DISPLAYED_ACTIONS' });

export const addDisplayedActionsForRoom = payload => ({
  payload,
  type: 'ADD_DISPLAYED_ACTIONS_FOR_ROOM',
});

export const addNewRegion = payload => ({
  payload,
  type: 'ADD_NEW_REGION',
});

export const addNewSubRegion = payload => ({
  payload,
  type: 'ADD_NEW_SUB_REGION',
});

export const addNewTag = payload => ({
  payload,
  type: 'ADD_NEW_TAG',
});

export const addToast = payload => ({
  payload,
  type: 'ADD_TOAST',
});

export const addUserPushSubscription = payload => ({
  payload,
  type: 'ADD_USER_PUSH_SUBSCRIPTION',
});

export const addUpdateForApproval = payload => ({
  payload,
  type: 'ADD_UPDATE_FOR_APPROVAL',
});

export const clearDuplicatedAction = () => ({ type: 'CLEAR_DUPLICATED_ACTION' });

export const completeEligibleAction = (actionType, actionId) => ({
  payload: { actionType, actionId },
  type: 'COMPLETE_ELIGIBLE_ACTION',
});

export const removeEligibleAction = (actionType, actionId) => ({
  payload: { actionType, actionId },
  type: 'REMOVE_ELIGIBLE_ACTION',
});

export const removeEligibleAnnouncement = payload => ({
  payload,
  type: 'REMOVE_ELIGIBLE_ANNOUNCEMENT',
});

export const removeTag = payload => ({
  payload,
  type: 'REMOVE_TAG',
});

export const removeUpdateForApproval = payload => ({
  payload,
  type: 'REMOVE_UPDATE_FOR_APPROVAL',
});

export const setAction = (data, actionType, actionId) => ({
  payload: { actionId, actionType, data },
  type: 'SET_ACTION',
});

export const setAllActionTypeTemplates = payload => ({
  payload,
  type: 'SET_ALL_ACTION_TYPE_TEMPLATES',
});

export const setAllActions = payload => ({
  payload,
  type: 'SET_ALL_ACTIONS',
});

export const setAllAnnouncements = payload => ({
  payload,
  type: 'SET_ALL_ANNOUNCEMENTS',
});

export const setAllCampaigns = payload => ({
  payload,
  type: 'SET_ALL_CAMPAIGNS',
});

export const setAllEveryActionErrors = payload => ({
  payload,
  type: 'SET_ALL_EA_ERRORS',
});

export const setAllMessages = payload => ({
  payload,
  type: 'SET_ALL_MESSAGES',
});

export const setAllRegions = payload => ({
  payload,
  type: 'SET_ALL_REGIONS',
});

export const setAllSubRegions = payload => ({
  payload,
  type: 'SET_ALL_SUB_REGIONS',
});

export const setAllRooms = payload => ({
  payload,
  type: 'SET_ALL_ROOMS',
});

export const setAllTags = payload => ({
  payload,
  type: 'SET_ALL_TAGS',
});

export const setAllTakenActions = payload => ({
  payload,
  type: 'SET_ALL_TAKEN_ACTIONS',
});

export const setAllUpdatesForApproval = payload => ({
  payload,
  type: 'SET_ALL_UPDATES_FOR_APPROVAL',
});

export const setAllUsers = payload => ({
  payload,
  type: 'SET_ALL_USERS',
});

export const setDuplicatedAction = payload => ({
  payload,
  type: 'SET_DUPLICATED_ACTION',
});

export const setEligibleActions = payload => ({
  payload,
  type: 'SET_ELIGIBLE_ACTIONS',
});

export const setEligibleAnnouncements = payload => ({
  payload,
  type: 'SET_ELIGIBLE_ANNOUNCEMENTS',
});

export const setRoom = (data, id) => ({
  payload: { id, data },
  type: 'SET_ROOM',
});

export const setSiteConfig = payload => ({
  payload,
  type: 'SET_SITE_CONFIG',
});

export const setUpdateForApproval = (data, id) => ({
  payload: { id, data },
  type: 'SET_UPDATE_FOR_APPROVAL',
});

export const setUserBadges = payload => ({
  payload,
  type: 'SET_USER_BADGES',
});

export const setUserPushSubscriptions = payload => ({
  payload,
  type: 'SET_USER_PUSH_SUBSCRIPTIONS',
});

export const setUserTakenActionStats = payload => ({
  payload,
  type: 'SET_USER_TAKEN_ACTION_STATS',
});

export const setUserTakenActions = payload => ({
  payload,
  type: 'SET_USER_TAKEN_ACTIONS',
});

export const setUserTripleEligibility = payload => ({
  payload,
  type: 'SET_USER_TRIPLE_ELIGIBILITY',
});

export const signOutUser = () => ({ type: 'SIGN_OUT_USER' });

export const uncompleteEligibleAction = (actionType, actionId) => ({
  payload: { actionType, actionId },
  type: 'UNCOMPLETE_ELIGIBLE_ACTION',
});

export const updateAction = payload => ({
  payload,
  type: 'UPDATE_ACTION',
});

export const updateActionTypeTemplate = payload => ({
  payload,
  type: 'UPDATE_ACTION_TYPE_TEMPLATE',
});

export const updateAnnouncement = payload => ({
  payload,
  type: 'UPDATE_ANNOUNCEMENT',
});

export const updateCampaign = payload => ({
  payload,
  type: 'UPDATE_CAMPAIGN',
});

export const updateEveryActionError = payload => ({
  payload,
  type: 'UPDATE_EA_ERROR',
});

export const updateMessage = payload => ({
  payload,
  type: 'UPDATE_MESSAGE',
});

export const updateUser = payload => ({
  payload,
  type: 'UPDATE_USER',
});

export const updateSingleUser = payload => ({
  payload,
  type: 'UPDATE_SINGLE_USER',
});

export const updateUserGroup = payload => ({
  payload,
  type: 'UPDATE_USER_GROUP',
});

export const updateUserPermissions = payload => ({
  payload,
  type: 'UPDATE_USER_PERMISSIONS',
});

export const removeToast = payload => ({
  payload,
  type: 'REMOVE_TOAST',
});
