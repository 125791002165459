import { combineReducers } from 'redux';

import actions from './actions';
import announcements from './announcements';
import badges from './badges';
import pushSubscriptions from './pushSubscriptions';
import rooms from './rooms';
import regions from './regions';
import subRegions from './subRegions';
import takenActions from './takenActions';
import toasts from './toasts';
import user from './user';
import actionTypeTemplates from './actionTypeTemplates';

export const baseReducers = {
  actionTypeTemplates,
  actions,
  announcements,
  badges,
  pushSubscriptions,
  regions,
  rooms,
  subRegions,
  takenActions,
  toasts,
  user,
};

const rootReducer = combineReducers(baseReducers);

export default rootReducer;
