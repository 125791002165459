import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import StyledButton from '+/styledComponents/StyledButtons';
import { AnnouncementBar } from '+/components/AnnouncementBar';
import StyledRow from '+/styledComponents/StyledRow';
import { ActionFeed } from '+/components/ActionFeed';
import TextReader from '+/utils/TextReader';
import { path } from '+/utils/pathFinder';

const { t } = new TextReader('mainActionFeed');

export class MainActionFeed extends PureComponent {
  // REACT LIFECYCLE

  render() {
    const { currentUser, removeEligibleAnnouncement, displayedActions } = this.props;

    return (
      <>
        {this.shouldDisplayAnnouncement() && (
          <AnnouncementBar
            removeEligibleAnnouncement={removeEligibleAnnouncement}
            announcement={this.announcementToDisplay}
            currentUser={currentUser}
            key={this.announcementToDisplay}
          />
        )}
        <ActionFeed basePath="" returnPath={path('home')} actions={displayedActions} />
        <StyledRow padded center key="moreButton">
          {this.renderGetMoreButton()}
        </StyledRow>
      </>
    );
  }

  // PARTIALS

  renderGetMoreButton() {
    const { displayedActions, eligibleActions, addDisplayedActions } = this.props;
    if (displayedActions.totalActionsCount() >= eligibleActions.unsnoozed().totalActionsCount()) {
      return null;
    }
    return (
      <StyledButton primary onClick={addDisplayedActions} data-idf="button-get-more-actions">
        {t('getMoreActionsButtonText')}
      </StyledButton>
    );
  }

  // HELPERS

  // TODO refactor to not stick announcement onto this.
  shouldDisplayAnnouncement() {
    const { eligibleAnnouncements } = this.props;
    this.announcementToDisplay = this.announcementToDisplay || eligibleAnnouncements.latest();
    const hasEligibleAnnouncements =
      this.announcementToDisplay && eligibleAnnouncements.includes(this.announcementToDisplay);
    return hasEligibleAnnouncements;
  }
}

MainActionFeed.propTypes = {
  eligibleActions: PropTypes.models.ActionsStore.isRequired,
  displayedActions: PropTypes.models.ActionsStore.isRequired,
  eligibleAnnouncements: PropTypes.models.AnnouncementsStore.isRequired,
  removeEligibleAnnouncement: PropTypes.func.isRequired,
  addDisplayedActions: PropTypes.func.isRequired,
  currentUser: PropTypes.models.User.isRequired,
};
