import EveryActionError from './EveryActionError';

class EveryActionErrorsStore {
  constructor(everyActionErrorsData) {
    this.loading = !everyActionErrorsData || everyActionErrorsData.loading;
    this.store = (
      (everyActionErrorsData && (everyActionErrorsData.store || everyActionErrorsData)) ||
      []
    ).map(datum => new EveryActionError(datum));
  }

  isEmpty() {
    return this.store.length === 0;
  }

  find(everyActionErrorId) {
    for (const everyActionError of this.store) {
      if (everyActionError.id === everyActionErrorId) return everyActionError;
    }
    return false;
  }

  dup() {
    return new EveryActionErrorsStore(this.store);
  }

  map(func) {
    return this.store.map(func);
  }

  updateEveryActionError(everyActionErrorData) {
    const everyActionError = this.find(everyActionErrorData.id);
    Object.assign(everyActionError, everyActionErrorData);
  }

  anyUnseen() {
    return this.store.some(everyActionError => !everyActionError.seenAt);
  }

  sorted() {
    return this.store.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  }
}

export default EveryActionErrorsStore;
