import { getDateRangeString } from '+/utils/timeZonesHelper';

export default class Announcement {
  constructor(data) {
    this.tags = [];
    this.type = 'announcement';
    Object.assign(this, data);
    if (this.startTime) this.startTime = new Date(this.startTime);
    if (this.endTime) this.endTime = new Date(this.endTime);
    // Updated at froze to allow sorting by updated at with in-place updating.
    this.frozenUpdatedAt = this.frozenUpdatedAt || this.updatedAt;
  }

  isActive() {
    return (
      this.enabled &&
      (!this.startTime || this.startTime < new Date()) &&
      (!this.endTime || this.endTime > new Date())
    );
  }

  dateRangeString() {
    return getDateRangeString(this.startTime, this.endTime, this.timeZone);
  }

  createdAtString() {
    return new Date(Date.parse(this.createdAt)).toDateString();
  }

  updatedAtString() {
    return new Date(Date.parse(this.updatedAt)).toDateString();
  }

  copyToDuplicate() {
    const newAnnouncement = new Announcement({ ...this });
    newAnnouncement.title = `Copy of ${newAnnouncement.title}`;
    newAnnouncement.id = 'duplicate';
    return newAnnouncement;
  }

  update(newData) {
    Object.assign(this, newData);
    if (this.startTime) this.startTime = new Date(this.startTime);
    if (this.endTime) this.endTime = new Date(this.endTime);
  }
}
