import { path, getPathPermission } from '+/utils/pathFinder';

export default (currentUser) => {
  const landingPathCookie = document.cookie.split(';').find(c => c.startsWith(' landingPath'));
  const landingPath = landingPathCookie && landingPathCookie.split('=')[1];

  if (landingPath) return landingPath;
  if (currentUser) return currentUser.homePath();
  const requiresSignin = !['any', 'loggedOut'].includes(getPathPermission(window.location.pathname));
  if (requiresSignin) return path('signin');
  return null;
};

export const setLandingPath = () => {
  const expire = new Date();
  expire.setTime(expire.getTime() + 10 * 60 * 1000);
  document.cookie = `landingPath=${window.location.pathname};expires=${expire.toUTCString()};path=/`;
};

export const deleteLandingPathIfOnIt = () => {
  const landingPathCookie = document.cookie.split(';').find(c => c.startsWith(' landingPath'));
  const landingPath = landingPathCookie && landingPathCookie.split('=')[1];

  if (window.location.pathname === landingPath) {
    document.cookie = 'landingPath=;expires=;path=/';
  }
};
