import React, { PureComponent } from 'react';
import Cookies from 'js-cookie';

import { Markdown } from '+/components/Markdown';
import { interpolate } from '+/components/Markdown/Markdown.component';
import PropTypes from '+/instances/propTypesInstance';
import StyledAnnouncementBar from '+/styledComponents/StyledAnnouncementBar';
import { StyledH4 } from '+/styledComponents/elements/typography';
import TextReader from '+/utils/TextReader';
import { postDismissal } from '+/utils/apiCalls';
import { notifyError } from '+/utils/toastsManager';
import { Modal } from '+/components/Modal';

const { t } = new TextReader('announcementBar');

export class AnnouncementBar extends PureComponent {
  constructor() {
    super();
    this.state = {
      showDetails: false,
    };
  }
  componentDidMount() {
    const newAnnouncement =
      parseInt(Cookies.get('seen-annoucement'), 10) !== this.props.announcement.id &&
      this.props.autoLaunchNewModal;
    // Delay modal to allow notification bar to animate in
    setTimeout(() => this.setState({ showDetails: newAnnouncement }), 1000);
  }
  render() {
    const { announcement, currentUser } = this.props;
    return (
      <StyledAnnouncementBar.Container>
        <StyledAnnouncementBar.Message>
          {announcement.teaserTextShort && (
            <StyledAnnouncementBar.TeaserShort>
              {interpolate(announcement.teaserTextShort, currentUser)}
            </StyledAnnouncementBar.TeaserShort>
          )}
          {announcement.teaserTextLong && (
            <StyledAnnouncementBar.TeaserLong>
              {interpolate(announcement.teaserTextLong, currentUser)}
            </StyledAnnouncementBar.TeaserLong>
          )}{' '}
          <StyledAnnouncementBar.Link onClick={this.handleShowDetails} role="button">
            {t('learnMore')}
          </StyledAnnouncementBar.Link>
        </StyledAnnouncementBar.Message>
        {this.renderDismissButton()}
        {this.renderAnnouncementDetails()}
      </StyledAnnouncementBar.Container>
    );
  }

  // PARTIALS

  renderAnnouncementDetails() {
    const { announcement, currentUser } = this.props;
    if (this.state.showDetails) {
      Cookies.set('seen-annoucement', announcement.id, { expires: 365 });
      return (
        <Modal open onClose={this.handleCloseModal}>
          <StyledH4>{interpolate(announcement.title, currentUser)}</StyledH4>
          <Markdown source={announcement.body} currentUser={currentUser} />
        </Modal>
      );
    }
    return null;
  }
  renderDismissButton() {
    return (
      <StyledAnnouncementBar.DismissButton
        data-idf="button-dismiss-announcement"
        onClick={this.handleDismiss}
        aria-label={t('dismissTooltipText')}
      >
        &times;
      </StyledAnnouncementBar.DismissButton>
    );
  }

  // HANDLERS

  handleShowDetails = (e) => {
    e.preventDefault();
    this.setState({ showDetails: true });
  };

  handleCloseModal = () => {
    setTimeout(() => this.setState({ showDetails: false }), 200);
  };

  handleDismiss = async() => {
    const { id, type } = this.props.announcement;
    const postResponse = postDismissal({ dismissableId: id, dismissableType: type });
    if (postResponse.errors) {
      notifyError(postResponse);
    } else {
      this.props.removeEligibleAnnouncement(id);
    }
  };
}

AnnouncementBar.propTypes = {
  currentUser: PropTypes.models.User.isRequired,
  announcement: PropTypes.models.Announcement.isRequired,
  removeEligibleAnnouncement: PropTypes.func.isRequired,
  autoLaunchNewModal: PropTypes.bool,
};

AnnouncementBar.defaultProps = {
  autoLaunchNewModal: true,
};
