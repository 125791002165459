import '../../styles/all.scss';

import Cookies from 'js-cookie';
import React, { Component } from 'react';

import gtm from '+/instances/googleTagManager';
import Theme from '+/instances/Theme';
import { notifyError, notifySuccess } from '+/utils/toastsManager';
import { AppRouter } from '+/components/AppRouter';
import { Toasts } from '+/components/Toasts';
import GlobalStyles from '+/styledComponents/StyledGlobalStyles';
import Animations from '+/styledComponents/StyledAnimations';

export class App extends Component {
  // REACT LIFECYCLE

  componentDidMount() {
    gtm.initializeGTM();
    // Print out any messages on page load.
    if (Cookies.get('errors')) {
      notifyError({ errors: [Cookies.get('errors')] });
      Cookies.remove('errors');
    }

    if (Cookies.get('flash')) {
      notifySuccess(Cookies.get('flash'));
      Cookies.remove('flash');
    }
  }

  // HELPER METHODS

  render() {
    return (
      <Theme dark>
        <GlobalStyles />
        <Animations />
        <Toasts />
        <AppRouter />
      </Theme>
    );
  }
}

// Prevents copy data from including styles or background colors.
document.addEventListener('copy', e => {
  const textContent = document.getSelection().toString() ||
                      e.target.value.substring(e.target.selectionStart, e.target.selectionEnd);
  if (e.clipboardData) {
    e.clipboardData.setData('text/plain', textContent);
    e.preventDefault();
  }
});
