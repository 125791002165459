import { SignOut } from './SignOut.component';
import { connect } from 'react-redux';

import * as actions from '+/actions';

const mapDispatchToProps = dispatch => ({
  signOutUser: () => dispatch(actions.signOutUser()),
});


export default connect(null, mapDispatchToProps)(SignOut);
