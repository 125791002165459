import React from 'react';
import styled from 'styled-components';

import PropTypes from '+/instances/propTypesInstance';
import themeVars from '+/utils/themeVars';
import media from '+/utils/media';
import appConfig from '+/appConfig';

import StyledRow from './StyledRow';

const StyledStatContainer = styled.div`
  margin-left: 1rem;
  flex-basis: auto;
  flex-grow: 0;

  color: ${themeVars('textColorDefault')};

  font-family: ${themeVars('fontFormal')};
  font-size: 1rem;
  letter-spacing: 0.055em;
  line-height: 1.25rem;
  text-align: right;
`;

const StyledStatNumber = styled.div`
  font-size: 1.3125em;
  font-weight: bold;
  overflow: hidden;
  position: relative;
`;

const StyledStatLabel = styled.div`
  display: block;

  font-size: 0.5em;
  font-weight: 400;
  line-height: 1.4;
  text-align: right;
  text-transform: uppercase;
`;

export const StyledStatItem = ({ number, label }) => (
  <StyledStatContainer key={label}>
    <StyledStatNumber>{number.toLocaleString(appConfig.language)}</StyledStatNumber>
    <StyledStatLabel>{label}</StyledStatLabel>
  </StyledStatContainer>
);

StyledStatItem.propTypes = {
  number: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export const StyledStatsBlock = styled(StyledRow)`
  display: inline-flex;
  justify-content: flex-end;
  max-width: 70%;

  ${media.xs`
  max-width:100%
  `}
`;
