import React from 'react';
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaPenFancy,
  FaPhone,
  FaTwitter,
} from 'react-icons/fa';
import { BsFillMegaphoneFill } from 'react-icons/bs';

import TextReader from '+/utils/TextReader';

import Theme from '../styles/settings/default/_themeDefault';

const { t } = new TextReader('actionProfile');

const profiles = {
  email: {
    color: Theme.colorEmailAction,
    textColor: Theme.colorEmailActionText,
    displayLabel: t('email.displayLabel'),
    actionPhrase: t('email.actionPhrase'),
    icon: <FaEnvelope />,
  },
  linkedin: {
    color: Theme.colorLinkedinAction,
    textColor: Theme.colorLinkedinActionText,
    displayLabel: t('linkedin.displayLabel'),
    actionPhrase: t('linkedin.actionPhrase'),
    icon: <FaLinkedinIn />,
  },
  facebook: {
    color: Theme.colorFacebookAction,
    textColor: Theme.colorFacebookActionText,
    displayLabel: t('facebook.displayLabel'),
    actionPhrase: t('facebook.actionPhrase'),
    icon: <FaFacebookF />,
  },
  other: {
    color: Theme.colorOtherAction,
    textColor: Theme.colorOtherActionText,
    displayLabel: t('other.displayLabel'),
    actionPhrase: t('other.actionPhrase'),
    icon: <BsFillMegaphoneFill />,
  },
  petition: {
    color: Theme.colorPetitionAction,
    textColor: Theme.colorPetitionActionText,
    displayLabel: t('petition.displayLabel'),
    actionPhrase: t('petition.actionPhrase'),
    icon: <FaPenFancy />,
  },
  phone: {
    color: Theme.colorPhoneAction,
    textColor: Theme.colorPhoneActionText,
    displayLabel: t('phone.displayLabel'),
    actionPhrase: t('phone.actionPhrase'),
    icon: <FaPhone />,
  },
  twitter: {
    color: Theme.colorTwitterAction,
    textColor: Theme.TcolorTwitterActionext,
    displayLabel: t('twitter.displayLabel'),
    actionPhrase: t('twitter.actionPhrase'),
    icon: <FaTwitter />,
  },
};

export default profiles;
