import styled from 'styled-components';

import themeVars from '+/utils/themeVars';
import media from '+/utils/media';

const StyledFooter = {};

StyledFooter.Footer = styled.footer`
  padding: 30px 40px;

  background: ${themeVars('colorBlack')};

  color: white;
  font-family: ${themeVars('fontReadable')};
  font-size: 0.75rem;
  text-align: center;

  ${media.md`
    height: ${themeVars('footerHeight')};
    padding-top: 0;
    padding-bottom: 0;

    line-height: ${themeVars('footerHeight')};
  `}
`;

StyledFooter.Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 81.25rem;

  ${media.md`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

StyledFooter.Copyright = styled.p`
  margin: 0;

  opacity: 0.68;
`;

StyledFooter.Social = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 -0.5em;
  padding: 0;
  min-width: 240px;
  list-style: none;

  ${media.md`
    justify-content: flex-start;
  `}
`;

StyledFooter.SocialItem = styled.li`
  height: 1.75rem;
  margin: 0 0.5em;
  width: 1.75rem;
`;

StyledFooter.SocialAnchor = styled.a`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0.68;

  background: white;

  color: ${themeVars('colorBlack')};
  font-size: 1.3125rem;
  line-height: 1.75rem;

  transition: opacity 0.1s ease-in-out 0s;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 100%;
  }
`;

StyledFooter.LinkList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0 2rem;
  list-style: none;

  ${media.md`
    padding: 0;
  `}
`;

StyledFooter.LinkListItem = styled.li`
  a {
    display: block;
    padding: 0.66667em;

    color: inherit;

    line-height: 1.4;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default StyledFooter;
