// Takes SubRegion data from server and stores it in object with helper methods for accessing it.

import { getSearchMatches } from '+/utils/search';

import FrontEndModel from './FrontEndModel';

export default class SubRegion extends FrontEndModel {
  contentMatches(string) {
    let contentString = [this.name, this.code || ''].join(';');
    contentString += this.regions.map(region => `;${region.name};${region.code || ''}`).join('');
    return getSearchMatches(contentString, string);
  }
}
