import React from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import useCurrentUser from '+/utils/hooks/useCurrentUser';

import { App } from './App.component';

import * as actions from '+/actions';

export default withRouter((props) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const signInUser = userData => dispatch(actions.signInUser(userData));

  return <App {...props} currentUser={currentUser} signInUser={signInUser} />;
});
