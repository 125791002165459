import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';

import Button from '+/components/Button/Button';
import themeVars, { ifProp } from '+/utils/themeVars';
import media from '+/utils/media';

const StyledButton = styled(Button).attrs(props => ({ ...props }))`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin: ${ifProp({ spaced: '1em' })};
  padding: ${themeVars('buttonPadding')};
  position: relative;

  border: none;
  box-shadow: ${themeVars('buttonShadow')};
  box-sizing: border-box;

  font-family: ${themeVars('buttonFontFamily')};
  font-size: ${themeVars('buttonFontSize')};
  font-weight: ${themeVars('buttonFontWeight')};
  letter-spacing: ${themeVars('buttonLetterSpacing')};
  line-height: 1;
  text-align: center;
  text-transform: ${themeVars('buttonFontCase')};
  white-space: nowrap;

  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s;

  &:hover {
    box-shadow: ${themeVars('buttonShadowHover')};
  }

  ${themeVars('buttonNotch') &&
  css`
    &:after {
      bottom: 0px;
      display: block;
      height: 0px;
      position: absolute;
      right: 0px;
      width: 0px;

      border-bottom: ${ifProp({ micro: '14px', small: '20px', default: '24px' })} solid
        ${themeVars('buttonBorderColor')};
      border-left: ${ifProp({ micro: '14px', small: '20px', default: '24px' })} solid transparent;

      content: '';
    }
  `};

  &[disabled] {
    background: ${themeVars('colorDisabled')};
    color: ${themeVars('textColorDisabled')};
    cursor: not-allowed;

    &:hover {
      background: ${themeVars('colorDisabled')};
      color: ${themeVars('textColorDisabled')};
    }

    ${themeVars('buttonNotch') &&
    css`
      &:after {
        border-color: transparent transparent ${themeVars('colorDarkDisabled')} transparent;
      }
    `};
  }

  ${ifProp({
    primary: css`
      background-color: ${themeVars('buttonBackgroundPrimary')};
      color: ${themeVars('buttonColorPrimary')};
      border: ${themeVars('buttonBorderPrimary')} ${themeVars('buttonBorderColorPrimary')};

      &:hover {
        background-color: ${themeVars('buttonBackgroundHoverPrimary')};
        color: ${themeVars('buttonColorHoverPrimary')};
        border-color: ${themeVars('buttonBorderColorHoverPrimary')};
      }
      ${themeVars('buttonNotch') &&
      css`
        &:after {
          border-bottom-color: ${themeVars('buttonBorderColorPrimary')};
        }
      `};
    `,
    danger: css`
      background-color: ${themeVars('buttonBackgroundDanger')};
      color: ${themeVars('buttonColorDanger')};
      border: ${themeVars('buttonBorderDanger')} ${themeVars('buttonBorderColorDanger')};

      &:hover {
        background-color: ${themeVars('buttonBackgroundHoverDanger')};
        color: ${themeVars('buttonColorHoverDanger')};
        border-color: ${themeVars('buttonBorderColorHoverDanger')};
      }

      ${themeVars('buttonNotch') &&
      css`
        &:after {
          border-bottom-color: ${themeVars('buttonBorderColorDanger')};
        }
      `};
    `,
    muted: css`
      background-color: ${themeVars('buttonBackgroundMuted')};
      color: ${themeVars('buttonColorMuted')};
      border: ${themeVars('buttonBorderMuted')} ${themeVars('buttonBorderColorMuted')};

      &:hover {
        background-color: ${themeVars('buttonBackgroundHoverMuted')};
        color: ${themeVars('buttonColorHoverMuted')};
        border-color: ${themeVars('buttonBorderColorHoverMuted')};
      }

      ${themeVars('buttonNotch') &&
      css`
        &:after {
          border-bottom-color: ${themeVars('buttonBorderColorMuted')};
        }
      `};
    `,
    warning: css`
      background-color: ${themeVars('buttonBackgroundWarning')};
      color: ${themeVars('buttonColorWarning')};
      border: ${themeVars('buttonBorderWarning')} ${themeVars('buttonBorderColorWarning')};

      &:hover {
        background-color: ${themeVars('buttonBackgroundHoverWarning')};
        color: ${themeVars('buttonColorHoverWarning')};
        border-color: ${themeVars('buttonBorderColorHoverWarning')};
      }

      ${themeVars('buttonNotch') &&
      css`
        &:after {
          border-bottom-color: ${themeVars('buttonBorderColorWarning')};
        }
      `};
    `,
    secondary: css`
      background-color: ${themeVars('buttonBackgroundSecondary')};
      color: ${themeVars('buttonColorSecondary')};
      border: ${themeVars('buttonBorderSecondary')} ${themeVars('buttonBorderColorSecondary')};

      &:hover {
        background-color: ${themeVars('buttonBackgroundHoverSecondary')};
        color: ${themeVars('buttonColorHoverSecondary')};
        border-color: ${themeVars('buttonBorderColorHoverSecondary')};
      }

      ${themeVars('buttonNotch') &&
      css`
        &:after {
          border-bottom-color: ${themeVars('buttonBorderColorSecondary')};
        }
      `};
    `,
    clear: css`
      background: transparent;
      color: ${themeVars('textColorDefault')};

      &:hover {
        background-color: transparent;
      }

      &:after {
        display: none;
      }
    `,
    $clearWithColor: css`
      background: transparent;
      color: ${themeVars('colorDarkPrimary')};
      text-decoration: underline;
      &:hover {
        background-color: transparent;
        color: ${themeVars('colorPrimary')};
      }
      &:after {
        display: none;
      }
      &:focus {
        outline: none;
      }
    `,
  })}

  ${ifProp({
    micro: css`
      height: ${themeVars('buttonHeightMicro')};
      padding: 0;
      font-family: ${themeVars('fontReadable')};
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
    `,
    small: css`
      height: ${themeVars('buttonHeightSmall')};
      min-width: ${ifProp('autoSize', 'none', themeVars('buttonWidthSmall'))};
      font-size: 0.875rem;
      line-height: ${themeVars('buttonHeightSmall')};
    `,
    large: css`
      height: ${themeVars('buttonHeightLarge')};
      min-width: ${ifProp('autoSize', 'none', themeVars('buttonWidthLarge'))};
      line-height: ${themeVars('buttonHeightLarge')};
      ${media.xxs`
    font-size: 0.9375rem;
  `};
    `,
    default: css`
      height: ${themeVars('buttonHeight')};
      min-width: ${ifProp('autoSize', 'none', themeVars('buttonWidth'))};
      line-height: ${themeVars('buttonHeight')};
      ${media.xxs`
    font-size: 0.9375rem;
  `};
    `,
  })}

${ifProp({
    block: css`
      display: flex;
      max-width: 100%;
      width: 100%;
    `,
  })}

${ifProp({
    leftIcon: css`
      svg {
        left: 1.125rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1.5em;
      }
    `,
    rightIcon: css`
      svg {
        display: inline-block;
        margin: 0 -0.5rem 0 1rem;
        height: 1.5em;
      }
    `,
    inlineIcon: css`
      svg {
        display: inline-block;
        margin-right: 0.4em;
        height: 1em;
      }
    `,
  })}
`;

export default StyledButton;

export const StyledLinkButton = styled(StyledButton).attrs({ as: 'a' })``;

export const StyledGoogleButton = styled.a`
  display: block;
  height: 40px;
  padding: 0 20px 0 50px;
  position: relative;
  background: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  color: rgba(0, 0, 0, 0.54);
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 40px;
  text-align: left;
  white-space: nowrap;

  cursor: pointer;

  &:focus {
    outline: 3px solid rgba(66, 133, 244, 0.3);
    outline-offset: 0;
  }

  &:active {
    background: #eee;
    outline: none;
  }
`;

export const StyledGoogleButtonIcon = styled(ReactSVG)`
  display: block;
  height: 18px;
  left: 8px;
  position: absolute;
  top: 11px;
  width: 18px;
`;
