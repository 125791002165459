export const makeItRain = (icon, count = 72) => {
  for (let i = 0; i < count; i++) {
    const rainDrop = document.createElement('div');

    rainDrop.setAttribute('class', 'rain-drop');
    document.body.appendChild(rainDrop);

    setTimeout(() => {
      const randomDuration = randomIntFromInterval(1500, 2500);
      const randomDelay = randomIntFromInterval(0, 250);
      const randomY = randomIntFromInterval(50, 100);
      const randomX = randomIntFromInterval(0, 100);
      const randomSize = randomIntFromInterval(16, 72);
      const randomRotate = randomIntFromInterval(-360, 360);

      rainDrop.style.backgroundImage = `url(${icon})`;
      rainDrop.style.transitionDuration = `${randomDuration}ms`;
      rainDrop.style.transitionDelay = `${randomDelay}ms`;
      rainDrop.style.left = `${randomX}vw`;
      rainDrop.style.width = `${randomSize}px`;
      rainDrop.style.height = `${randomSize}px`;
      rainDrop.style.transform = `translateY(${randomY}vh) rotate(${randomRotate}deg) scale(0.5)`;
      rainDrop.style.opacity = 0;

      setTimeout(() => {
        rainDrop.parentElement.removeChild(rainDrop);
      }, randomDuration);
    }, 20);
  }
};

export const explodeIcons = (icon, x = 0, y = 0, count = 48) => {
  for (let i = 0; i < count; i++) {
    const heart = document.createElement('div');
    heart.setAttribute('class', 'exploding-icon');
    heart.style.left = `${x}px`;
    heart.style.top = `${y}px`;
    heart.style.backgroundImage = `url(${icon})`;
    document.body.appendChild(heart);

    setTimeout(() => {
      const randomDuration = randomIntFromInterval(750, 1500);
      const randomX = randomIntFromInterval(-0.4 * randomDuration, 0.4 * randomDuration);
      const randomY = randomIntFromInterval(-0.4 * randomDuration, 0.4 * randomDuration);
      const randomScale = (randomIntFromInterval(0, 400) + 100) / 100;
      const randomRotate = `${randomIntFromInterval(-360, 360)}deg`;
      heart.style.transitionDuration = `${randomDuration}ms`;
      heart.style.transform = `translate(${randomX}px,${randomY}px) scale(${randomScale}) rotate(${randomRotate})`;
      heart.style.opacity = 0;

      setTimeout(() => {
        heart.parentElement.removeChild(heart);
      }, randomDuration);
    }, 20);
  }
};

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
