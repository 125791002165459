import styled from 'styled-components';

import themeVars, { ifProp } from '+/utils/themeVars';

export const AvatarButton = styled.button`
  display: block;
  max-width: 150px;
  pointer-events: ${ifProp('disable', 'none', 'auto')};
  cursor: ${ifProp('disable', 'auto', 'pointer')};
  transition: 0.2s transform ${themeVars('easeOutExpo')};

  &:hover {
    transform: scale(1.1);
  }
`;

export const AvatarImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;

