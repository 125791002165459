/**
 * Exports combined custom + default site settings.
 * @module appConfig
 */

// Note, require syntax necessary here due to node babel issues.
const defaults = require('./appConfigDefault.js');
const customization = require('./appConfigCustom.js');

const calculated = Object.assign({}, defaults, customization);
if (!calculated.userGroups.includes(calculated.defaultUserGroup)) {
  throw new Error('default user type not included in user types');
}

module.exports = calculated;
