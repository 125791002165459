import styled from 'styled-components';

import { StyledP, StyledUL, StyledOL, StyledLI, StyledH2 } from './typography';

export const P = StyledP;
export const Ol = StyledOL;
export const Ul = StyledUL;
export const Li = StyledLI;
export const Strong = styled.strong``;
export const A = styled.a`
  text-decoration: underline;
`;
export const H1 = styled.h1``;
export const H2 = StyledH2;
export const H3 = styled.h3``;
export const H4 = styled.h4``;
export const H5 = styled.h5``;
