import { css } from 'styled-components';

export default css`
  /* Hides Zip & Phone fields */
  /* Dangerously reliant on existence of activeEmailAddress in forms! */
  .activeEmailAddress ~ .zip,
  .activeEmailAddress ~ .phoneNumber {
    display: none;
  }
`;
