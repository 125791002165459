import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import ActionsStore from '+/models/ActionsStore';
import AnnouncementsStore from '+/models/AnnouncementsStore';
import TakenActionsStore from '+/models/TakenActionsStore';
import User from '+/models/User';
// eslint-disable-next-line import/no-cycle -- FIXME: We should try to remove the dependency cycle here
import rootReducer from '+/reducers/base';
import RegionsStore from '+/models/RegionsStore';
import SubRegionsStore from '+/models/SubRegionsStore';

const enhancers = [];
let devTools = false;

let logger;

if (process.env.NODE_ENV === 'development') {
  devTools = true;

  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  const { devToolsEnhancerDevelopmentOnly } = require('@redux-devtools/extension') || {};

  if (devToolsEnhancerDevelopmentOnly)
    enhancers.push(devToolsEnhancerDevelopmentOnly({ autoPause: true }));

  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  logger = require('redux-logger').default;
}

const middleware = getDefaultMiddleware => [
  ...getDefaultMiddleware(),
  ...(process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk]),
];

export const getStoreMiddleware = () => [...middleware];

const startingState = {
  user: { currentUser: new User(), tripleEligible: true },
  actions: {
    all: new ActionsStore(),
    eligibleActions: new ActionsStore(),
    displayedActions: new ActionsStore(),
    displayedActionsForRoom: {},
    individual: {
      custom: {},
      email: {},
      facebook: {},
      linkedin: {},
      other: {},
      petition: {},
      phone: {},
      twitter: {},
    },
  },
  regions: {
    all: new RegionsStore([]),
  },
  subRegions: {
    all: new SubRegionsStore([]),
  },
  takenActions: {
    user: new TakenActionsStore([]),
    all: new TakenActionsStore([]),
    userPointSum: 0,
    userTakenActionCount: 0,
    sessionTakenActionCount: 0,
    currentStreakCount: 0,
    longestStreakCount: 0,
  },
  announcements: {
    all: new AnnouncementsStore(),
    eligibleAnnouncements: new AnnouncementsStore(),
  },
  badges: {
    user: [],
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: startingState,
  middleware,
  devTools,
  enhancers,
});
export const getStoreStartingState = () => ({ ...startingState });

export default store;
