import React from 'react';
import styled, { css } from 'styled-components';

import media from '+/utils/media';
import themeVars, { ifProp } from '+/utils/themeVars';
import { StyledColumn } from './elements/containers';

import PropTypes from '+/instances/propTypesInstance';
import Theme from '+/instances/Theme';

const StyledTabbedContainer = {};

StyledTabbedContainer.Tab = styled.label`
  padding: 0 1em;

  color: ${themeVars('textColorDefault')};
  font-family: ${themeVars('fontFormal')};
  font-size: .75rem;
  font-weight: 700;
  line-height: ${themeVars('tabHeight')};
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  transition: background-color .2s ease-out;

  cursor: pointer;

  ${ifProp({
    selected: css`
      color: ${themeVars('tabColorSelected')};
      background: ${themeVars('tabBackgroundSelected')};
      box-shadow: 0 2px 0 0 ${themeVars('tabColorAccent')} inset;
    `,
    default: css`
      background: ${themeVars('tabBackgroundUnselected')};
      color: ${themeVars('tabColorUnselected')};

      &:hover {
        background-color: ${themeVars('tabBackgroundUnselectedHover')};
        color: ${themeVars('tabColorUnselectedHover')};
      }
    `,
  })}

${media.xxs`
  font-size: .875rem;
  `};
`;

StyledTabbedContainer.TabRow = styled.div`
  display: flex;
  flex-direction: row;
  position: static; /* IE fallback */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;

  box-shadow: ${ifProp({ light: '', default: `0px 3px 10px ${themeVars('colorBlack')}` })};

  * {
    flex-grow: 1;
    width: 10em;
  }
`;

StyledTabbedContainer.Box = ({ children }) => (
  <Theme dark>
    <StyledColumn grow fullWidth>
      {children}
    </StyledColumn>
  </Theme>
);

StyledTabbedContainer.Box.propTypes = {
  children: PropTypes.node.isRequired,
};

StyledTabbedContainer.Body = styled.div`
  display: flex;
  flex-grow: 1;
  background: ${themeVars('bodyBackground')};
`;

export default StyledTabbedContainer;
