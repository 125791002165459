import FrontEndModel from './FrontEndModel';

class ActionTypeTemplate extends FrontEndModel {
  identifier() {
    return this.name;
  }

  update(newData) {
    Object.assign(this, newData);
  }
}

export default ActionTypeTemplate;
