import base from '../_themeBase';
import applyBaseCustom from '../_themeBaseCustom';
import applyDefaultAssignments from '../default/_themeAssignments';
import applyDefaultAssignmentsCustom from '../default/_themeAssignmentsCustom';
import applyDefaultSettings from '../default/_themeSettings';
import applyDefaultSettingsCustom from '../default/_themeSettingsCustom';
import applyAssignments from './_themeAssignments';
import applyAssignmentsCustom from './_themeAssignmentsCustom';
import applySettings from './_themeSettings';
import applySettingsCustom from './_themeSettingsCustom';

let theme = Object.assign({ themeName: 'Dark' }, base);

theme = applyBaseCustom(theme);
theme = applyDefaultAssignments(theme);
theme = applyDefaultAssignmentsCustom(theme);
theme = applyAssignments(theme);
theme = applyAssignmentsCustom(theme);
theme = applyDefaultSettings(theme);
theme = applyDefaultSettingsCustom(theme);
theme = applySettings(theme);
theme = applySettingsCustom(theme);

const exported = theme;

export default exported;
