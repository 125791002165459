import base from '../_themeBase';
import applyBaseCustom from '../_themeBaseCustom';
import applyAssignments from './_themeAssignments';
import applyAssignmentsCustom from './_themeAssignmentsCustom';
import applySettings from './_themeSettings';
import applySettingsCustom from './_themeSettingsCustom';

let theme = Object.assign({ themeName: 'Dark' }, base);

theme = applyBaseCustom(theme);
theme = applyAssignments(theme);
theme = applyAssignmentsCustom(theme);
theme = applySettings(theme);
theme = applySettingsCustom(theme);

const exported = theme;

export default exported;
