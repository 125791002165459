import styled, { css } from 'styled-components';

import themeVars, { ifProp } from '+/utils/themeVars';
import { StyledInTextLink } from '../StyledLinks';

const defaultHeading = css`
  margin: ${ifProp({ noMargin: '0', textLeft: '.5em 0', default: '.5em' })};

  color: ${themeVars('textColorDefault')};
  font-family: ${themeVars('fontHeadings')};
  letter-spacing: 0.01em;
  text-align: ${ifProp({ textLeft: 'left', default: 'center' })};
  text-transform: uppercase;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const defaultBlockElement = css`
  color: ${themeVars('textColorDefault')};
  line-height: 1.67;

  &:not(:last-child) {
    margin-bottom: 1.6em;
  }
`;

export const StyledH1 = styled.h1`
  ${defaultHeading}
  padding-bottom: .3em;

  font-size: ${themeVars('h1FontSize')};
`;

export const StyledH2 = styled.h2`
  ${defaultHeading}
  padding-bottom: .3em;

  font-size: ${themeVars('h2FontSize')};
`;

export const StyledH3 = styled.h3`
  ${defaultHeading}
  padding-bottom: .3em;

  font-size: ${themeVars('h3FontSize')};
`;

export const StyledH4 = styled.h4`
  ${defaultHeading}
  padding-bottom: .3em;

  font-size: ${themeVars('h4FontSize')};
`;

export const StyledH5 = styled.h5`
  ${defaultHeading}
  padding-bottom: .3em;

  font-size: ${themeVars('h5FontSize')};
`;

export const StyledH6 = styled.h6`
  ${defaultHeading}

  font-size: ${themeVars('h6FontSize')};
`;

export const StyledP = styled.p`
  ${defaultBlockElement};

  font-size: ${ifProp({
    large: themeVars('pFontSizeLarge'),
    small: themeVars('pFontSizeSmall'),
    default: themeVars('pFontSize'),
  })};

  text-align: ${ifProp('center', 'center')};

  ${ifProp({
    small: css`
      ${StyledInTextLink} {
        background: none;
        box-shadow: none;
        text-decoration: underline;
        &:hover {
          color: ${themeVars('linkInTextLineColor')};
        }
      }
    `,
  })};
`;

export const StyledCenteredText = styled.p`
  display: block;
  margin: 2em;

  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
`;

export const StyledQuote = styled.p`
  font-family: monospace;
`;

export const StyledUL = styled.ul`
  ${defaultBlockElement};
  padding-left: 2.22222em;
`;

export const StyledOL = styled.ol`
  ${defaultBlockElement};
  padding-left: 2.22222em;
`;

export const StyledLI = styled.li`
  margin: 1em 0;
`;
