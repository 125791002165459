import ToastsStore from '+/models/ToastsStore';

const defaultStore = {
  all: new ToastsStore(),
};

// eslint-disable-next-line default-param-last
const toastsStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
    case 'ADD_TOAST': {
      const toastData = action.payload;
      const newAllToasts = store.all.dup();
      newAllToasts.add(toastData);
      Object.assign(newStore, { all: newAllToasts });
      break;
    }
    case 'REMOVE_TOAST': {
      const toastId = action.payload;
      const newAllToasts = store.all.dup();
      newAllToasts.remove(toastId);
      Object.assign(newStore, { all: newAllToasts });
      break;
    }
    default:
  }
  return newStore;
};

export default toastsStore;
