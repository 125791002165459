import React from 'react';

import { SlideOutMenu } from './SlideOutMenu.component';
import useCurrentUser from '+/utils/hooks/useCurrentUser';
import useUserBadges from '+/utils/hooks/useUserBadges';

export default (props) => {
  const currentUser = useCurrentUser();
  const userBadges = useUserBadges();

  return <SlideOutMenu {...props} currentUser={currentUser} userBadges={userBadges} />;
};
