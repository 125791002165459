import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import { StyledContainer } from '+/styledComponents/elements/containers';
import { ActionFeedItem } from '+/components/ActionFeed/ActionFeedItem';

import { LoadingMessage } from './LoadingMessage';
import { ActionFeedEmptiedMessage } from './ActionFeedEmptiedMessage';

const actionItemVariants = {
  hidden: { opacity: 0, transition: { duration: 0.2 } },
  visible: { opacity: 1 },
};

const actionItemTransition = {
  duration: 0.4,
  type: 'spring',
};

export const ActionFeed = props => {
  const { actions, basePath, isRoom, returnPath } = props;
  const isSelected = action => {
    const { actionType, actionId } = props.match.params;
    return action.actionType === actionType && action.id === parseInt(actionId, 10);
  };

  if (actions.isLoading()) {
    return (
      <StyledContainer fullHeight>
        <LoadingMessage />
      </StyledContainer>
    );
  }

  if (actions.isEmpty()) {
    return (
      <StyledContainer fullHeight>
        <ActionFeedEmptiedMessage isRoom={isRoom} />
      </StyledContainer>
    );
  }
  return (
    <StyledContainer fullHeight>
      <AnimatePresence>
        {actions.map((action, idx) => {
          const nextAction = actions[idx + 1];
          return (
            <motion.div
              variants={actionItemVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              layout
              transition={actionItemTransition}
              key={`${action.actionType}#${action.id}`}
            >
              <ActionFeedItem
                {...props}
                isSelected={isSelected(action)}
                action={action}
                nextAction={nextAction}
                basePath={basePath}
                returnPath={returnPath}
              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </StyledContainer>
  );
};

ActionFeed.propTypes = {
  actions: PropTypes.models.ActionsStore.isRequired,
  basePath: PropTypes.string,
  completeEligibleAction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isRoom: PropTypes.bool,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  removeEligibleAction: PropTypes.func.isRequired,
  returnPath: PropTypes.string,
  uncompleteEligibleAction: PropTypes.func.isRequired,
};

ActionFeed.defaultProps = {
  returnPath: '',
  basePath: '',
  isRoom: false,
};
