import User from './User';
import FrontEndStore from './FrontEndStore';

class UsersStore extends FrontEndStore {
  constructor(usersData) {
    super(usersData);
    this.loading = !usersData || usersData.loading;
    this.store = ((usersData && usersData.store) || usersData || []).map(datum => new User(datum));
  }

  isEmpty() {
    return this.store.length === 0;
  }

  find(userId) {
    for (const user of this.store) {
      if (user.id === parseInt(userId, 10)) return user;
    }
    return false;
  }

  updatePermission(userId, userPermission) {
    const user = this.find(userId);
    if (!user) return false;
    user.permissions = userPermission;
    return true;
  }

  updateGroup(userId, userGroup) {
    const user = this.find(userId);
    if (!user) return false;
    user.group = userGroup;
    return true;
  }

  dup() {
    return new UsersStore(this.store.map(hash => ({ ...hash })));
  }

  map(func) {
    return this.store.map(func);
  }
}

export default UsersStore;
