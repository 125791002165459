import React from 'react';

import PropTypes from '+/instances/propTypesInstance';

import { Footer } from '+/components/Footer';
import { Header } from '+/components/Header';
import { StyledBody } from '+/styledComponents/elements/containers';
import Theme from '+/instances/Theme';

const BareLayout = ({ children, forcePlainHeader }) => (
  <Theme light>
    <StyledBody>
      <Header key={window.location.pathname} forcePlain={forcePlainHeader} />
      {children}
      <Footer />
    </StyledBody>
  </Theme>
);

BareLayout.propTypes = {
  children: PropTypes.node.isRequired,
  forcePlainHeader: PropTypes.bool,
};

BareLayout.defaultProps = {
  forcePlainHeader: false,
};

export const withBareLayout = Child => () => (
  <BareLayout>
    <Child />
  </BareLayout>
);

export default BareLayout;
