//
// Used in the redux and components to organize taken action related data
// and provide helper methods.
//

class TakenActionsStore {
  constructor(actionData) {
    this.set = new Set(actionData || []);
  }

  isEmpty() {
    return this.set.size === 0;
  }

  count(filter) {
    if (!filter) {
      return this.set.size;
    }
    const filteredSet = new Set([]);
    this.set.forEach((el) => {
      if (el.actionType === `${filter}_action`) {
        filteredSet.add(el);
      }
    });
    return filteredSet.size;
  }

  userCount() {
    const userIds = new Set([]);
    this.set.forEach((takenAction) => {
      userIds.add(takenAction.userId);
    });
    return userIds.size;
  }

  dup() {
    return new TakenActionsStore(this.set);
  }
}

export default TakenActionsStore;
