import React from 'react';
import styled, { css } from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import themeVars, { ifProp } from '+/utils/themeVars';
import Button from '+/components/Button/Button';

import PropTypes from '../instances/propTypesInstance';

const StyledCard = {};

StyledCard.Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 37rem;
  min-height: ${ifProp({ autoHeight: '0', default: '19.75rem' })};
  padding: ${ifProp({ noPadding: '0em', default: '1.5rem 1.5rem 2.5rem' })};
  position: relative;
  width: ${ifProp({ fitContent: 'auto', default: '100%' })};

  background-color: ${themeVars('cardBackgroundColor')};
  background-image: ${themeVars('cardBackgroundImage')};
  background-repeat: no-repeat;
  border-radius: ${themeVars('cardBorderRadiusCondensed')};

  filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 2px 20px);
  -webkit-transform: translate3d(0, 0, 0);

  ${ifProp(
    'inModal',
    css`
      margin-bottom: 0;
      min-height: 0;
      padding-bottom: ${ifProp({ noPadding: '0', default: '1.5rem' })};
      border-radius: ${themeVars('cardBorderRadiusUncondensed')};
      background: ${themeVars('colorWhite')};
      filter: none;
    `,
  )};

  .exit-left & {
    margin-bottom: 0;
  }

  & > * {
    max-width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

StyledCard.Container = props => {
  const { 'data-idf': dataIdf, ...otherProps } = props;
  return (
    <Container {...otherProps} data-idf={dataIdf}>
      <StyledCard.Box {...otherProps}>{props.children}</StyledCard.Box>
    </Container>
  );
};

StyledCard.Container.propTypes = {
  children: PropTypes.node.isRequired,
  'data-idf': PropTypes.string.isRequired,
};

StyledCard.DismissButtonContainer = styled(Button)`
  display: inline-flex;
  position: absolute;
  right: 0.4em;
  top: 0.4em;

  background: none;
  border: none;
  outline: none;

  color: ${themeVars('textColorExtraFaded')};

  cursor: pointer;

  &:hover {
    color: ${themeVars('textColorFaded')};
  }
`;

StyledCard.DismissButton = props => (
  <StyledCard.DismissButtonContainer {...props}>
    <FaTimes />
    {props.children}
  </StyledCard.DismissButtonContainer>
);

StyledCard.DismissButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StyledCard;
