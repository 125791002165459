import { useSelector } from 'react-redux';

import useFetch from './useFetch';
import useCurrentUser from './useCurrentUser';
import { getUserBadges } from '+/utils/getUserBadges';
import * as actions from '+/actions';
import * as apiCalls from '+/utils/apiCalls';

let fetched = false;
let lastPrefs = '';

const fetchCollectedUserData = async(dispatch) => {
  const responseData = await apiCalls.getCollectedUserData();
  if (responseData.errors) return;
  const {
    eligibleActions,
    eligibleAnnouncements,
    userPushSubscriptions,
    userPointSum,
    userTakenActionCount,
    userTripleEligibility,
    currentStreakCount,
    longestStreakCount,
  } = responseData;
  dispatch(actions.setEligibleActions(eligibleActions));
  dispatch(actions.setEligibleAnnouncements(eligibleAnnouncements));
  dispatch(actions.setUserTripleEligibility(userTripleEligibility));
  dispatch(
    actions.setUserTakenActionStats({
      userPointSum,
      userTakenActionCount,
      currentStreakCount,
      longestStreakCount,
    }),
  );
  dispatch(actions.setUserBadges(getUserBadges(userPointSum, longestStreakCount)));
  dispatch(actions.setUserPushSubscriptions(userPushSubscriptions));
  fetched = true;
};

const useCollectedUserData = (refetch = false) => {
  const currentUser = useCurrentUser();

  const currentStreakCount = useSelector(state => state.takenActions.currentStreakCount);
  const longestStreakCount = useSelector(state => state.takenActions.longestStreakCount);
  const userBadges = useSelector(state => state.badges.user);
  const userPointSum = useSelector(state => state.takenActions.userPointSum);
  const userTakenActionCount = useSelector(state => state.takenActions.userTakenActionCount);
  const userTripleEligibility = useSelector(state => state.user.tripleEligible);
  const eligibleActions = useSelector(state => state.actions.eligibleActions);
  const eligibleAnnouncements = useSelector(state => state.announcements.eligibleAnnouncements);

  // Keep track of action preferences and refetch when they change.
  const currentPrefs = JSON.stringify(currentUser.actionSettings());
  const shouldFetch =
    !currentUser.isEmpty() && (refetch || currentPrefs !== lastPrefs || !fetched);

  lastPrefs = currentPrefs;

  useFetch(fetchCollectedUserData, shouldFetch);

  return {
    currentStreakCount,
    eligibleActions,
    eligibleAnnouncements,
    longestStreakCount,
    userBadges,
    userPointSum,
    userTakenActionCount,
    userTripleEligibility,
  };
};

export default useCollectedUserData;
