import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Toast } from './Toast';

import PropTypes from '+/instances/propTypesInstance';

import { StyledToastsContainer } from '+/styledComponents/StyledToasts';

const toastVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
};

const toastTransition = {
  duration: 0.4,
  type: 'spring',
};

export const Toasts = ({ toasts }) => (
  <StyledToastsContainer>
    <AnimatePresence>
      {toasts &&
        toasts.map(n => (
          <motion.div
            variants={toastVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            layout
            transition={toastTransition}
            key={n.id}
          >
            <Toast data={n} />
          </motion.div>
        ))}
    </AnimatePresence>
  </StyledToastsContainer>
);

Toasts.propTypes = {
  toasts: PropTypes.models.ToastsStore.isRequired,
};
