import React from 'react';

const LazyRequestPassword = React.lazy(() => import('./ResetPassword.container.js'));
const SuspendedRequestPassword = () => (
  <React.Suspense
    fallback={
      <div />
    }
  >
    <LazyRequestPassword />
  </React.Suspense>
);
export default SuspendedRequestPassword;
