// ====================================================================
// Theme Settings (CSS)
// ====================================================================

// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  // Measures
  headerLogoWidth: '185px',
  headerLogoHeight: '60px',

  colorOtherAction: variables.colorDanger,
  colorOtherActionText: variables.colorWhite,
  backgroundPointValueDefault: variables.colorMediumGrey,
  colorPointValueDefault: variables.colorWhite,

  // Section colors
  headerBackground: variables.colorBlack,
  announcementBarBackground: variables.colorRed,

  actionCardFlagLabelFont: variables.fontFormal,
  actionCardFlagLabelFontStyle: 'normal',

  // Buttons
  buttonNotch: false,

  // Primary
  buttonBorderColorPrimary: 'transparent',
  // hover
  buttonBackgroundHoverPrimary: variables.colorDanger,

  // Secondary
  buttonBorderColorSecondary: 'transparent',

  // Warning
  buttonBorderColorWarning: 'transparent',

  // Danger
  buttonBorderColorDanger: 'transparent',

  // Muted
  buttonBorderColorMuted: 'transparent',

  // Cards
  cardBorderRadiusCondensed: '5px',
  cardBorderRadiusUncondensed: '5px',
  cardBackgroundImage: 'none',
  cardBackgroundColor: variables.colorWhite,

  slideOutMenuHeaderBackground: variables.colorBlack,
  slideOutMenuLinkColor: variables.textColorDefault,
  slideOutMenuLinkColorActive: variables.colorDanger,
  slideOutMenuLinkBackgroundHover: variables.colorLightestGrey,
});
