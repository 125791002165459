import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import PropTypes from '+/instances/propTypesInstance';
import StyledAwardList from '+/styledComponents/StyledAwardList';
import TextReader from '+/utils/TextReader';

const { t } = new TextReader('awards');

const randomArrayItem = arr => arr[Math.floor(Math.random() * arr.length)];

const awardListActiveItemVariants = {
  hidden: { opacity: 0.25, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
};

const awardListItemVariants = {
  hidden: { opacity: 0.25, scale: 0.8 },
  visible: { opacity: 0.25, scale: 0.8 },
};

export const AwardList = ({ userBadges }) => (
  <>
    <StyledAwardList.Title>{t('awardList.title')}</StyledAwardList.Title>
    <StyledAwardList.List>
      <AnimatePresence>
        {t('badges').map((badge, i) => {
          const isAchieved = !!userBadges.find(cB => cB.name === badge.name);
          return (
            <StyledAwardList.ListItem
              key={badge.icon}
              achieved={isAchieved}
              variants={isAchieved ? awardListActiveItemVariants : awardListItemVariants}
              as={motion.div}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.4,
                type: 'spring',
                delay: i * 0.4 + 0.25,
              }}
            >
              <img src={badge.icon} alt={badge.name} />
              <StyledAwardList.BadgeName>{badge.name}</StyledAwardList.BadgeName>
              <StyledAwardList.Criteria>
                {badge.requiredPoints > 0 && (
                  <StyledAwardList.CriteriaItemn>
                    {badge.requiredPoints}
                    {badge.requiredPoints === 1
                      ? t('awardList.criteria.points.singular')
                      : t('awardList.criteria.points.plural')}
                  </StyledAwardList.CriteriaItemn>
                )}
                {badge.requiredStreak > 0 && (
                  <StyledAwardList.CriteriaItemn>
                    {badge.requiredStreak}
                    {badge.requiredStreak === 1
                      ? t('awardList.criteria.weekStreak.singular')
                      : t('awardList.criteria.weekStreak.plural')}
                  </StyledAwardList.CriteriaItemn>
                )}
              </StyledAwardList.Criteria>
            </StyledAwardList.ListItem>
          );
        })}
      </AnimatePresence>
    </StyledAwardList.List>

    <StyledAwardList.Text>{randomArrayItem(t('awardList.messages'))}</StyledAwardList.Text>
  </>
);

AwardList.propTypes = {
  userBadges: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
