import React from 'react';
import { connect } from 'react-redux';

import { addDisplayedActions, removeEligibleAnnouncement } from '+/actions/';
import useCurrentUser from '+/utils/hooks/useCurrentUser';
import useDisplayedActions from '+/utils/hooks/useDisplayedActions';
import useEligibleActions from '+/utils/hooks/useEligibleActions';
import useEligibleAnnouncements from '+/utils/hooks/useEligibleAnnouncements';

import { MainActionFeed } from './MainActionFeed.component';

const mapDispatchToProps = dispatch => ({
  addDisplayedActions: () => {
    dispatch(addDisplayedActions());
  },
  removeEligibleAnnouncement: announcementId => {
    dispatch(removeEligibleAnnouncement(announcementId));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(props => {
  const currentUser = useCurrentUser();
  const eligibleActions = useEligibleActions();
  const eligibleAnnouncements = useEligibleAnnouncements();
  const displayedActions = useDisplayedActions();

  return (
    <MainActionFeed
      {...props}
      currentUser={currentUser}
      displayedActions={displayedActions}
      eligibleActions={eligibleActions}
      eligibleAnnouncements={eligibleAnnouncements}
    />
  );
});
