import React from 'react';

import { TabbedContainer } from '+/components/TabbedContainer';
import { StyledStretchDiv } from '+/styledComponents/elements/containers';

const LazyWelcome = React.lazy(() => import('./Welcome.container.js'));
const SuspendedWelcome = () => (
  <React.Suspense
    fallback={
      <StyledStretchDiv>
        <TabbedContainer tabs={[{}, {}]} />
      </StyledStretchDiv>
    }
  >
    <LazyWelcome />
  </React.Suspense>
);
export default SuspendedWelcome;
