import styled from 'styled-components';

import themeVars, { ifProp } from '+/utils/themeVars';

export const StyledToastsContainer = styled.div`
  left: 50%;
  max-width: 37rem;
  padding-top: 0.6875rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${themeVars('toastZIndex')};

  transform: translateX(-50%);
`;

export const StyledToast = {};

StyledToast.Box = styled.div`
  margin: 1rem auto 0;
  padding: 1rem;
  max-width: 35rem;
  width: 95%;

  border-radius: 6px;
  background: ${ifProp({
    info: themeVars('colorToastNeutral'),
    success: themeVars('colorToastSuccess'),
    warning: themeVars('colorToastDanger'),
    default: themeVars('colorToastNeutral'),
  })};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  font-size: 0.75rem;
  letter-spacing: 0.06667em;
  line-height: normal;
  text-align: ${ifProp({
    actionable: 'left',
    default: 'center',
  })};
`;

StyledToast.Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

StyledToast.Icon = styled.div`
  display: flex;
`;

StyledToast.Body = styled.div`
  flex-grow: 1;

  ${StyledToast.Icon} + & {
    margin-right: 1.5rem;
  }

  p,
  a {
    color: inherit;
    font-size: inherit;
    margin: 0;
  }
`;
