import React from 'react';

import StyledCard from '+/styledComponents/StyledCard';
import { StyledLoadingIndicator } from '+/styledComponents/StyledLoadingIndicator';

const LazyActionCard = React.lazy(() => import('./ActionCard.container'));

const SuspendedActionCard = React.forwardRef((props, ref) => (
  <React.Suspense
    fallback={
      <StyledCard.Box ref={ref}>
        <StyledLoadingIndicator size={80} muted />
      </StyledCard.Box>
    }
  >
    <LazyActionCard ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedActionCard;
