import ActionsStore from '+/models/ActionsStore';
import Action from '+/models/Action';
import User from '+/models/User';
import displayedActionsFeeder from '+/utils/displayedActionFeeder';

const defaultStore = {
  all: new ActionsStore(),
  eligibleActions: new ActionsStore(),
  displayedActions: new ActionsStore(),
  actionsDisplayedForRoom: {},
  duplicatedAction: null,
};

// eslint-disable-next-line default-param-last
const actionsStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
    case 'SET_ALL_ACTIONS': {
      const actionsData = action.payload;
      const newAllActions = new ActionsStore(actionsData);
      Object.assign(newStore, { all: newAllActions });
      break;
    }
    case 'SET_ACTION': {
      const { actionId, actionType, data } = action.payload;
      const newAction = new Action(data);
      newStore.individual = {...newStore.individual}
      newStore.individual[newAction.actionType] = { ...newStore.individual[newAction.actionType]}
      newStore.individual[actionType][actionId] = newAction;
      break;
    }
    case 'UPDATE_ACTION': {
      const actionData = action.payload;
      const newAllActions = store.all.dup();
      const newAction = new Action(actionData);
      newAllActions.updateAction(actionData);
      newStore.individual = {...newStore.individual}
      newStore.individual[newAction.actionType] = { ...newStore.individual[newAction.actionType]}
      newStore.individual[newAction.actionType][newAction.id] = newAction;
      Object.assign(newStore, { all: newAllActions });
      break;
    }
    case 'SET_DUPLICATED_ACTION': {
      const actionToDup = action.payload;
      const newAction = actionToDup.copyToDuplicate();
      newStore.duplicatedAction = newAction;
      break;
    }
    case 'CLEAR_DUPLICATED_ACTION': {
      newStore.duplicatedAction = null;
      break;
    }
    case 'SET_ELIGIBLE_ACTIONS': {
      Object.assign(newStore, { eligibleActions: new ActionsStore(action.payload) });
      if (store.displayedActions.isEmpty()) {
        const newDisplayedActions = store.displayedActions.dup();
        displayedActionsFeeder.feed(newStore.eligibleActions, newDisplayedActions);
        Object.assign(newStore, { displayedActions: newDisplayedActions });
      }
      break;
    }
    case 'REMOVE_ELIGIBLE_ACTION': {
      const { actionType, actionId } = action.payload;
      const newEligibleActions = store.eligibleActions.dup();
      newEligibleActions.remove(actionType, actionId);
      const newDisplayedActions = store.displayedActions.dup();
      newDisplayedActions.remove(actionType, actionId);
      const newDisplayedActionsForRoom = { ...store.displayedActionsForRoom };
      Object.keys(newDisplayedActionsForRoom).forEach(roomKey => {
        newDisplayedActionsForRoom[roomKey] =
          newDisplayedActionsForRoom[roomKey].dup();
        newDisplayedActionsForRoom[roomKey].remove(actionType, actionId);
      });
      Object.assign(newStore, {
        eligibleActions: newEligibleActions,
        displayedActions: newDisplayedActions,
        displayedActionsForRoom: newDisplayedActionsForRoom,
      });
      break;
    }
    case 'COMPLETE_ELIGIBLE_ACTION': {
      const { actionType, actionId } = action.payload;
      const newEligibleActions = store.eligibleActions.dup();
      newEligibleActions.markCompleted(actionType, actionId);
      const newDisplayedActions = store.displayedActions.dup();
      newDisplayedActions.markCompleted(actionType, actionId);
      const newDisplayedActionsForRoom = { ...store.displayedActionsForRoom };
      Object.keys(newDisplayedActionsForRoom).forEach(roomKey => {
        newDisplayedActionsForRoom[roomKey] =
          newDisplayedActionsForRoom[roomKey].dup();
        newDisplayedActionsForRoom[roomKey].markCompleted(actionType, actionId);
      });
      Object.assign(newStore, {
        eligibleActions: newEligibleActions,
        displayedActions: newDisplayedActions,
        displayedActionsForRoom: newDisplayedActionsForRoom,
      });
      break;
    }
    case 'UNCOMPLETE_ELIGIBLE_ACTION': {
      const { actionType, actionId } = action.payload;
      const newEligibleActions = store.eligibleActions.dup();
      newEligibleActions.markUncompleted(actionType, actionId);
      const newDisplayedActions = store.displayedActions.dup();
      newDisplayedActions.markUncompleted(actionType, actionId);
      const newDisplayedActionsForRoom = { ...store.displayedActionsForRoom };
      Object.keys(newDisplayedActionsForRoom).forEach(actionTypeKey => {
        newDisplayedActionsForRoom[actionTypeKey] =
          newDisplayedActionsForRoom[actionTypeKey].dup();
        newDisplayedActionsForRoom[actionTypeKey].markUncompleted(actionType, actionId);
      });
      Object.assign(newStore, {
        eligibleActions: newEligibleActions,
        displayedActions: newDisplayedActions,
        displayedActionsForRoom: newDisplayedActionsForRoom,
      });
      break;
    }
    case 'ADD_DISPLAYED_ACTIONS': {
      const newDisplayedActions = store.displayedActions.dup();
      displayedActionsFeeder.feed(store.eligibleActions, newDisplayedActions);
      Object.assign(newStore, { displayedActions: newDisplayedActions });
      break;
    }
    case 'ADD_DISPLAYED_ACTIONS_FOR_ROOM': {
      const { roomPath, actions } = action.payload;
      const oldDisplayedActions = store.displayedActionsForRoom[roomPath] || new ActionsStore();
      const newDisplayedActions = oldDisplayedActions.dup();
      displayedActionsFeeder.feed(actions, newDisplayedActions);
      const allRoomActions = store.displayedActionsForRoom;
      Object.assign(newStore, {
        displayedActionsForRoom: { ...allRoomActions, [roomPath]: newDisplayedActions },
      });
      break;
    }
    case 'UPDATE_USER': {
      const userData = action.payload;
      const newUserModel = new User(userData);
      const disabledActionTypes = newUserModel.disabledActionTypes();
      const newEligibleActions = store.eligibleActions.dup();
      const newDisplayedActions = store.displayedActions.dup();
      newEligibleActions.removeActionsByType(disabledActionTypes);
      newDisplayedActions.removeActionsByType(disabledActionTypes);
      Object.assign(newStore, {
        displayedActions: newDisplayedActions,
        eligibleActions: newEligibleActions,
      });
      break;
    }
    case 'SIGN_OUT_USER': {
      const newAllActions = new ActionsStore();
      const newEligibleActions = new ActionsStore();
      const newDisplayedActions = new ActionsStore();
      Object.assign(newStore, {
        all: newAllActions,
        displayedActions: newDisplayedActions,
        eligibleActions: newEligibleActions,
      });
      break;
    }
    default:
  }
  return newStore;
};

export default actionsStore;
