import styled from 'styled-components';

import themeVars, { ifProp } from '+/utils/themeVars';
import media from '+/utils/media';
import { StyledH4, StyledH6, StyledP } from '+/styledComponents/elements/typography';

const StyledAwardList = {};

StyledAwardList.Title = styled(StyledH4)`
  color: inherit;
`;

StyledAwardList.List = styled.div`
  padding: 1.5rem 0 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${media.md`
    flex-wrap: nowrap;
`}

  img {
    display: block;
    height: 150px;
    margin: auto;
  }
`;

StyledAwardList.ListItem = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding: 0.5rem;
  display: inline-block;
  text-align: center;
  filter: grayscale(
    ${ifProp({
    achieved: '0%',
    default: '100%',
  })}
  );
  ${media.sm`
  max-width: 33%;
`}
`;

StyledAwardList.Text = styled(StyledP)`
  color: inherit;
  text-align: center;
`;

StyledAwardList.BadgeName = styled(StyledH6)`
  color: inherit;
`;

StyledAwardList.Criteria = styled.ul`
  list-style: none;
  margin: 0.5em 0 0;
  padding: 0;
  line-height: 1.375;
  font-size: 0.875rem;
  color: ${themeVars('colorMediumLighterGrey')};
`;

StyledAwardList.CriteriaItemn = styled.li`
  display: inline;

  &:not(:first-child):before {
    content: " + ";
  }
`;


export default StyledAwardList;
