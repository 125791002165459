import styled, { css } from 'styled-components';

import media from '+/utils/media';
import themeVars, { fromProps, ifProp } from '+/utils/themeVars';

export const StyledMain = styled.main`
  flex-grow: 1;
  width: 100%;
`;

// This isn't actually a body element.
export const StyledBody = styled.div`
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${themeVars('headerHeight')};
  position: relative;
  background: ${themeVars('bodyBackground')};

  color: ${themeVars('textColorDefault')};
`;

export const StyledStretchDiv = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

export const StyledFullWidthDiv = styled.div`
  width: 100vw;
`;

export const StyledBox = styled.div`
  margin: ${ifProp('maxWidth', '0', '20px')};
  padding: 20px;
  position: relative;
  width: ${ifProp('maxWidth', '100%')};

  background-color: ${themeVars('bodyForeground')};
  border-radius: 2px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.6);
`;

export const StyledContainer = styled.div`
  align-content: center;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${ifProp('narrow', '26rem', '37rem')} !important;
  padding: 6.25% 0;
  width: 87.5%;

  ${ifProp({
    overlayHeader: css`
      z-index: ${p => parseInt(themeVars('headerZIndex')(p), 10) + 1};
    `,
  })}

  ${media.sm`
  padding: 3rem 0;
  `}

  & > span {
    width: 100%;
  }
`;

export const StyledPaddedDiv = styled.div`
  margin: ${ifProp({ noMargin: '0px', default: '20px' })};
  padding: ${props => props.padding || '1.5em'};
  position: relative;
  width: ${ifProp({ fullWidth: '100%' })};
  width: ${fromProps('width')};
  ${ifProp({
    colored: css`
      background: ${themeVars('bodyBackground')};
    `,
    foreground: css`
      background: ${themeVars('bodyForeground')};
    `,
  })};
`;

export const StyledDiv = styled.div`
  position: relative;
  width: ${fromProps('width')};
  cursor: ${ifProp({ pointer: 'pointer' })};
`;

export const StyledDivFixedToBottom = styled.div`
  position: fixed;
  bottom: 0;
`;

export const StyledSpan = styled.span`
  position: relative;
  cursor: ${ifProp({ pointer: 'pointer' })};
`;

export const StyledColumn = styled.div`
  align-items: ${ifProp({
    center: 'center',
    middle: 'center',
  })};
  display: flex;
  flex-direction: column;
  flex-grow: ${ifProp({ grow: 1 })};
  height: ${ifProp({ fullHeight: '100%' })};
  justify-content: ${ifProp({
    center: 'center',
    spaceAround: 'space-around',
    spaceBetween: 'space-between',
  })};
  padding: ${ifProp({
    padded: '0 1em',
    leftPadded: '0 0 0 3.125em',
    default: '0',
  })};
  width: ${ifProp({
    fullWidth: '100%',
  })};

  background: ${ifProp({
    colored: themeVars('bodyBackground'),
  })};
`;

export const StyledFlexRow = styled.div`
  align-items: ${props => (props.alignitems ? props.alignitems : 'auto')};
  display: flex;
  justify-content: ${props => (props.justifycontent ? props.justifycontent : 'auto')};
`;

export const StyledInline = styled.div`
  display: inline-block;
  width: auto;
`;
