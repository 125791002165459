import TakenActionsStore from '+/models/TakenActionsStore';

const defaultStore = {
  all: new TakenActionsStore([]),
  user: new TakenActionsStore([]),
  userPointSum: 0,
  userTakenActionCount: 0,
  sessionTakenActionCount: 0,
  currentStreakCount: 0,
  longestStreakCount: 0,
};

const takenActions = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
  case 'SET_ALL_TAKEN_ACTIONS': {
    const takenActionsData = action.payload;
    const newTakenActions = new TakenActionsStore(takenActionsData);
    Object.assign(newStore, { all: newTakenActions });
    break;
  }
  case 'SET_USER_TAKEN_ACTIONS': {
    const takenActionsData = action.payload;
    const newTakenActions = new TakenActionsStore(takenActionsData);
    Object.assign(newStore, { user: newTakenActions });
    break;
  }
  case 'COMPLETE_ELIGIBLE_ACTION': {
    Object.assign(newStore, {
      sessionTakenActionCount: store.sessionTakenActionCount + 1,
      // Updated with new stats
      // userTakenActionCount: store.userTakenActionCount + 1,
    });
    break;
  }
  case 'UNCOMPLETE_ELIGIBLE_ACTION': {
    Object.assign(newStore, {
      sessionTakenActionCount: store.sessionTakenActionCount - 1,
      userTakenActionCount: store.userTakenActionCount - 1,
    });
    break;
  }
  case 'SET_USER_TAKEN_ACTION_STATS': {
    Object.assign(newStore, {
      userPointSum: action.payload.userPointSum,
      userTakenActionCount: action.payload.userTakenActionCount,
      currentStreakCount: action.payload.currentStreakCount,
      longestStreakCount: action.payload.longestStreakCount,
    });
    break;
  }
  case 'SIGN_OUT_USER': {
    const newTakenActions = new TakenActionsStore([]);
    Object.assign(newStore, { user: newTakenActions });
    break;
  }
  default:
  }
  return newStore;
};

export default takenActions;
