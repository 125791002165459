import React from 'react';

import { withRouter } from 'react-router-dom';
import useCurrentUser from '+/utils/hooks/useCurrentUser';

import { AppRouter } from './AppRouter.component';

export default withRouter((props) => {
  const currentUser = useCurrentUser();

  return <AppRouter {...props} currentUser={currentUser} />;
});
