// This class creates utility objects for transferring actions between action stores.
// It is intended to be used to select some actions which are eligible for completion by a user,
// and move them into a store of the actions to be displayed on their screen.
// To the best of its ability, the feeder will add actions up to the ADD_COUNT.
// It will always select prioritized actions first.
// It will also try to ensure that a certain minimum number of kinds of actions (DIVERSITY_COUNT)
// are included.
// After priority and ensuring diversity, actions will be
// added by the order of the most recent updated.
// The order of actions should be preserved with the addition of new actions,
// so new actions never jump up to the front of the queue.

import ActionsStore from '+/models/ActionsStore';

const ADD_COUNT = 10;
const DIVERSITY_COUNT = 4;

class DisplayedActionFeeder {
  feed(eligibleActions, displayedActions) {
    const unsnoozedEligibleActions = eligibleActions.unsnoozed();
    let undisplayedActions = unsnoozedEligibleActions.excluding(displayedActions);
    const actionsToEnsureDiversity = this.getDiverseActions(undisplayedActions, displayedActions);
    // Order undisplayed actions by pushing diverse to the front, and ordering all by recency.
    undisplayedActions = new ActionsStore([
      ...actionsToEnsureDiversity.orderedByRecency(),
      ...undisplayedActions.excluding(actionsToEnsureDiversity).orderedByRecency(),
    ]);
    // Without disturbing prior ordering except where moving priorirized actions, order by priority
    undisplayedActions = undisplayedActions.orderedByPriority();
    const actionsToEnsureCount = this.getActionsToCount(undisplayedActions, ADD_COUNT);
    displayedActions.addStore(actionsToEnsureCount);
    return displayedActions;
  }

  getDiverseActions(undisplayedActions, displayedActions) {
    const latestArr = undisplayedActions.orderedByRecency();
    const includedActionTypes = new Set();
    const diverseActions = new ActionsStore();
    displayedActions.presentActionTypes().forEach(a => includedActionTypes.add(a.actionType));
    for (const action of latestArr) {
      if (includedActionTypes.size >= DIVERSITY_COUNT) return diverseActions;
      if (!includedActionTypes.has(action.actionType)) {
        includedActionTypes.add(action.actionType);
        diverseActions.addAction(action);
      }
    }
    return diverseActions;
  }

  getActionsToCount(undisplayedActions, additionalCount) {
    const actionsToCount = new ActionsStore();
    undisplayedActions.slice(0, additionalCount).forEach(a => actionsToCount.addAction(a));
    return actionsToCount;
  }
}

DisplayedActionFeeder.feed = (eligible, displayed) =>
  new DisplayedActionFeeder().feed(eligible, displayed);

export default DisplayedActionFeeder;
