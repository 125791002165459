import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '+/actions/';
import useCurrentUser from '+/utils/hooks/useCurrentUser';
import useUserPointSum from '+/utils/hooks/useUserPointSum';
import useCurrentStreakCount from '+/utils/hooks/useCurrentStreakCount';
import { Header } from './Header.component';

const mapStateToProps = store => ({
  areUnseenMessages: store.messages && store.messages.all ? store.messages.all.anyUnseen() : false,
});

const mapDispatchToProps = dispatch => ({ signOutUser: () => dispatch(actions.signOutUser()) });

// withRouter no longer necessary?
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const currentUser = useCurrentUser();
    const userPointSum = useUserPointSum();
    const currentStreakCount = useCurrentStreakCount();
    return (
      <Header
        {...props}
        currentUser={currentUser}
        userPointSum={userPointSum}
        currentStreakCount={currentStreakCount}
      />
    );
  }),
);
