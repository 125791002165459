import Cookies from 'js-cookie';

import User from '+/models/User';

import gtm from '../instances/googleTagManager';

let subscribe;

const defaultStore = { currentUser: new User(), tripleEligible: false };

// Performs func once for given key.
const onlyOnce = (func, key = 'default') => {
  onlyOnce.performed = onlyOnce.performed || {};
  onlyOnce.performed[key] = onlyOnce.performed[key] || Symbol('performed');
  return (...args) => {
    if (func[onlyOnce.performed[key]]) return;
    func(...args);
    // eslint-disable-next-line no-param-reassign
    func[onlyOnce.performed[key]] = true;
  };
};

// eslint-disable-next-line default-param-last
const user = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
    case 'SIGN_IN_USER': {
      const userData = action.payload;
      const userModel = new User(userData);
      gtm.setUser(userModel);
      if (userModel.needsBrowserNotificationSubscription()) {
        // eslint-disable-next-line global-require -- We face a dependency cycle if we dont load it like this
        if (!subscribe) subscribe = require('+/utils/pushSubscriber').subscribe;
        setTimeout(subscribe, 15000);
      }
      localStorage.setItem('isAdmin', !!userModel.isAdmin());
      Cookies.set('fan-signed-in', true, { expires: 365 });
      Object.assign(newStore, { currentUser: userModel });
      break;
    }
    case 'SET_USER_TAKEN_ACTION_COUNT': {
      // Main logic handled in taken actions reducer.
      onlyOnce(gtm.setEngagement, store.currentUser.id)(store.currentUser, action.payload);
      break;
    }
    case 'SET_USER_TRIPLE_ELIGIBILITY': {
      const tripleEligible = action.payload;
      Object.assign(newStore, { tripleEligible });
      break;
    }
    case 'SIGN_OUT_USER': {
      Cookies.remove('fan-session');
      Cookies.remove('fan-session.sig');
      gtm.setUser(null);
      Object.assign(newStore, { currentUser: new User({}) });
      break;
    }
    case 'UPDATE_USER': {
      const userData = action.payload;
      const newUserModel = new User(userData);
      Object.assign(newStore, { currentUser: newUserModel });
      break;
    }
    default:
  }
  return newStore;
};

export default user;
