import { formatDate } from './modelHelpers';

class EveryActionError {
  constructor(everyActionErrorData) {
    Object.assign(this, everyActionErrorData);
  }

  createdAtString() {
    return new Date(Date.parse(this.createdAt)).toDateString();
  }

  createdAtStringLong() {
    return formatDate(new Date(Date.parse(this.createdAt)));
  }

  seenAtStringLong() {
    return formatDate(new Date(Date.parse(this.seenAt)));
  }

  dup() {
    return new EveryActionError(this);
  }
}

export default EveryActionError;
