import { path } from '+/utils/pathFinder';
import appConfig from '+/appConfig';
import { buildDayMonthString, getDateRangeString } from '+/utils/timeZonesHelper';
import { getSearchMatches } from '+/utils/search';

import profiles from './actionProfiles';
import FrontEndModel from './FrontEndModel';
import { capitalize } from './modelHelpers';

const sampledSubjectCache = {};

class Action extends FrontEndModel {
  constructor(data, actionType) {
    super(data);
    if (!this.tags) this.tags = [];
    // Updated at froze to allow sorting by updated at with in-place updating.
    this.frozenUpdatedAt = this.frozenUpdatedAt || this.updatedAt;
    this.actionType = this.actionType || actionType;
    this.type = `${this.actionType}_action`;
    if (this.actionType === 'custom' && !this.actionTypeTemplate) {
      this.actionTypeTemplate = {};
    }
    if (this.startTime) this.startTime = new Date(this.startTime);
    if (this.endTime) this.endTime = new Date(this.endTime);
    this.replyToUsers =
      typeof this.replyToUsers === 'string' ? this.replyToUsers.split(',') : this.replyToUsers;
  }

  identifier() {
    return `${this.type}#${this.id}`;
  }

  reactIcon() {
    if (this.isNotCustom()) {
      return profiles[this.actionType].icon;
    }
    return null;
  }

  iconSVGUrl() {
    if (this.isCustom()) {
      return this.actionTypeTemplate.iconSVGUrl;
    }
    return null;
  }

  iconSVGCode() {
    if (this.isCustom()) {
      return this.actionTypeTemplate.iconSVGCode;
    }
    return null;
  }

  displayLabel() {
    if (this.isNotCustom()) {
      return profiles[this.actionType].displayLabel;
    }
    return this.actionTypeTemplate.label;
  }

  inputLabel() {
    if (this.isNotCustom()) {
      return profiles[this.actionType].inputLabel;
    }
    return this.actionTypeTemplate.inputLabel;
  }

  actionPhrase() {
    if (this.isNotCustom()) {
      return profiles[this.actionType].actionPhrase;
    }
    return this.actionTypeTemplate.actionPhrase;
  }

  color() {
    if (this.isNotCustom()) {
      return profiles[this.actionType].color;
    }
    return this.actionTypeTemplate.color;
  }

  textColor() {
    if (this.isNotCustom()) {
      return profiles[this.actionType].textColor;
    }
    return this.actionTypeTemplate.textColor;
  }

  isHidden() {
    if (!this.limit) { return false }
    if (!this.showAgain) { return this.currentCompletionCount >= this.actionLimit}

    return this.showAgainDate && new Date(this.showAgainDate) > new Date();
  }

  isActive() {
    return (
      this.enabled &&
      (!this.startTime || this.startTime < new Date()) &&
      (!this.endTime || this.endTime > new Date()) &&
      (!this.isHidden())
    );
  }

  isQueued() {
    return (
      this.enabled &&
      ((this.startTime && this.startTime > new Date()) ||
      (this.limit && this.showAgain && this.showAgainDate && new Date(this.showAgainDate) > new Date()))
    );
  }

  isEnded() {
    return this.endTime && this.endTime < new Date();
  }

  isDisabled() {
    return !this.enabled;
  }

  snoozeKey() {
    return `snooze_${this.identifier()}`;
  }

  snooze() {
    localStorage.setItem(this.snoozeKey(), new Date());
  }

  isSnoozed() {
    const snoozedDatum = localStorage.getItem(this.snoozeKey());
    const snoozedOn = snoozedDatum && new Date(snoozedDatum);
    if (!snoozedOn) return false;
    const expiryDate = new Date(snoozedOn.getTime() + 1000 * 60 * 60 * 24 * 3);
    if (expiryDate > new Date()) return true;
    localStorage.removeItem(this.snoozeKey());
    return false;
  }

  dateRangeString() {
    return getDateRangeString(this.startTime, this.endTime, this.timeZone) || '';
  }

  showAgainDateString() {
    return this.showAgainDate && buildDayMonthString(new Date(this.showAgainDate), this.timeZone);
  }

  characterLimit() {
    return this.actionType === 'twitter' ? 280 : null;
  }

  // Returns promise
  async sampleContent() {
    const { contents = [] } = this;
    const contextIndex = Math.floor(Math.random() * contents.length);
    const randomContent = contents[contextIndex];

    return this.customParseIfNeeded(randomContent);
  }

  async getContent(idx) {
    const { contents = [] } = this;
    const randomContent = contents[idx];

    return this.customParseIfNeeded(randomContent);
  }

  // Returns promise
  async sampleSubject() {
    const identifier = this.identifier();

    if (sampledSubjectCache[identifier]) return sampledSubjectCache[identifier];

    const { subjects = [] } = this;
    const randomSubject = subjects[Math.floor(Math.random() * subjects.length)];

    const parseSubjectPromise = this.customParseIfNeeded(randomSubject);

    return parseSubjectPromise.then(parsedSubject => {
      if (!sampledSubjectCache[identifier]) sampledSubjectCache[identifier] = parsedSubject;

      return sampledSubjectCache[identifier];
    });
  }

  async getTo() {
    return this.customParseIfNeeded(this.to);
  }

  async getTitle() {
    return this.customParseIfNeeded(this.title);
  }

  async getDescription() {
    return this.customParseIfNeeded(this.description);
  }

  async getCC() {
    return this.customParseIfNeeded(this.cc);
  }

  async getBCC() {
    return this.customParseIfNeeded(this.bcc);
  }

  async getReplyTo() {
    return this.customParseIfNeeded(this.replyTo);
  }

  async customParseIfNeeded(value) {
    if (!appConfig.enableCustomContentParsers) {
      return value;
    }

    return import('+/utils/customContentParser').then(({ parser }) =>
      parser(value, this.identifier()),
    );
  }

  hasTag(tagToCheck) {
    return !!this.tags.filter(tag => tag.id === tagToCheck.id)[0];
  }

  contentMatches(string) {
    let contentString;
    // Can search for specific fields by value:name
    if (string.includes(':')) {
      contentString = Object.keys(this)
        .map(k => `${k}:${this[k]}`)
        .join(';');
      contentString += this.tags.map(t => `tag:${t.name}`).join(';');
    } else {
      contentString = Object.values(this).join(';');
      contentString += this.tags.map(t => t.name).join(';');
    }
    return (
      getSearchMatches(contentString, string)
    );
  }

  hasTagsMatching(matcher) {
    if (matcher === '') return true;
    const regexMatcher = new RegExp(matcher.split('').join('.*'), 'i');
    return this.tags.filter(tag => tag.name.match(regexMatcher)).length > 0;
  }

  hasContent() {
    return !!this.contents;
  }

  hasTarget() {
    const link = (this.links && this.links[0]) || this.target;
    return (this.actionType === 'twitter' && this.intent) || (link && link !== '');
  }

  hasNoTarget() {
    return !this.hasTarget();
  }

  async linkFor(user) {
    const value = this.links && this.links[user.id % this.links.length];
    return this.customParseIfNeeded(value);
  }

  isCopiable() {
    return ['email', 'facebook', 'other', 'linkedin', 'petition'].includes(this.actionType);
  }

  isCustom() {
    return this.type === 'custom_action';
  }

  isNotCustom() {
    return !this.isCustom();
  }

  maxChars() {
    switch (this.actionType) {
      case 'twitter':
        if (!this.replyToUsers) return 280;
        return (
          280 -
          (this.replyToUsers.reduce((a, u) => a + u.length + 1, 0) + // sum of all usernames
            (this.replyToUsers.length - 1)) // for spaces between names
        );
      default:
        return null;
    }
  }

  copyToDuplicate() {
    const newAction = new Action({ ...this }, this.actionType);
    newAction.title = `Copy of ${newAction.title}`;
    newAction.id = 'duplicate';
    return newAction;
  }

  actionTypeCapitalized() {
    return capitalize(this.actionType);
  }

  actionTypeTable() {
    return `${this.actionType}_action`;
  }

  actionPath(base = '') {
    const { campaignId, actionType, id } = this;
    if (this.campaignId) {
      return `${base}${path('campaigns.action', { campaignId, actionType, actionId: id })}`;
    }
    return `${base}${path('action', { actionType, actionId: id })}`;
  }

  update(newData) {
    Object.assign(this, newData);
    if (this.startTime) this.startTime = new Date(this.startTime);
    if (this.endTime) this.endTime = new Date(this.endTime);
  }

  supplementCampaign(campaignsStore) {
    if (this.campaignId) {
      const campaign = campaignsStore.find(this.campaignId);
      this.campaignName = campaign ? campaign.name : '';
    }
  }
}

export default Action;
