import React from 'react';
import PropTypes from 'prop-types';

import { ActionFeed } from '+/components/ActionFeed';
import { StyledH1 } from '+/styledComponents/elements/typography';
import { StyledColumn } from '+/styledComponents/elements/containers';
import StyledRow from '+/styledComponents/StyledRow';
import StyledButton from '+/styledComponents/StyledButtons';
import { StyledLoadingIndicator } from '+/styledComponents/StyledLoadingIndicator';
import TextReader from '+/utils/TextReader';

const { t } = new TextReader('room');

export const Room = (props) => {
  const { displayedActionsForRoom, addActionsDisplayedForRoom, room } = props;

  if (!room) {
    return (
      <StyledColumn center style={{ minHeight: '70vh' }}>
        <StyledLoadingIndicator />
      </StyledColumn>
    );
  }

  if (room && room.isMissing()) {
    return (
      <StyledColumn spaceAround style={{ minHeight: '70vh' }}>
        <StyledH1>Page not found</StyledH1>
      </StyledColumn>
    );
  }

  React.useEffect(() => {
    if (!displayedActionsForRoom) {
      addActionsDisplayedForRoom({ roomPath: room.path, actions: room.actions() });
    }
  }, []);

  if (!displayedActionsForRoom) {
    return null;
  }

  const renderGetMoreButton = () => {
    if (
      displayedActionsForRoom.totalActionsCount() >=
      room
        .actions()
        .unsnoozed()
        .totalActionsCount()
    ) {
      return null;
    }
    return (
      <StyledButton
        primary
        onClick={() => addActionsDisplayedForRoom({ roomPath: room.path, actions: room.actions() })}
        data-idf="button-get-more-actions"
      >
        {t('getMoreActionsButtonText')}
      </StyledButton>
    );
  };

  return (
    <>
      <ActionFeed
        basePath={`/${room.path}`}
        returnPath={`/${room.path}`}
        actions={displayedActionsForRoom}
        isRoom
      />
      <StyledRow padded center key="moreButton">
        {renderGetMoreButton()}
      </StyledRow>
    </>
  );
};

Room.propTypes = {
  room: PropTypes.models.room,
  displayedActionsForRoom: PropTypes.models.actionsStore,
  addActionsDisplayedForRoom: PropTypes.func.isRequired,
};

Room.defaultProps = {
  room: null,
  displayedActionsForRoom: null,
};
