// Takes Room data from server and stores it in object with helper methods for accessing it.

import ActionsStore from './ActionsStore';
import FrontEndModel from './FrontEndModel';

class Room extends FrontEndModel {
  constructor(data) {
    super(data);
    this.modelType = Room;
  }

  dup() {
    const room = new Room(this);
    room.assignedActions = {...room.assignedActions};

    Object.keys(room.assignedActions).forEach(key => {
      room.assignedActions[key] = [...room.assignedActions[key]];
    });
    return room;
  }

  isActive() {
    return this.enabled === true;
  }

  isMissing() {
    return this.missing === true;
  }

  actions() {
    const allActions = Object.values(this.assignedActions).reduce(
      (accum, arr) => [...accum, ...arr],
      [],
    );
    return new ActionsStore(allActions);
  }
}

export default Room;
