import FrontEndStore from './FrontEndStore';
import ActionTypeTemplate from './ActionTypeTemplate';

//
// Used in the redux and components to organize action related data and provide helper methods.
//

class ActionTypeTemplatesStore extends FrontEndStore {
  constructor(actionTypeTemplateData) {
    super();
    this.loading = !actionTypeTemplateData; // If no data object supplied, assume unloaded.
    this.store = (
      (actionTypeTemplateData && actionTypeTemplateData.store) ||
      actionTypeTemplateData ||
      []
    )
      // In case cached code doesn't include new types ↓
      .map(datum => new ActionTypeTemplate(datum));
  }

  // FILTERS / Arrays

  all() {
    return this.store;
  }

  findByName(name) {
    return this.store.find(aTT => aTT.name === name);
  }

  slice(...args) {
    return this.all().slice(...args);
  }
}

export default ActionTypeTemplatesStore;
