import { addToast, removeToast } from '+/actions';
import Toast from '+/models/Toast';

import store from './store';

export function notifyError(response) {
  if (response.errors) {
    response.errors.forEach(error => {
      const toast = new Toast({ body: error, type: 'warning' });
      store.dispatch(addToast(toast));
      setTimeout(() => store.dispatch(removeToast(toast.id)), 5000);
    });
  }
  return !!response.errors;
}

export function notifySuccess(message) {
  const toast = new Toast({ body: message, type: 'success' });
  store.dispatch(addToast(toast));
  setTimeout(() => store.dispatch(removeToast(toast.id)), 3000);
}

export function notifyNeutral(message) {
  const toast = new Toast({ body: message, type: 'info' });
  store.dispatch(addToast(toast));
}

export function notifyResult(message) {
  if (!notifyError(message)) notifyResult(message);
}
