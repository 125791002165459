import styled from 'styled-components';
import themeVars, { ifProp } from '+/utils/themeVars';

const StyledModal = {};

StyledModal.Container = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${themeVars('modalZIndex')};

  background-color: rgba(0, 0, 0, 0.8);

  &:empty {
    display: none;
  }

  & > * {
    margin: auto;
  }
`;

StyledModal.NoBox = styled.div`
  max-width: 37rem;
  width: 87.5%;
  z-index: 2;

  &:after,
  &:before {
    display: block;
    height: 2rem;
    content: '';
  }
`;

StyledModal.Box = styled.div`
  margin: auto;
  max-width: ${ifProp('narrow', '25rem', ifProp('wide', '60rem', '33em'))};
  padding: 1.5rem;
  position: relative;
  width: 87.5%;
  z-index: 2;

  background: ${themeVars('colorWhite')};
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

StyledModal.CloseButton = styled.button`
  padding: 0.125rem 0.5rem;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  z-index: 5;

  background: none;
  border: none;

  font-size: 1.875rem;
  line-height: 1;

  cursor: pointer;
`;

StyledModal.Overlay = styled.div`
  margin: 0 0 calc(-100vh); /* Fallback for browsers that do not support Custom Properties */
  margin: 0 0 calc(var(--vh, 1vh) * -100);
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
  position: sticky;
  top: 0;
  width: 100%;
`;

StyledModal.TextContent = styled.div`
  color: ${themeVars('colorDarkGrey')};

  p {
    color:inherit;
    font-size: 0.875rem;
  }
  
  a {
    color: inherit;
  }
`;

export default StyledModal;
