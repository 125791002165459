import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from '+/instances/propTypesInstance';
import { path } from '+/utils/pathFinder';

export const SignOut = ({ signOutUser }) => {
  signOutUser();
  return <Redirect to={path('signIn')} />;
};

SignOut.propTypes = {
  signOutUser: PropTypes.func.isRequired,
};
