// ====================================================================
// Theme Settings (CSS)
// ====================================================================
// /
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styledComponents.
//
// It extends _themeCustom and is extended by _themeDarkCustom.
// --------------------------------------------------------------------

// Colors
// --------------------------------------------------------------------

// Sections
// --------------------------------------------------------------------

export default variables => ({
  ...variables,

  textColorDefault: variables.colorWhite,
});
