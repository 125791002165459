const themeVars = name => props => props.theme[name];

const isFunction = possibleFunc => typeof possibleFunc === 'function';
const isMapping = possibleMapping =>
  possibleMapping && !(possibleMapping instanceof String || typeof possibleMapping === 'string');
const callIfFunction = (possibleFunc, argument) =>
  (isFunction(possibleFunc) ? possibleFunc(argument) : possibleFunc);

// Retrieves the value of the first mapped prop (and calls if function).
// firstValueFrom({ propA, propB }, { propA: 12 }) returns 12.
const firstValueFrom = (props, mapping) => {
  for (const prop of Object.keys(props)) {
    if (props[prop] && mapping[prop]) return callIfFunction(mapping[prop], props);
  }
  return callIfFunction(mapping.default, props);
};

// If the styled component has a prop whose name matches the first value,
// returns the second value (and calls with props if it is a function)
// else returns the third value (and calls with props if it is a function)
// i.e. ifProp("fullWidth", "100%", themeVars("defaultWidth"))
// Also accepts mappings
// i.e. ifProp({fullWidth: "100%", halfWidth: "50%", default: themeVars("defaultWidth"})
export const ifProp = (nameOrMapping, valueIfPresent, valueIfAbsent) => (props) => {
  if (isMapping(nameOrMapping)) {
    return firstValueFrom(props, nameOrMapping);
  }
  const returnValue = props[nameOrMapping] ? valueIfPresent : valueIfAbsent;
  return callIfFunction(returnValue, props);
};

// If the styled component has a prop whose name matches the first value,
// whose value matches the second,
// returns the third (and calls with props if it is a function)
// else returns the fourth (and calls with props if is a function
// i.e. ifPropEquals("signedin", "true", "red", "white")
export const ifPropEquals = (name, target, valueIfPresent, valueIfAbsent) => (props) => {
  const targetMatched = props[name] === target;
  const returnValue = targetMatched ? valueIfPresent : valueIfAbsent;
  return callIfFunction(returnValue, props);
};

// fromProps("action.color");
export const fromProps = name => props =>
  name.split('.').reduce((accum, nextName) => accum && accum[nextName], props);

export default themeVars;
