import RoomsStore from '+/models/RoomsStore';
import Room from '+/models/Room';

const defaultStore = { all: new RoomsStore([]), individual: {} };

// eslint-disable-next-line default-param-last
const room = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
    case 'SET_ALL_ROOMS': {
      const newAllRooms = new RoomsStore(action.payload);
      newStore.all = newAllRooms;
      break;
    }
    case 'SET_ROOM': {
      const { data, id } = action.payload;
      const newRoom = new Room(data.errors ? { missing: true } : data);
      newStore.individual[id] = newRoom;
      break;
    }
    case 'REMOVE_ELIGIBLE_ACTION': {
      const { actionType, actionId } = action.payload;
      newStore.individual = { ...store.individual };
      Object.keys(store.individual).forEach(id => {
        const oldRoom = store.individual[id] || new Room();
        const newRoom = oldRoom.dup();
        newRoom.assignedActions[actionType] = newRoom.assignedActions[actionType].filter(
          actionData => actionData.id !== actionId,
        );
        newStore.individual[id] = newRoom;
      });
      break;
    }
    case 'COMPLETE_ELIGIBLE_ACTION': {
      const { actionType, actionId } = action.payload;
      newStore.individual = { ...store.individual };
      Object.keys(store.individual).forEach(id => {
        const oldRoom = store.individual[id] || new Room();
        const newRoom = oldRoom.dup();
        newRoom.assignedActions[actionType] = newRoom.assignedActions[actionType].map(a => {
          const newAction = { ...a };
          if (a.id === actionId) {
            // eslint-disable-next-line no-param-reassign
            newAction.completed = true;
          }
          return newAction;
        });
        newStore.individual[id] = newRoom;
      });
      break;
    }
    default:
      break;
  }
  return newStore;
};

export default room;
