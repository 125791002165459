// ====================================================================
// Theme Settings Base (CSS)
// ====================================================================
//
// This file defines the palette to be used throughout the site.
//
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

// Instance Specific
//

// Actual colors

const base = {
  colorDarkGreen: '#046a38',
  colorBlack: '#000',
  // colorDarkYellow: '#F29517',
  colorDarkYellow: '#F29616', // Official
  colorLightestGrey: '#e8e9e9',
  colorLightGrey: '#DEE0E0', // Official
  colorGrey: '#CACBCC', // Official
  colorFadedDarkGrey: '#737373',
  colorMediumGrey: '#28333D', // Official
  colorMediumLightGrey: '#2b3843',
  colorMediumLighterGrey: '#32404d',
  colorDarkGrey: '#1D252C', // Official
  colorGreen: '#32B59D', // Official
  colorLightGreen: '#009579',
  colorOffWhite: '#F2F4F5', // Official
  colorPink: '#f5afaf',
  colorRed: '#DE3412',
  colorBrightRed: '#fe3b1f',
  colorDarkRed: '#BD0802',
  colorWhite: '#FFF', // Official
  colorOffwhite: '#f2f4f5',
  colorYellow: '#ffb600',
  colorLime: '#C2D500',
  colorBlue: '#00abc8',

  // Brand colors
  colorFacebook: '#3B5998',
  colorTwitter: '#1DA1F2',
  colorLinkedin: '#0073B0',

  // Font Families
  fontOpenSans: '\'Open Sans\', sans-serif',
  fontMontserrat: '\'Montserrat\', sans-serif',
  fontNotoSerif: '\'Noto Serif\', serif',

  // Sizes
  sizeMico: '.625rem',
  sizeTiny: '.9375rem',
  sizeSmall: '1rem',
  sizeRegular: '1.125rem',
  sizeRegularPlus: '1.375rem',
  sizeLargeMinus: '1.5rem',
  sizeLarge: '1.75rem',
  sizeVeryLarge: '2rem',
  sizeHuge: '3rem',
  sizeVeryHuge: '3.75em',

  // Easing
  easeInExpo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
  easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  easeInOutExpo: 'cubic-bezier(1, 0, 0, 1)',
};

export default base;
