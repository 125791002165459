import React from 'react';

import TextReader from '+/utils/TextReader';
import appConfig from '+/appConfig';

import StyledRow from '+/styledComponents/StyledRow';

import { StyledColumn, StyledStretchDiv, StyledDiv } from '+/styledComponents/elements/containers';
import { StyledBackground } from '+/styledComponents/elements/backgrounds';
import Theme from '+/instances/Theme';
import { StyledP } from '+/styledComponents/elements/typography';

const { t } = new TextReader('password.nextStep');

export const ResetPasswordConfirmation = () => (
  <StyledStretchDiv>
    <Theme dark>
      <StyledBackground
        imageDesktop={appConfig.passwordBackgroundImageDesktop}
        imageMobile={appConfig.passwordBackgroundImageMobile}
        webPDesktop={appConfig.passwordBackgroundImageDesktopWebP}
        webPMobile={appConfig.passwordBackgroundImageMobileWebP}
        darken={0.7}
        darkenMobile={0.3}
      >
        <StyledColumn fullHeight fullWidth spaceAround center>
          <StyledDiv>
            <StyledRow padded>
              <StyledP>
                <strong>{t('label')}</strong>&nbsp; {t('text')}
              </StyledP>
            </StyledRow>
          </StyledDiv>
        </StyledColumn>
      </StyledBackground>
    </Theme>
  </StyledStretchDiv>
);
