import styled, { css } from 'styled-components';
import React from 'react';

import PropTypes from '+/instances/propTypesInstance';
import { ifProp } from '+/utils/themeVars';
import appConfig from '+/appConfig';

export const StyledLoadingIndicator = props => (
  <StyledLoadingIndicatorContainer {...props}>
    <img src={appConfig.loadingIndicator} alt="" />
  </StyledLoadingIndicatorContainer>
);

StyledLoadingIndicator.propTypes = {
  muted: PropTypes.bool,
  size: PropTypes.number,
};

StyledLoadingIndicator.defaultProps = {
  muted: null,
  size: 120,
};

export const StyledLoadingIndicatorContainer = styled.div`
  height: ${props => props.size}px;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.size}px;
  ${ifProp('muted', css`
    opacity: 0.5;
    filter: grayscale(100%);
  `)};
`;
