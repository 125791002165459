import React from 'react';

import { AvatarButton, AvatarImage } from '+/styledComponents/StyledAvatar';
import PropTypes from '+/instances/propTypesInstance';
import TextReader from '+/utils/TextReader';

const { t } = new TextReader('awards');

export const Avatar = ({ badge, handleShowAwardDetails, userName }) => (
  <AvatarButton onClick={badge && handleShowAwardDetails} disable={!badge}>
    <AvatarImage
      src={badge ? badge.icon : t('defaultBadgeIcon')}
      alt={badge ? badge.name : userName}
    />
  </AvatarButton>
);

Avatar.propTypes = {
  badge: PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string,
  }),
  handleShowAwardDetails: PropTypes.func,
  userName: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  badge: null,
  handleShowAwardDetails: null,
};
