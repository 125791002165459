// ====================================================================
// Theme Settings (CSS)
// ====================================================================

// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  headerBackground: variables.colorBlack,

  // Tabs
  tabColorSelected: variables.colorRed,
  tabColorUnselected: variables.colorBlack,
  tabColorUnselectedHover: variables.colorBlack,
  tabBackgroundSelected: variables.colorWhite,
  tabBackgroundUnselected: variables.colorLightGrey,
  tabBackgroundUnselectedHover: variables.colorMediumLightGrey,
  tabColorAccent: variables.colorAccent,
});
