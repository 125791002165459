import React from 'react';
import { NavLink } from 'react-router-dom';

import gtm from '+/instances/googleTagManager';
import PropTypes from '+/instances/propTypesInstance';
import { capitalize } from '+/models/modelHelpers';

export default class NavLinkInstance extends React.PureComponent {
  render() {
    return <NavLink {...this.props} onClick={this.handleClick} />;
  }

  // Handlers

  handleClick = (e) => {
    const { 'data-idf': dataIdf, action, user, onClick } = this.props;
    if (onClick) onClick(e);
    gtm.push({
      event: 'Click',
      elementType: 'Link',
      element: dataIdf
        .replace('link-', '')
        .split('-')
        .map(w => capitalize(w))
        .join(' '),
      actionId: action && action.identifier(),
      userId: user && user.identifier(),
    });
  };
}

NavLinkInstance.propTypes = {
  'data-idf': PropTypes.string,
  action: PropTypes.models.Action,
  user: PropTypes.models.User,
  onClick: PropTypes.func,
};

NavLinkInstance.defaultProps = {
  'data-idf': 'unknown',
  action: null,
  user: null,
  onClick: null,
};
