import styled, { css } from 'styled-components';

import themeVars, { ifProp } from '+/utils/themeVars';

const Section = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
${ifProp('fullHeight', css`
  min-height: calc(100vh - ${themeVars('headerHeight')});
  `,
  )};
  padding: ${themeVars('sectionPadding')};
  width: ${ifProp({ fullWidth: '100%' })};
`;

export default Section;
