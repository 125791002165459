import { FaCheckCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledToast } from '+/styledComponents/StyledToasts';
import { Markdown } from '+/components/Markdown';

export class Toast extends React.PureComponent {
  render() {
    const { data } = this.props;
    const containsMarkdownLink = data.body.match(/\[.+\]\(.+\)/);
    return (
      <StyledToast.Box {...{ [data.type || 'info']: true }} data-idf="toast">
        <StyledToast.Content role="alert">
          {data.type === 'success' && (
            <StyledToast.Icon>
              <FaCheckCircle size="25" />
            </StyledToast.Icon>
          )}
          <StyledToast.Body>
            {containsMarkdownLink ? <Markdown source={data.body}></Markdown> : data.body}
          </StyledToast.Body>
        </StyledToast.Content>
      </StyledToast.Box>
    );
  }
}

Toast.propTypes = {
  data: PropTypes.object.isRequired,
};
