import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import TextReader from '+/utils/TextReader';
import { StyledH1, StyledP } from '+/styledComponents/elements/typography';
import { StyledInTextAnchor } from '+/styledComponents/StyledLinks';
import StyledAnnouncementCard from '+/styledComponents/StyledAnnouncementCard';
import Theme from '+/instances/Theme';

const { t } = new TextReader('actionFeed');

export const ActionFeedEmptiedMessage = ({ isRoom }) =>
  (isRoom ? (
    <StyledAnnouncementCard.Container>
      <StyledAnnouncementCard.Title>
        <span role="img" aria-label="celebrate">
          🎉
        </span>
        <StyledAnnouncementCard.H1>
          {t('roomActionsCompletedMessage.title')}
        </StyledAnnouncementCard.H1>
        <span role="img" aria-label="celebrate">
          🎉
        </span>
      </StyledAnnouncementCard.Title>
      <StyledAnnouncementCard.SubTitle>
        <StyledP center>{t('roomActionsCompletedMessage.subtitle')}</StyledP>
        <span role="img" aria-label="clap">
          👏👏👏👏
        </span>
      </StyledAnnouncementCard.SubTitle>
      <StyledP
        center
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('roomActionsCompletedMessage.text', {
            link: ReactDOMServer.renderToStaticMarkup(
              <Theme light>
                <StyledInTextAnchor
                  href={t('roomActionsCompletedMessage.link.url')}
                  className="thl-twitter-link"
                >
                  {t('roomActionsCompletedMessage.link.text')}
                </StyledInTextAnchor>
              </Theme>,
            ),
          }),
        }}
      />
    </StyledAnnouncementCard.Container>
  ) : (
    <StyledAnnouncementCard.Container>
      <StyledH1>{t('actionsCompletedMessage.title')}</StyledH1>
      <StyledP center>
        <span role="img" aria-label="celebrate">
          🎉
        </span>{' '}
        {t('actionsCompletedMessage.subtitle')}{' '}
        <span role="img" aria-label="celebrate">
          🎉
        </span>
      </StyledP>
      <StyledP
        center
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('actionsCompletedMessage.text', {
            link: ReactDOMServer.renderToStaticMarkup(
              <Theme light>
                <StyledInTextAnchor
                  href={t('actionsCompletedMessage.link.url')}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="thl-twitter-link"
                >
                  {t('actionsCompletedMessage.link.text')}
                </StyledInTextAnchor>
              </Theme>,
            ),
          }),
        }}
      />
    </StyledAnnouncementCard.Container>
  ));

ActionFeedEmptiedMessage.propTypes = {
  isRoom: PropTypes.bool,
};

ActionFeedEmptiedMessage.defaultProps = {
  isRoom: false,
};
