import TextReader from '+/utils/TextReader';

const { t } = new TextReader('awards');

export const getUserBadges = (points, longestStreak) => {
  const achievedBadges = t('badges').filter((badge) => {
    const isAchieved = points >= badge.requiredPoints &&
      longestStreak >= badge.requiredStreak;
    return isAchieved;
  });
  return achievedBadges;
};
