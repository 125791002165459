/**
 * Exports Campaign Store class
 * @module CampaignsStore
 */

import Campaign from './Campaign';
import FrontEndStore from './FrontEndStore';

export default class CampaignsStore extends FrontEndStore {
  constructor(campaignData) {
    super();
    this.status = campaignData ? 'loaded' : 'unloaded';
    this.store = ((campaignData && campaignData.store) || campaignData || []).map(
      c => new Campaign(c),
    );
    this.storeType = CampaignsStore;
  }

  justEnabled() {
    return new CampaignsStore(this.store.filter(c => c.isEnabled()));
  }

  justChildrenOf(campaign) {
    return new CampaignsStore(this.store.filter(c => c.parentCampaignId === campaign.id));
  }

  justParents() {
    return new CampaignsStore(this.store.filter(c => !c.parentCampaignId));
  }

  updateCampaign(campaignData) {
    const campaign = this.find(campaignData.id);
    Object.assign(campaign, campaignData);
  }

  orderedByRecency() {
    return this.store.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
  }

  /**
   * Builds options for campaign drop downs.
   *
   * @public
   * @return {array} An array of objects with label and value keys.
   */
  toOptions() {
    const pushChildren = (items, parent, indent = 0) => {
      this.justChildrenOf(parent)
        .toArray()
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .forEach(child => {
          let spaces = '';
          for (let i = 0; i <= indent; i++) {
            spaces += '&nbsp;&nbsp;&nbsp;';
          }
          items.push({ label: `${spaces}└${child.name}`, value: child.id });
          pushChildren(items, child, indent + 1);
        });
    };
    const parents = this.store
      .filter(c => c.isParent())
      .sort((a, b) => (a.name < b.name ? -1 : 1));
    const allOptions = parents.map(parent => {
      const items = [];
      items.push({ label: parent.name, value: parent.id });
      pushChildren(items, parent);
      return items;
    });
    return allOptions.reduce((accum, array) => accum.concat(array), []);
  }
}
