import styled, { css } from 'styled-components';

import themeVars, { fromProps, ifProp } from '+/utils/themeVars';
import media from '+/utils/media';

const StyledRow = styled.div`
  align-items: ${ifProp({ middle: 'center' })};
  display: flex;
  flex-direction: row;
  flex-wrap: ${ifProp({ noWrap: 'nowrap', default: 'wrap' })};
  justify-content: ${ifProp('center', 'center')};
  justify-content: ${ifProp('spaceBetween', 'space-between')};
  justify-content: ${ifProp('spaceAround', 'space-around')};
  margin: ${fromProps('margin')};
  padding: ${ifProp('padded', '1em 0')};
  position: relative;
  width: ${ifProp('halfWidth', '48%', '100%')};

  & > * {
    max-width: 100%;
  }

  ${ifProp({
    blockOnMobile: css`
      display: block;
      ${media.md` display: flex; `};
`,

  })};
  ${ifProp({
    warning: css`
      color: ${themeVars('colorRed')};
`,
  })};
`;

export default StyledRow;
