// ====================================================================
// Theme Settings Assignments Custom (CSS)
// ====================================================================
//
// This file contains modifications of the assignments of the base palette to specific roles.
// Modifications are included here to avoid conflicts in git.
//
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styled-components.
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  colorPrimary: variables.colorBlack,
  colorDarkPrimary: variables.colorRed,
  colorWarning: variables.colorDarkGrey,
  colorSecondary: variables.colorDarkGrey,

  fontReadable: variables.fontSourceSansPro,
  fontFormal: variables.fontMuli,

  // Text colors
  textColorDefault: variables.colorBlack,
  textColorPrimary: variables.colorWhite, // On top of primary color
  textColorDanger: variables.colorWhite, // On top of danger color
  textColorWarning: variables.colorLightGrey, // On top of warning color
  textColorSecondary: variables.colorLightGrey, // On top of secondary color
  textColorDisabled: variables.colorFadedDarkGrey,
  textColorFaded: variables.colorGrey,
  textColorExtraFaded: variables.colorLightGrey,
});
