import appConfig from '+/appConfig';
import store from './store';
import { createPushSubscription, testPushSubscription } from '+/utils/apiCalls';
import { addUserPushSubscription } from '+/actions';

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
};

const getSubscription = async() => {
  const { publicVapid } = appConfig;
  const { pushManager } = await navigator.serviceWorker.register('/push-service-worker.js', {
    scope: '/',
  });
  const subscriptionOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapid),
  };
  return pushManager.subscribe(subscriptionOptions);
};

export const subscribe = async() => {
  if (!navigator.serviceWorker) return false;
  const subscription = await getSubscription();
  const responseData = await createPushSubscription(subscription);
  store.dispatch(addUserPushSubscription(responseData));
  testPushSubscription({ endpoint: subscription.endpoint });
  return true;
};
