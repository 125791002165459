/**
 * Exports default properties for appConfig object.
 * @module appConfigDefault
 */

module.exports = {
  mainLogo: '/fan-logo.png',
  headerLogo: '/fast-action-network.svg',
  headerIcon: '/mended-heart.svg',
  faviconSvg: 'favicon.svg',
  faviconIco: 'favicon.ico',
  appleTouchIcon: 'apple-touch-icon.png',
  themeColor: '#ffb600',
  manifest: 'manifest.json',
  siteTitle: 'Fast Action Network',
  siteName: 'Fast Action Network',
  siteDescription: 'Access meaningful and relevant online actions for animals any time of the day. In a matter of minutes, you can drive these campaigns forward, all from the comfort and convenience of your home, office, or wherever life may take you.',
  shareImage: 'https://the-humane-league-share.s3.amazonaws.com/action/share-image.jpg',
  shareImageSecure: 'https://the-humane-league-share.s3.amazonaws.com/action/share-image.jpg',
  language: 'en-US',
  twitterSite: '@thehumaneleague',
  organizationTitle: 'The Humane League',
  googleFonts: ['Open Sans:300,400,600,700', 'Montserrat:400,700', 'Noto Serif:400i,400', 'Roboto:500'],
  googleClientId: '',
  googleTagManagerId: '',
  dailyActionEmailSendHour: 14, // 9 EST
  defaultEmailNotificationsSetting: 'daily',
  donateUrl: '', // Url to direct users to make donations
  disableWebsite: false,
  disabledWebsiteMessage: 'This site is down for maintanence.',
  enableCustomContentParsers: false,
  sendWelcomeEmails: true,
  signInMethods: ['google', 'password'],
  signInBackgroundImageMobile: '/assets/mended-heart-bg-pattern-mobile.png',
  signInBackgroundImageMobileWebP: '/assets/mended-heart-bg-pattern-mobile.webp',
  signInBackgroundImageDesktop: '/assets/herd-cows-sunset.jpg',
  signInBackgroundImageDesktopWebP: '/assets/herd-cows-sunset.webp',
  signUpBackgroundImageMobile: '/assets/mended-heart-bg-pattern-mobile.png',
  signUpBackgroundImageMobileWebP: '/assets/mended-heart-bg-pattern-mobile.webp',
  signUpBackgroundImageDesktop: '/assets/herd-cows-sunset.jpg',
  signUpBackgroundImageDesktopWebP: '/assets/herd-cows-sunset.webp',
  signUpShowZipCodeField: true,
  passwordBackgroundImageMobile: '/assets/mended-heart-bg-pattern-mobile.png',
  passwordBackgroundImageMobileWebP: '/assets/mended-heart-bg-pattern-mobile.webp',
  passwordBackgroundImageDesktop: '/assets/herd-cows-sunset.jpg',
  passwordBackgroundImageDesktopWebP: '/assets/herd-cows-sunset.webp',
  emailRestrictionPattern: {
    emailPattern: '',
    emailPatternLabel: '',
  },
  emailSender: {
    address: 'test-emails@thehumaneleague.org',
    name: 'Test Email',
    genericName: '',
  },
  userGroups: ['user', 'admin'],
  defaultUserGroup: 'user',
  feedbackFormUrl: null,
  publicVapid:
    'BDWfZdSJRFFFEOY2B3G0UY8mP6t4fkEAFkix8Mxp_5hX8v1ZOySBxiq3knSqYbvuKiIy79q6a2X8xYpOuzbBCjo',
  loadingIndicator: '/mended-heart-red-pulse.svg',
  animationIcon: '/mended-heart-red.svg',
  requireSubRegionId: false,
};
