// Takes User data from server and stores it in object with helper methods for accessing it.

import { path } from '+/utils/pathFinder';
import { getSearchMatches } from '+/utils/search';

import FrontEndModel from './FrontEndModel';
import actionProfiles from './actionProfiles';

const ACTION_TYPES = Object.keys(actionProfiles);

export const USER_PERMISSIONS = ['superadmin', 'admin', 'author', 'spectator', 'user', 'banned'];

class User extends FrontEndModel {
  constructor(userData = {}) {
    super({
      newActionEmailNotifications: 'never',
      phoneNumber: '',
      customActionTypesEnabled: [],
      ...userData, // Override defaults
      loaded: !!Object.entries(userData)[0],
      newActionEmailNotificationsEnabled: userData.newActionEmailNotifications === 'daily',
    });
  }

  isDefinitelyNotAdmin() {
    return (
      // is loaded and not admin
      ['user', 'banned'].includes(this.permissions) ||
      // Or is not yet loaded and an admin previously logged in from this browser.
      (this.isEmpty() &&
        (!document.cookie.match('fan-session') || !localStorage.getItem('isAdmin')))
    );
  }

  isAdmin() {
    return (
      ['spectator', 'author', 'admin', 'superadmin'].includes(this.permissions) ||
      (this.isEmpty() && document.cookie.match('fan-session') && !!localStorage.getItem('isAdmin'))
    );
  }

  canApproveUpdates() {
    return ['admin', 'superadmin'].includes(this.permissions);
  }

  canViewUsers() {
    return ['admin', 'superadmin'].includes(this.permissions);
  }

  canScrubUsers() {
    return ['superadmin'].includes(this.permissions);
  }

  isSuperAdmin() {
    return ['superadmin'].includes(this.permissions);
  }

  isJustAuthor() {
    return this.permissions === 'author';
  }

  isBanned() {
    return this.permissions === 'banned';
  }

  isNotBanned() {
    return !this.isBanned();
  }

  needsToSetPreferences() {
    return ACTION_TYPES.every(type => this[`${type}Enabled`] === false);
  }

  homePath() {
    if (this.isAdmin()) {
      return path('admin.index');
    }
    return path('home');
  }

  isEmpty() {
    return this.loaded !== true;
  }

  isLoaded() {
    return !!this.loaded;
  }

  name() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }

  initialAndLastName() {
    if (this.firstName === 'system') return 'System';
    return `${(this.firstName || '')[0]}. ${this.lastName || ''}`;
  }

  nameAndId() {
    return `${this.name()} (#${this.id})`;
  }

  identifier() {
    return `user#${this.id}`;
  }

  description() {
    if (['superadmin', 'admin', 'author', 'spectator'].includes(this.permissions)) {
      return this.permissions;
    }
    if (this.permissions === 'banned') {
      return 'Banned user';
    }
    return `${this.engagementString()} user receiving ${this.group} actions`;
  }

  engagementString() {
    const threeWeeksAgo = new Date(new Date() - 60 * 60 * 24 * 7 * 1000 * 3);
    if (new Date(this.createdAt) > threeWeeksAgo) {
      return 'new';
    }
    /* eslint-disable indent */
    switch (this.engagementScore()) {
      case 0:
        return 'unengaged';
      case 1:
        return 'occasional';
      case 2:
        return 'engaged';
      case 3:
        return 'highly engaged';
      default:
        return 'extremely engaged';
    }
    /* eslint-enable indent */
  }

  contentMatches(string) {
    const contentString = Object.values(this).join(';');
    return getSearchMatches(contentString, string);
  }

  analyticsType() {
    if (this.isAdmin()) return 'admin';
    return 'employee';
  }

  hasVerifiedEmail() {
    if (this.activeEmailAssignment) return this.activeEmailAssignment.verified;
    return null;
  }

  hasNotVerifiedEmailInAwhile() {
    if (this.activeEmailAssignment && !this.activeEmailAssignment.verified) {
      return this.activeEmailAddress.createdAt < new Date(new Date() - 1000 * 60 * 60 * 24 * 5);
    }
    return null;
  }

  needsBrowserNotificationSubscription() {
    return (
      window.Notification &&
      !['granted', 'denied'].includes(window.Notification.permission) &&
      this.newActionBrowserNotificationsEnabled
    );
  }

  // In months, the exact amount of time since signup.
  timeSinceSignup() {
    const createdAt = Date.parse(this.createdAt);
    const now = new Date();
    return (now - createdAt) / (30 * 24 * 60 * 60 * 1000);
  }

  // In months, the exact amount of time since signup.
  timeSinceSignupString() {
    const int = parseInt(this.timeSinceSignup() * 4, 10);
    if (int < 4) {
      return `${int} week${int !== 1 ? 's' : ''}`;
    }
    return `${int / 4} months`;
  }

  // The time since signup rounded to the nearest month.
  monthsSinceSignup() {
    const { floor, max } = Math;
    return floor(max(1, this.timeSinceSignup()));
  }

  engagementScore(takenCount) {
    const count = takenCount || this.takenCount || this.takenActionCount || 0;
    if (count === 0) return 0;
    const { floor, max, log2 } = Math;
    // This function returns nice numbers, with sensitivity weighted to lower values.
    return floor(log2(max(2, count / this.timeSinceSignup())));
  }

  latestActionTakenAtString() {
    if (!this.latestActionTakenAt) return 'never';
    return new Date(Date.parse(this.latestActionTakenAt)).toDateString();
  }

  disabledActionTypes() {
    const disabledActionTypes = new Set();
    for (const action of ACTION_TYPES) {
      if (!this[`${action}Enabled`]) {
        disabledActionTypes.add(action);
      }
    }
    return disabledActionTypes;
  }

  actionSettings() {
    const actionSettings = {};
    for (const action of ACTION_TYPES) {
      actionSettings[`${action}Enabled`] = this[`${action}Enabled`];
    }
    actionSettings.customActionTypesEnabled = this.customActionTypesEnabled;
    return actionSettings;
  }
}

export default User;
