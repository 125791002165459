import styled from 'styled-components';

import themeVars from '+/utils/themeVars';
import { StyledH5 } from '+/styledComponents/elements/typography';

const StyledSlideOutMenu = {};

StyledSlideOutMenu.Container = styled.div`
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding-top: ${themeVars('headerHeight')};
  position: fixed;
  top: 0;
  width: ${themeVars('slideOutMenuWidth')};
  z-index: ${themeVars('slideOutMenuZIndex')};

  background: #fff;
  box-shadow: 0 -40px 40px 0 rgba(0, 0, 0, 0.25);

  color: ${themeVars('colorDarkGrey')};
`;

StyledSlideOutMenu.Overlay = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${themeVars('slideOutMenuZIndex')};

  background-color: rgba(0, 0, 0, 0.5);
`;

StyledSlideOutMenu.Header = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${themeVars('slideOutMenuHeaderHeight')};
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: ${themeVars('slideOutMenuWidth')};

  background-color: ${themeVars('slideOutMenuHeaderBackground')};

  color: #fff;
  text-align: center;
`;

StyledSlideOutMenu.Body = styled.section`
  height: calc(100vh - ${themeVars('headerHeight')});
  overflow-y: auto;
`;

StyledSlideOutMenu.Logo = styled.img`
  display: block;
  height: ${themeVars('headerLogoHeight')};
  width: ${themeVars('headerLogoWidth')};
`;

StyledSlideOutMenu.User = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;

  background: #fff;

  text-align: center;
`;

StyledSlideOutMenu.UserName = styled(StyledH5)`
  margin: 0.75em 0 0;

  color: inherit;
`;

StyledSlideOutMenu.UserTitle = styled.div`
  margin-top: 0.5em;

  font-family: ${themeVars('fontFormal')};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  opacity: 0.5;
`;

StyledSlideOutMenu.Main = styled.nav`
  background-blend-mode: multiply, normal;
  background-image: ${themeVars('slideOutMenuBackground')};
`;

StyledSlideOutMenu.MainList = styled.ul`
  padding: 1em 0 1.125em;

  font-family: ${themeVars('fontFormal')};
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.055625em;
  line-height: 1.25;
  text-transform: uppercase;

  list-style: none;
`;

StyledSlideOutMenu.MainListItem = styled.li`
  a {
    align-items: center;
    display: flex;
    padding: 0.78125em 3.75em 0.78125em 2.0625em;

    color: ${props =>
    (props.donate ? themeVars('colorDanger') : themeVars('slideOutMenuLinkColor'))};

    transition: background-color 0.1s ease-out;

    &[aria-current='page'] {
      color: ${themeVars('slideOutMenuLinkColorActive')};
      text-decoration: underline;
    }

    &:hover {
      background: ${themeVars('slideOutMenuLinkBackgroundHover')};
    }

    svg {
      margin-right: 0.35rem;
    }
  }
`;

StyledSlideOutMenu.Hr = styled.hr`
  margin: 0 0 0 26px;
  width: 232px;

  border: none;
  border-top: 2px solid ${themeVars('slideOutMenuDividerColor')};
`;

StyledSlideOutMenu.Utility = styled.nav`
  padding-bottom: 3.75rem;
`;

StyledSlideOutMenu.UtilityList = styled.ul`
  padding: 1em 0;

  font-family: ${themeVars('fontMontserrat')};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.15385em;
  line-height: 3.4;
  text-transform: uppercase;

  list-style: none;
`;

StyledSlideOutMenu.UtilityListItem = styled.li`
  a {
    display: block;
    padding: 0 2.25rem;

    color: ${themeVars('slideOutMenuLinkColor')};

    transition: background-color 0.1s ease-out;

    &:hover {
      background: ${themeVars('slideOutMenuLinkBackgroundHover')};
    }
  }
`;

StyledSlideOutMenu.UtilityIcon = styled.span`
  display: inline-flex;
  margin-right: 1rem;
  margin-top: -0.1em;
  vertical-align: middle;
  font-size: 1.5rem;
`;

export default StyledSlideOutMenu;
