import Message from './Message';

class MessagesStore {
  constructor(messagesData) {
    this.loading = !messagesData || messagesData.loading;
    this.store = ((messagesData && (messagesData.store || messagesData)) || []).map(
      datum => new Message(datum),
    );
  }

  isEmpty() {
    return this.store.length === 0;
  }

  find(messageId) {
    for (const message of this.store) {
      if (message.id === messageId) return message;
    }
    return false;
  }

  dup() {
    return new MessagesStore(this.store);
  }

  map(func) {
    return this.store.map(func);
  }

  updateMessage(messageData) {
    const message = this.find(messageData.id);
    Object.assign(message, messageData);
  }

  anyUnseen() {
    return this.store.some(message => !message.seenAt);
  }

  sorted() {
    return this.store.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  }
}

export default MessagesStore;
