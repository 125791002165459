import React from 'react';

import { StyledStretchDiv } from '+/styledComponents/elements/containers';

const LazyAccount = React.lazy(() => import('./Account.container.js'));
const SuspendedAccount = () => (
  <React.Suspense fallback={<StyledStretchDiv />}>
    <LazyAccount />
  </React.Suspense>
);
export default SuspendedAccount;
