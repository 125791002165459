import React from 'react';

import PropTypes from '+/instances/propTypesInstance';

import { Header } from '+/components/Header';
import { Footer } from '+/components/Footer';
import { StyledBody, StyledMain } from '+/styledComponents/elements/containers';
import StyledSection from '+/styledComponents/StyledSection';
import Theme from '+/instances/Theme';

const BaseLayout = ({ forcePlainHeader, children }) => (
  <Theme light>
    <StyledBody>
      <Header key={window.location.pathname} forcePlain={forcePlainHeader} />
      <StyledMain>
        <StyledSection data-idf="icon-section" fullHeight>{children}</StyledSection>
      </StyledMain>
      <Footer />
    </StyledBody>
  </Theme>
);

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  forcePlainHeader: PropTypes.bool,
};

BaseLayout.defaultProps = {
  forcePlainHeader: null,
};

export const withBaseLayout = Child => () => (
  <BaseLayout>
    <Child />
  </BaseLayout>
);

export default BaseLayout;
