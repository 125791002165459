//
// Used in the redux and components to organize announcement related data
// and provide helper methods.
//

import Announcement from './Announcement';
import FrontEndStore from './FrontEndStore';

export default class AnnouncementsStore extends FrontEndStore {
  constructor(announcementData) {
    super();
    this.status = announcementData ? 'loaded' : 'unloaded';
    this.store = announcementData ? announcementData.map(a => new Announcement(a)) : [];
    this.storeType = AnnouncementsStore;
  }

  find(id) {
    return this.store.find(
      nextAnnouncement => nextAnnouncement.id === Number(id),
    );
  }

  updateAnnouncement(announcementData) {
    const announcement = this.find(announcementData.id);
    announcement.update(announcementData);
  }
}
