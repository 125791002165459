import { buildGET, buildPOST, buildPATCH, buildDELETE } from './apiCallsHelper';

/*
 * This file defines fetches for the site's data using the helper methods from apiCallsHelper.
 * These methods hit the back end and parse any json data returned.
 */

// USER

// ⌄⌄⌄⌄⌄⌄⌄⌄ Action Type Templates
export const getAllActionTypeTemplates = buildGET('/api/v1/action_type_templates');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Authentication
export const signInUser = buildPOST('/api/v1/authenticate');
export const signInUserPassword = buildPOST('/api/v1/authenticate/password');
export const signUpUserPassword = buildPOST('/api/v1/users/create/password');
export const requestPasswordReset = buildPOST('/api/v1/passwords/reset/request');
export const resetPassword = buildPOST('/api/v1/passwords/reset/update');
export const signInUserFacebook = buildPOST('/api/v1/authenticate/facebook');
export const signUpUserFacebook = buildPOST('/api/v1/users/create/facebook');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Basic User Data
export const getCollectedUserData = buildGET('/api/v1/current_user/collected_data');
export const getEligibleActions = buildGET('/api/v1/current_user/eligible_actions');
export const getUserTakenActionStats = buildGET('/api/v1/current_user/taken_actions/stats');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Dismissals
export const postDismissal = buildPOST('/api/v1/dismissals');
export const deleteDismissal = buildDELETE('/api/v1/dismissals');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Messages
export const postMessage = buildPOST('/api/v1/messages');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Push Subscriptions
export const createPushSubscription = buildPOST('/api/v1/subscribe');
export const testPushSubscription = buildPOST('/api/v1/subscribe/test');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Regions
export const getAllRegions = buildGET('/api/v1/regions');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Sub-regions
export const getAllSubRegions = buildGET('/api/v1/sub_regions');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Taken Actions
export const getUserTakenActions = buildGET('/api/v1/current_user/taken_actions');
export const postTakenAction = buildPOST('/api/v1/taken_actions');
export const deleteTakenAction = buildDELETE('/api/v1/taken_actions');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Updates For Approvals
export const getAllUpdatesForApproval = buildGET('/api/v1/updates_for_approval');
export const getUpdateForApproval = buildGET('/api/v1/updates_for_approval/:id');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ User
export const patchUser = buildPATCH('/api/v1/users/:id');
// ^^^^^^^^

// ADMIN

// ⌄⌄⌄⌄⌄⌄⌄⌄ Action Type Templates
export const patchActionTypeTemplate = buildPATCH('/api/v1/action_type_templates/:id');
export const postActionTypeTemplate = buildPOST('/api/v1/action_type_templates');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Actions
export const getEmailActions = buildGET('/api/v1/email_actions');
export const getCustomActions = buildGET('/api/v1/custom_actions');
export const getFacebookActions = buildGET('/api/v1/facebook_actions');
export const getLinkedinActions = buildGET('/api/v1/linkedin_actions');
export const getOtherActions = buildGET('/api/v1/other_actions');
export const getPetitionActions = buildGET('/api/v1/petition_actions');
export const getPhoneActions = buildGET('/api/v1/phone_actions');
export const getTwitterActions = buildGET('/api/v1/twitter_actions');
export const getAction = buildGET('/api/v1/:actionType_actions/:actionId');
export const postAction = buildPOST('/api/v1/:actionType_actions');
export const patchAction = buildPATCH('/api/v1/:actionType_actions/:id');
export const patchActionStatus = buildPATCH('/api/v1/:actionType_actions/:id/status');
export const patchCompleteAction = buildPATCH('/api/v1/:actionType_actions/:id/complete');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Announcements
export const getAllAnnouncements = buildGET('/api/v1/announcements');
export const postAnnouncement = buildPOST('/api/v1/announcements');
export const patchAnnouncement = buildPATCH('/api/v1/announcements/:id');
export const patchAnnouncementStatus = buildPATCH('/api/v1/announcements/:id/status');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Campaigns
export const getAllCampaigns = buildGET('/api/v1/campaigns');
export const postCampaign = buildPOST('/api/v1/campaigns');
export const patchCampaign = buildPATCH('/api/v1/campaigns/:id');
export const patchCampaignStatus = buildPATCH('/api/v1/campaigns/:id/status');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Every Action Errors
export const getAllEveryActionErrors = buildGET('/api/v1/every_action_errors');
export const getEveryActionError = buildGET('/api/v1/every_action_errors/:id');
export const postEveryActionError = buildPOST('/api/v1/every_action_errors');
export const patchEveryActionErrorSeenAt = buildPATCH('/api/v1/every_action_errors/:id/seenAt');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Messages
export const getAllMessages = buildGET('/api/v1/messages');
export const patchMessageSeenAt = buildPATCH('/api/v1/messages/:id/seenAt');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Regions
export const postRegion = buildPOST('/api/v1/regions');
export const patchRegion = buildPATCH('/api/v1/regions/:id');
export const deleteRegion = buildDELETE('/api/v1/regions/:id');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Sub-regions
export const postSubRegion = buildPOST('/api/v1/sub_regions');
export const patchSubRegion = buildPATCH('/api/v1/sub_regions/:id');
export const deleteSubRegion = buildDELETE('/api/v1/sub_regions/:id');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Rooms
export const getAllRooms = buildGET('/api/v1/rooms');
export const getRoom = buildGET('/api/v1/rooms/:id');
export const postRoom = buildPOST('/api/v1/rooms');
export const patchRoom = buildPATCH('/api/v1/rooms/:id');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Site Config
export const getSiteConfig = buildGET('/api/v1/site_config');
export const postSiteConfig = buildPOST('/api/v1/site_config');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Tags
export const getAllTags = buildGET('/api/v1/tags');
export const postNewTag = buildPOST('/api/v1/tags');
export const deleteTag = buildDELETE('/api/v1/tags/:id');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Taken Actions
export const getAllTakenActions = buildGET('/api/v1/taken_actions');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Updates For Approvals
export const approveUpdateForApproval = buildPOST('/api/v1/updates_for_approval/:id/approve');
export const disapproveUpdateForApproval = buildPOST(
  '/api/v1/updates_for_approval/:id/disapprove',
);
export const postUpdateForApproval = buildPOST('/api/v1/updates_for_approval');
// ^^^^^^^^

// ⌄⌄⌄⌄⌄⌄⌄⌄ Users
export const getAllUsers = buildGET('/api/v1/users');
export const patchUserPermissions = buildPATCH('/api/v1/users/:id/permissions');
export const patchUserGroup = buildPATCH('/api/v1/users/:id/group');
export const deleteUser = buildDELETE('/api/v1/users/:id');
// ^^^^^^^^
