// ====================================================================
// Theme Settings (CSS)
// ====================================================================
//
// This file contains assignments of the generic roles and specific values to objects
//
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styledComponents.
//
// It extends _themeBaseCustom and is extended by _themeCustom
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  // Measures

  headerHeight: '74px',
  headerLogoWidth: '230px',
  headerLogoHeight: '30px',
  headerItemPadding: '36px 32px',
  burgerWidth: '54px',
  burgerWidthLarge: '74px',
  slideOutMenuWidth: '314px',
  slideOutMenuHeaderHeight: '74px',
  footerHeight: '3.75rem',
  tabHeight: '3.5625rem',

  announcementBarZIndex: '19',
  headerZIndex: '20',
  slideOutMenuZIndex: '21',
  tooltipZIndex: '23',
  modalZIndex: '25',
  toastZIndex: '27',

  // Typography
  // --------------------------------------------------------------------

  // Fonts
  fontDefault: variables.fontReadable,
  fontButtons: variables.fontFormal,
  fontHeadings: variables.fontFormal,
  fontLabels: variables.fontReadable,

  // Font Sizes
  rootFontSize: '100%',
  pFontSizeSmall: variables.fontSizeParagraphSmall,
  pFontSize: variables.fontSizeParagraphRegular,
  pFontSizeLarge: variables.fontSizeParagraphLarge,
  smallFontSize: variables.fontSize,
  labelFontSize: variables.fontSizeParagraphSmall,
  h1FontSize: variables.fontSizeHeadingRegular,
  h2FontSize: variables.fontSizeHeadingHuge,
  h3FontSize: variables.fontSizeParagraphLarge,
  h4FontSize: variables.fontSizeHeadingRegular,
  h5FontSize: variables.fontSizeHeadingSmall,
  h6FontSize: variables.fontSizeHeadingTiny,
  actionCardFlagLabelFontSize: variables.sizeLargeMinus,

  // Font styles
  actionCardFlagLabelFont: 'Noto Serif, serif',
  actionCardFlagLabelFontStyle: 'italic',

  // Colors
  // --------------------------------------------------------------------

  // Action colors
  colorActionIcon: variables.colorWhite,
  colorFacebookAction: variables.colorFacebook,
  colorFacebookActionText: variables.colorWhite,
  colorLinkedinAction: variables.colorLinkedin,
  colorLinkedinActionText: variables.colorWhite,
  colorTwitterAction: variables.colorTwitter,
  colorTwitterActionText: variables.colorDarkGrey,
  colorEmailAction: variables.colorBlue,
  colorEmailActionText: variables.colorDarkGrey,
  colorPetitionAction: variables.colorLime,
  colorPetitionActionText: variables.colorDarkGrey,
  colorPhoneAction: variables.colorGreen,
  colorPhoneActionText: variables.colorDarkGrey,
  colorOtherAction: variables.colorPrimary,
  colorOtherActionText: variables.colorDarkGrey,
  colorDisabledGrey: 'grey', // get rid of
  colorDisabledLightGrey: 'lightgrey', // get rid of

  colorFacebookActionLight: variables.colorFacebook,
  colorLinkedinActionLight: variables.colorLinkedin,
  colorTwitterActionLight: variables.colorTwitter,
  colorEmailActionLight: '#FF6224',
  colorPhoneActionLight: '#BF112E',
  colorPetitionActionLight: variables.colorPetitionAction,
  colorOtherActionLight: '#4B0082',

  backgroundPointValueDefault: variables.colorLime,
  colorPointValueDefault: variables.colorDarkGrey,
  backgroundPointValueNoPoints: variables.colorLightestGrey,
  colorPointValueNoPoints: variables.colorDarkGrey,

  // Section colors
  bodyBackground: variables.colorOffwhite,
  bodyBackgroundColored: variables.colorOffwhite,
  bodyForeground: variables.colorWhite,
  headerBackground: variables.colorDarkGrey,
  slideOutMenuBackground: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05)),
    linear-gradient(to bottom, transparent, transparent)`,
  slideOutMenuDividerColor: variables.colorLightGrey,
  slideOutMenuHeaderBackground: variables.colorDarkGrey,
  announcementBarBackground: variables.colorYellow,

  // Links
  // --------------------------------------------------------------------
  linkInTextColor: variables.textColorDefault,
  linkInTextColorHover: variables.linkInTextColor,
  linkInTextLineColor: variables.colorPrimary,
  linkInTextLineColorHover: variables.colorPrimary,
  linkInTextLineDistance: '0px',

  linkStandaloneColor: variables.textColorDefault,
  linkStandaloneFontWeight: 'bold',
  linkStandaloneLineWidth: '4px',
  linkStandaloneLineDistance: '8px',

  headerItemColor: '#046a38',

  slideOutMenuLinkColor: variables.textColorDefault,
  slideOutMenuLinkColorActive: variables.colorDarkYellow,
  slideOutMenuLinkBackgroundHover: variables.colorLightestGrey,

  // Buttons
  // --------------------------------------------------------------------

  // Default
  buttonHeight: '60px',
  buttonHeightMicro: '30px',
  buttonHeightSmall: '50px',
  buttonHeightLarge: '80px',
  buttonWidth: '220px',
  buttonWidthSmall: '160px',
  buttonWidthLarge: '220px',

  buttonBackground: 'transparent',
  buttonBorder: '2px solid',
  buttonBorderColor: variables.colorPrimaryInvert,
  buttonBorderRadius: '4px',
  buttonColor: variables.colorPrimaryInvert,
  buttonFontFamily: variables.fontFormal,
  buttonFontSize: '0.9375rem',
  buttonSmallFontSize: '.8rem',
  buttonFontWeight: '700',
  buttonFontCase: 'uppercase',
  buttonLetterSpacing: '1px',
  buttonPadding: '0 1.125rem',
  buttonNotch: true,

  // hover
  buttonBackgroundHover: variables.colorPrimaryInvert,
  buttonBorderColorHover: variables.colorPrimaryInvert,
  buttonColorHover: variables.buttonColor,

  // Primary
  buttonBackgroundPrimary: variables.colorPrimary,
  buttonBorderPrimary: '0 solid',
  buttonBorderColorPrimary: variables.colorDarkPrimary,
  buttonColorPrimary: variables.textColorPrimary,
  buttonShadow: 'none',
  // hover
  buttonBackgroundHoverPrimary: variables.colorDarkPrimary,
  buttonBorderColorHoverPrimary: variables.colorDarkPrimary,
  buttonColorHoverPrimary: variables.textColorPrimary,
  buttonShadowHover: 'none',

  // Muted
  buttonBackgroundMuted: variables.colorDisabled,
  buttonBorderMuted: '0 solid',
  buttonBorderColorMuted: variables.colorDarkDisabled,
  buttonColorMuted: variables.textColorPrimary,
  // hover
  buttonBackgroundHoverMuted: variables.colorDarkDisabled,
  buttonBorderColorHoverMuted: variables.colorDarkDisabled,
  buttonColorHoverMuted: variables.textColorPrimary,

  // Danger
  buttonBackgroundDanger: variables.colorDanger,
  buttonBorderDanger: '0 solid',
  buttonBorderColorDanger: variables.colorDarkDanger,
  buttonColorDanger: variables.textColorDanger,
  // hover
  buttonBackgroundHoverDanger: variables.colorDarkDanger,
  buttonBorderColorHoverDanger: variables.colorDarkDanger,
  buttonColorHoverDanger: variables.textColorDanger,

  // Warning
  buttonBackgroundWarning: variables.colorWarning,
  buttonBorderWarning: '0 solid',
  buttonBorderColorWarning: variables.colorDarkWarning,
  buttonColorWarning: variables.textColorWarning,
  // hover
  buttonBackgroundHoverWarning: variables.colorDarkWarning,
  buttonBorderColorHoverWarning: variables.colorDarkWarning,
  buttonColorHoverWarning: variables.textColorWarning,

  // Secondary
  buttonBackgroundSecondary: variables.colorSecondary,
  buttonBorderSecondary: '0 solid',
  buttonBorderColorSecondary: variables.colorDarkSecondary,
  buttonColorSecondary: variables.textColorSecondary,
  // hover
  buttonBackgroundHoverSecondary: variables.colorDarkSecondary,
  buttonBorderColorHoverSecondary: variables.colorDarkSecondary,
  buttonColorHoverSecondary: variables.textColorSecondary,

  // Other

  tooltipColor: variables.colorDarkGrey,
  tooltipBorderColor: variables.colorDarkGrey,
  actionFlagHeight: '3.125rem',

  // Inputs
  //---------------------------------------------------------------------

  inputColor: variables.colorDarkGrey,
  inputBackgroundColor: variables.colorWhite,
  inputBorderColor: 'rgba(29, 37, 44, 0.1)',
  inputBorderColorFocus: variables.colorPrimary,
  inputBorderColorError: variables.colorDanger,
  inputPlaceholderColor: 'rgba(0, 0, 0, 0.6)',
  inputFontSize: variables.fontSizeParagraphRegular,
  inputRadius: '0',
  inputHeight: '48px',
  inputPadding: '1em 1.125em',

  labelColorError: variables.colorDanger,

  // Toasts
  colorToastNeutral: '#fff3cd',
  colorToastSuccess: '#d4edda',
  colorToastDanger: '#f7d6da',

  // Cards
  cardBorderRadiusCondensed: '0',
  cardBorderRadiusUncondensed: '5px',
  cardBackgroundImage: `linear-gradient(315deg, transparent 1.75rem, ${variables.colorWhite} 1.75rem)`,
  cardBackgroundColor: 'transparent',
});
