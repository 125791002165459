/**
 * Exports custom properties for appConfig object.
 * @module appConfigCustom
 */

module.exports = {
  headerLogo: '/open-wing-alliance.svg',
  headerIcon: '/open-wing-alliance-logomark.svg',
  faviconSvg: 'favicon-owa.svg',
  faviconIco: 'favicon-owa.ico',
  appleTouchIcon: 'apple-touch-icon-owa.png',
  themeColor: '#000000',
  siteTitle: 'OWA Action App',
  siteName: 'OWA Action App',
  siteDescription: 'Join activists around the world in taking meaningful and relevant online actions for animals. In a matter of minutes, you can drive campaigns forward and create a better world for chickens.',
  shareImage: 'https://owa-action-app.s3.amazonaws.com/share_image.png',
  shareImageSecure: 'https://owa-action-app.s3.amazonaws.com/share_image.png',
  googleClientId: '247324730601-3221fqdcre1dh8g93kc23h61gb5vqqrs.apps.googleusercontent.com',
  organizationTitle: 'Open Wing Alliance',
  googleFonts: ['Source Sans Pro:300,400,600,700', 'Oswald:700', 'Muli:700'],
  googleTagManagerId: 'GTM-K44FDQJ',
  emailRestrictionPattern: {
    // emailPattern: /@thehumaneleague\.org(\.uk)?$/,
    emailPattern: '',
    emailPatternLabel: '@thehumaneleague.org',
  },
  noMoreActionsLink: {
    path: 'https://www.twitter.com/globalcagefree',
    text: 'our twitter feed',
  },
  emailSender: {
    address: 'actions@openwingalliance.org',
    name: 'Ellie Donohue-Miller',
    genericName: 'Action App',
  },
  userGroups: ['user', 'admin'],
  defaultUserGroups: 'user',
  feedbackFormUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSddQn2XypXwWdWJ17XbXfDpyDatxmj18mceNNMMvvprQNqmGg/viewform',
  publicVapid: 'BPOdrsPe4o8WuPGVjz_l6PwaDMtMdarTvuEz2QLJD0cpSuwkwDw0lVk8rQ1XHsG5K1Ss8IlrxEE7BT_TZxDbxeY',
  signInBackgroundImageMobile: '/assets/brown-chickens-mobile.png',
  signInBackgroundImageMobileWebP: '/assets/brown-chickens-mobile.webp',
  signInBackgroundImageDesktop: '/assets/brown-chickens.jpg',
  signInBackgroundImageDesktopWebP: '/assets/brown-chickens.webp',
  signUpBackgroundImageMobile: '/assets/brown-chickens-mobile.png',
  signUpBackgroundImageMobileWebP: '/assets/brown-chickens-mobile.webp',
  signUpBackgroundImageDesktop: '/assets/brown-chickens.jpg',
  signUpBackgroundImageDesktopWebP: '/assets/brown-chickens.webp',
  signUpShowZipCodeField: false,
  passwordBackgroundImageMobile: '/assets/brown-chickens-mobile.png',
  passwordBackgroundImageMobileWebP: '/assets/brown-chickens-mobile.webp',
  passwordBackgroundImageDesktop: '/assets/brown-chickens.jpg',
  passwordBackgroundImageDesktopWebP: '/assets/brown-chickens.webp',
  loadingIndicator: '/owa-loading.svg',
  animationIcon: '/gold-star.svg',
  enableCustomContentParsers: true,
  requireSubRegionId: true,
};
