import { useSelector } from 'react-redux';

import useFetch from './useFetch';
import * as actions from '+/actions';
import * as apiCalls from '+/utils/apiCalls';

const fetchUserData = async(dispatch) => {
  const userData = await apiCalls.signInUser();
  if (userData.errors) return;
  dispatch(actions.signInUser(userData));
};

const useCurrentUser = (refetch = false) => {
  const user = useSelector(store => store.user.currentUser);

  const shouldFetch = refetch || !user || (user.isEmpty && user.isEmpty());
  useFetch(fetchUserData, shouldFetch);

  return user;
};

export default useCurrentUser;
