import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@keyframes enterBottom {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -5px, 0);
  }

  75% {
    transform: translate3d(0, 3px, 0);
  }

  90% {
    transform: translate3d(0, -1px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes enterBottomDelayed {
  from {
    opacity: 0;
    transform: translate(0, 3000px);
  }

  40% {
    opacity: 0;
    transform: translate(0, 3000px);
  }

  70% {
    opacity: 1;
    transform: translate(0x, -5px);
  }

  85% {
    transform: translate(0px, 3px);
  }

  95% {
    transform: translate(0, -1px);
  }

  to {
    transform: translate(0, 0);
  }
}


@keyframes exitLeft {
  0% {
    margin-bottom: 2rem;
    max-height: 500px;
    transform: translate3d(-20px, 0, 0);
  }

  20% {
    opacity: 1;
  }

  60% {
    max-height: 0;
    margin-bottom: 0;
  }

  to {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.exit-left {
  animation-duration: 1s;
  animation-name: exitLeft;
}

@keyframes shiftInBottom {
  from {
    opacity: .4;
    transform: translate(0, 12px);
  }

  80% {
    opacity: 1;
    transform: translate(0, -6px);
  }
  92% {
    opacity: 1;
    transform: translate(0, 3px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.enter-bottom:nth-of-type(1) {
  animation-duration: 0.95s;
  animation-name: enterBottom;
}

.enter-bottom:nth-of-type(2) {
  animation-duration: 1.15s;
  animation-name: enterBottom;
}

.enter-bottom:nth-of-type(3) {
  animation-duration: 1.35s;
  animation-name: enterBottom;
}

.enter-bottom:nth-of-type(4) {
  animation-duration: 1.55s;
  animation-name: enterBottom;
}

.enter-bottom:nth-of-type(5) {
  animation-duration: 1.75s;
  animation-name: enterBottom;
}

.enter-bottom:nth-of-type(n + 5) {
  animation-duration: 3s;
  animation-name: enterBottom;
}

.enter-bottom-delayed {
  animation-duration: 3s;
  animation-name: enterBottomDelayed;
}

@keyframes shiftOutTop {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }

  to {
    opacity: .4;
    transform: translate(0, -12px);
  }
}

@keyframes pulse {
  20% {
    padding: 0;
    border: 3px solid rgba(black, .2);
  }

  70% {
    padding: 30%;
    border: 5px solid rgba(black, .6);
  }

  to {
    padding: 20%;
    border: 8px solid rgba(black, .1);
  }
}

.shift-in-bottom-and-pulse {
  position: relative;

  animation-duration: .2s;
  animation-timing-function: linear;
  animation-name: shiftInBottom;
  &::before {
    position: absolute;
    content: " ";

    background: rgba(white, .1);
    border-radius: 100%;

    animation-duration: .4s;
    animation-delay: .25s;
    animation-timing-function: linear;
    animation-name: pulse;
  }
}

.shift-out-top {
  animation-duration: .2s;
  animation-timing-function: linear;
  animation-name: shiftOutTop;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  to {
    opacity: 1;
  }
}

.pop-in {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
}

.pop-out {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-name: zoomOut;
}

@keyframes slideOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -0.5rem, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: .2s;
  animation-timing-function: ease-out;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: .2s;
  animation-timing-function: ease-out;
}
`;
