// A utility for managing fetch requests.
// Takes a function, a boolean, and arguments for the function.
// Will only run one function at a time, and waits for a second before running the same again.
// Using shouldFetch here allows us to selectively refetch data, since we can't conditionally
// include hooks in React.

import { useDispatch } from 'react-redux';

const isOnGoing = {};

const toggleOnGoing = (name, value) => {
  isOnGoing[name] = value;
};

let keyInt = 0;
const newKey = () => {
  keyInt++;
  return keyInt;
};

export default (func, shouldFetch, ...args) => {
  // eslint-disable-next-line
  func.key = func.key || newKey();
  const stringified = JSON.stringify([func.key, ...args]);
  const dispatch = useDispatch();
  if (!isOnGoing[stringified] && shouldFetch) {
    toggleOnGoing(stringified, true);
    return func(dispatch, ...args)
      .then(() => setTimeout(() => toggleOnGoing(stringified, false), 1000))
      .catch(() => toggleOnGoing(stringified, false));
  }
  return Promise.resolve();
};
