import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import themeVars, { ifProp } from '+/utils/themeVars';

// This is a styled version of React Router's
// Link component.

const GenericLinkStyles = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;

  cursor: pointer;
`;

const GenericStyledLink = styled(Link)`
  ${GenericLinkStyles}
`;

const GenericStyledAnchor = styled.a`
  ${GenericLinkStyles}
`;

const InTextLinkStyles = css`
  box-shadow: 0 2px 0 0 ${themeVars('linkInTextLineColor')};

  color: ${themeVars('linkInTextColor')};

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    color: ${themeVars('textColorPrimary')};
  }

  &:active,
  &:hover {
    background-color: ${themeVars('linkInTextLineColor')};
  }
`;

export const StyledInTextLink = styled(GenericStyledLink)`
  ${InTextLinkStyles}
`;

export const StyledInTextAnchor = styled(GenericStyledAnchor)`
  ${InTextLinkStyles}
`;

export const StyledInActionLink = styled(GenericStyledLink)`
  color: ${themeVars('colorYellow')};
  text-decoration: ${ifProp('noDecoration', 'none', 'underline')};

  &:hover {
    color: ${themeVars('textColorPrimary')};
  }
`;
// See also typography paragraghs for additional styling.

export const StyledStandaloneLink = styled(GenericStyledLink)`
  --box-shadow-color: ${themeVars('colorDarkPrimary')};
  display: block;
  margin-bottom: ${themeVars('linkStandaloneLineDistance')};
  padding-bottom: ${themeVars('linkStandaloneLineDistance')};

  box-shadow: 0 ${themeVars('linkStandaloneLineWidth')} 0 0 var(--box-shadow-color);

  color: ${themeVars('textColorDefault')};
  font-weight: ${themeVars('linkStandaloneFontWeight')};

  text-transform: uppercase;

  &:visited {
    color: ${themeVars('linkStandaloneColor')};
  }

  ${ifProp({
    disabled: css`
      --box-shadow-color: ${themeVars('colorDarkDisabled')};
      color: ${themeVars('colorDarkDisabled')};
      cursor: not-allowed;

      &:visited {
        color: ${themeVars('colorDarkDisabled')};
      }
      &:hover {
        --box-shadow-color: ${themeVars('colorDarkDisabled')};
      }
    `,
    accent: css`
      --box-shadow-color: ${themeVars('colorAccent')};

      &:hover {
        --box-shadow-color: ${themeVars('colorDarkAccesnt')};
      }
    `,
    default: css`
      --box-shadow-color: ${themeVars('colorPrimary')};

      &:hover {
        --box-shadow-color: ${themeVars('colorDarkPrimary')};
      }
    `,
  })};
  ${ifProp({
    formal: css`
      font-family: ${themeVars('fontFormal')};
      font-size: 0.9375rem;
      letter-spacing: 0.06667em;
    `,
  })};
`;

export const StyledBoldLink = styled(GenericStyledLink)`
  color: ${themeVars('textColorDefault')};
  font-weight: bold;
`;

// This is just a plain 'ole anchor tag
export const StyledAnchor = styled.a`
  width: ${ifProp({ fullWidth: '100%' })};

  color: ${themeVars('linkInTextColor')};
  text-align: ${ifProp({ center: 'center' })};
  text-decoration: ${themeVars('linkInTextTextDecoration')};
  word-break: break-all;

  cursor: pointer;

  &:active,
  &:hover {
    color: ${themeVars('linkInTextColorHover')};
    text-decoration: ${themeVars('linkInTextTextDecorationHover')};
  }
`;
