import { formatDate } from './modelHelpers';

/**
 * Exports Front End model class for inheritance.
 * @module FrontEndModel
 */

export default class FrontEndModel {
  constructor(data) {
    Object.assign(this, data);
  }

  dup() {
    const Constructor = this.modelType;
    return new Constructor(this);
  }

  /**
   * @public
   * @return {boolean} returns a boolean representing whether the model data is present.
   */
  isLoading() {
    return this.isEmpty();
  }

  /**
   * @public
   * @return {string} A formatted string representation of the createdAt date.
   */
  createdAtString() {
    return new Date(Date.parse(this.createdAt)).toDateString();
  }

  /**
   * @public
   * @return {string} A formatted string representation of the updatedAt date.
   */
  updatedAtString() {
    return new Date(Date.parse(this.updatedAt) + new Date().getTimezoneOffset()).toDateString();
  }

  /**
   * @public
   * @return {string} A formatted string representation of the updatedAt date.
   */
  updatedAtStringLong() {
    return formatDate(new Date(Date.parse(this.updatedAt)));
  }
}
