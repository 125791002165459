/**
 * Exports Region Store class
 * @module RegionsStore
 */

import Region from './Region';
import FrontEndStore from './FrontEndStore';

export default class RegionsStore extends FrontEndStore {
  constructor(regionData) {
    super();
    this.status = regionData ? 'loaded' : 'unloaded';
    this.store = ((regionData && regionData.store) || regionData || []).map(
      c => new Region(c),
    );
    this.storeType = RegionsStore;
  }

  findBySubRegionId(id) {
    return this.store.filter(r => r.subRegions.some(sr => sr.id === id));
  }
}
