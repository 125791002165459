/**
 * Exports Text Reader class.
 * @module TextReader
 */

import text from '../text';

const dig = (object, path) => {
  const pathParts = path ? path.split('.') : [];
  return pathParts.reduce((accum, part) => accum && accum[part], object);
};

const isProd = process.env.NODE_ENV === 'production';

export default class TextReader {
  constructor(contextPath) {
    this.contextText = dig(text, contextPath);
    // bound version of read function for easy accessibility.
    this.t = this.read.bind(this);
  }

  /**
   * @public
   * @param {string} path - a path of keys to follow in extracting data.
   * If prefixed with '-', starts at the beginning of the text object.
   * Otherwise it starts at the defined context.
   * @param {object} replacements - an object of keys and values. If the return value is a string,
   * replaces the ':' prefixed key names in that string with the replacement value.
   * @return {object} returns the value nested under the series of keys specified.
   * @example
   * // returns the value nested under 'header', 'title' replacing :name with 'Kay'
   * reader.read('-header.title', { name: 'Kay' });
   */
  read(path, replacements) {
    const relevantContext = path.startsWith('-') ? text : this.contextText;
    const relevantPath = path.startsWith('-') ? path.slice(1) : path;
    const value = dig(relevantContext, relevantPath);
    if (typeof value === 'undefined' || value === null) {
      if (!isProd) throw new Error(`text not found: ${relevantPath}`);
      return null;
    }
    if (!replacements) return value;
    return Object.keys(replacements).reduce(
      (accum, key) => accum.replace(`%${key}`, replacements[key]),
      value,
    );
  }
}
