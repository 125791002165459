import appConfig from '../appConfig';

window.dataLayer = window.dataLayer || [];

const instance = {
  initializeGTM: () => {
    /* eslint-disable */
    var host = window.location.hostname;
    if (host != 'localhost') {
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', appConfig.googleTagManagerId);
    }
    /* eslint-enable */
  },
  setUser: (user) => {
    instance.push({
      event: 'User Change',
      userId: user ? user.identifier() : null,
      userType: user ? user.analyticsType() : null,
      userDuration: user ? user.monthsSinceSignup() : null,
    });
  },
  setEngagement: (user, actionCount) => {
    instance.push({
      event: 'User Update',
      userEngagement: user.engagementScore(actionCount),
    });
  },
  push: (...args) => {
    window.dataLayer.push(...args);
  },
};

export default instance;
