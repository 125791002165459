import React from 'react';

const LazyMarkdown = React.lazy(() => import('./Markdown.container.js'));
const SuspendedMarkdown = props => (
  <React.Suspense
    fallback={
      <div />
    }
  >
    <LazyMarkdown {...props} />
  </React.Suspense>
);

export default SuspendedMarkdown;
