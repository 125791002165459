import TextReader from '+/utils/TextReader';

const { t } = new TextReader('paths');

export const path = (string, params) => {
  const keyPath = `${string}.value`;
  return t(keyPath, params);
};

// converts /test/%test/3 to /test/[^/]*?/3
// NOTE, client uses %, server uses :
const toRegex = pathTemplate => pathTemplate.replace(/%[^/]*/g, '[^/]*?');

const getPathPermissionIn = (string, obj) => {
  if (!obj) return false;
  if (typeof obj === 'string' || obj instanceof String) return false;
  if (obj.value && !!string.match(toRegex(obj.value))) {
    return obj.permissions;
  } else if (obj.value) {
    return false;
  }
  const pathKey = Object.keys(obj).find(key => getPathPermissionIn(string, obj[key]));
  return getPathPermissionIn(string, obj[pathKey]) || false;
};

export const getPathPermission = (string) => {
  const paths = t('-paths');
  return getPathPermissionIn(string, paths);
};
