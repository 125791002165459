import React from 'react';

import { TabbedContainer } from '+/components/TabbedContainer';
import { StyledStretchDiv } from '+/styledComponents/elements/containers';

const LazySettings = React.lazy(() => import('./Settings.container.js'));
const SuspendedSettings = () => (
  <React.Suspense
    fallback={
      <StyledStretchDiv>
        <TabbedContainer tabs={[{}, {}]} />
      </StyledStretchDiv>
    }
  >
    <LazySettings />
  </React.Suspense>
);
export default SuspendedSettings;
