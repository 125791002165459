import { FaCaretRight } from 'react-icons/fa';
import React from 'react';
import styled from 'styled-components';

import themeVars, { fromProps, ifProp } from '+/utils/themeVars';
import Theme from '+/instances/Theme';
import StyledRow from '../StyledRow';

import PropTypes from '+/instances/propTypesInstance';

export const StyledHr = styled.hr`
  margin: 0;
  width: 100%;

  border: none;
  border-top: 2px solid ${themeVars('colorBackgroundShade')};
`;

const StyledShapeContainer = styled.div`
  margin-top: 5em;
  overflow: visible;
  position: relative;
  width: 100%;
`;

const StyledShapeTop = styled.div`
  bottom: 100%;
  height: 100px;
  position: absolute;
  width: 100%;

  svg {
    fill: ${themeVars('colorBackground')};
  }
`;

const StyledShapeBody = styled.div`
  min-height: 10em;
  margin-top: -10em;
  padding: 5em 0 10em;
  width: 100%;

  background: ${themeVars('colorBackground')};
`;

export const StyledShape = ({ children }) => (
  <Theme vibrant>
    <StyledShapeContainer>
      <StyledShapeTop>
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon points="0,40 0,100 100,100 100,80 30,0" />
        </svg>
      </StyledShapeTop>
      <StyledShapeBody>{children}</StyledShapeBody>
    </StyledShapeContainer>
  </Theme>
);

StyledShape.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TagBadge = styled.span`
  padding: 0 1em;
  font-size: 0.8125em;
  line-height: 2em;
  display: inline-block;

  background: ${fromProps('tag.color')};
  border-radius: 1em;

  color: ${themeVars('textColorDefault')};

  &:not(:first-child) {
    margin-left: 0.3em;
  }

  &:not(:last-child) {
    margin-right: 0.3em;
  }
`;

const Campaign = styled.span`
  margin-left: 0.3em;
  margin-right: 0.3em;
  padding: 0.05em 0.5em;
  transform: ${ifProp({ parent: 'scale(2)' })};

  background: #dde5dd;
  border: 2px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px rgba(100, 100, 30, 0.1);
`;

export const CampaignDisplay = ({ campaign, parentCampaign }) => (
  <StyledRow middle>
    {parentCampaign && <Campaign>{parentCampaign.name}</Campaign>}
    {parentCampaign && <FaCaretRight />}
    <Campaign>{campaign.name}</Campaign>
  </StyledRow>
);

CampaignDisplay.propTypes = {
  campaign: PropTypes.models.Campaign.isRequired,
  parentCampaign: PropTypes.models.Campaign,
};

CampaignDisplay.defaultProps = {
  parentCampaign: null,
};
