import styled from 'styled-components';
import React from 'react';
import media, { sizes } from '+/utils/media';
import { fromProps } from '+/utils/themeVars';
import PropTypes from '+/instances/propTypesInstance';

export const Background = styled.div`
  /* bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  background-color: ${fromProps('backgroundColor')};
  background-image: url(${fromProps('imageMobile')});
  background-position: top;
  background-size: cover;

  ${media.sm`
    background-image: url(${fromProps('imageDesktop')});
  `} */
`;

export const BackgroundPicture = styled.picture`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  img {
    display: block;
    height: 100%;
    width: 100%;

    object-fit: cover;
    object-position: 50% 0;
  }
`;

const StyledBackgroundContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  height: 100%;
  position: relative;
`;

const Shade = styled.div`
  bottom: 0;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  background: rgba(0,0,0,${fromProps('darkenMobile')});

  ${media.sm`
    background: rgba(0,0,0,${fromProps('darken')});
  `}
`;

export const StyledBackground = ({
  children,
  darken,
  darkenMobile,
  imageDesktop,
  webPDesktop,
  imageMobile,
  webPMobile,
  alt,
}) => (
  <StyledBackgroundContainer>
    <BackgroundPicture>
      {webPDesktop && <source srcSet={webPDesktop} media={`(min-width: ${sizes.sm}px)`} type="image/webp" />}
      <source srcSet={imageDesktop} media={`(min-width: ${sizes.sm}px)`} />
      {webPMobile && <source srcSet={webPMobile} type="image/webp" />}
      <img src={imageMobile} alt={alt} />
    </BackgroundPicture>
    {(darken && darkenMobile) && <Shade darken={darken} darkenMobile={darkenMobile} />}
    <Content>{children}</Content>
  </StyledBackgroundContainer>
);

StyledBackground.propTypes = {
  children: PropTypes.node,
  darken: PropTypes.number,
  darkenMobile: PropTypes.number,
  imageDesktop: PropTypes.string.isRequired,
  imageMobile: PropTypes.string.isRequired,
  webPDesktop: PropTypes.string,
  webPMobile: PropTypes.string,
  alt: PropTypes.string,
};

StyledBackground.defaultProps = {
  children: null,
  darken: null,
  darkenMobile: null,
  webPDesktop: null,
  webPMobile: null,
  alt: '',
};
