import appConfig from '+/appConfig';

export const capitalize = s => s[0].toUpperCase() + s.slice(1);

export const formatDate = (date) => {
  // What does this do?
  // const utc = new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000;
  // const localCurrentTime = new Date();
  // const localDate = new Date(utc - localCurrentTime.getTimezoneOffset() * 60000);
  //
  const time = new Date(date)
    .toLocaleTimeString(appConfig.language, {
      hour: '2-digit',
      minute: '2-digit',
    })
    .toLowerCase();

  const formattedLocalDate = new Date(date).toLocaleDateString(appConfig.language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return `${formattedLocalDate} ${time}`;
};

export const formatTime = (date) => {
  const time = new Date(date)
    .toLocaleTimeString(appConfig.language, {
      hour: '2-digit',
      minute: '2-digit',
    })
    .toLowerCase();

  return `${time}`;
};

export const truncate = (string, length) => {
  if (string.length < length) return string;
  return `${string.substring(0, length)}...`;
};
