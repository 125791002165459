import RegionsStore from '+/models/RegionsStore';

const defaultStore = { all: new RegionsStore([]) };

const regionsStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
    case 'SET_ALL_REGIONS': {
      const regionsData = action.payload;
      const newRegions = new RegionsStore(regionsData);
      Object.assign(newStore, { all: newRegions });
      break;
    }
    case 'ADD_NEW_REGION': {
      const regionsData = [...store.all.store, action.payload];
      const newRegions = new RegionsStore(regionsData);
      Object.assign(newStore, { all: newRegions });
      break;
    }
    default:
  }
  return newStore;
};

export default regionsStore;
