import React, { useState } from 'react';

import PropTypes from '+/instances/propTypesInstance';

import StyledTabbedContainer from '+/styledComponents/StyledTabbedContainer';
import Theme from '+/instances/Theme';

export const TabbedContainer = ({ light, handleSelect, tabs }) => {
  const initialSelected = tabs.find(t => t.selected);
  const [selected, setSeletected] = useState(initialSelected && initialSelected.label);

  const ThemeIfLight = light ? ({ children }) => <Theme light>{children}</Theme> : React.Fragment;

  return (
    <StyledTabbedContainer.Box light={light}>
      <StyledTabbedContainer.TabRow light={light}>
        {tabs.map(tab => (
          <StyledTabbedContainer.Tab
            key={tab.label}
            selected={tab.label === selected}
            onClick={() => {
              if (handleSelect) {
                setSeletected(tab.label);
              }
              if (tab.func) {
                tab.func();
              }
            }}
          >
            {tab.label}
          </StyledTabbedContainer.Tab>
        ))}
      </StyledTabbedContainer.TabRow>
      <ThemeIfLight>
        <StyledTabbedContainer.Body>
          {tabs.find(tab => tab.label === selected).body}
        </StyledTabbedContainer.Body>
      </ThemeIfLight>
    </StyledTabbedContainer.Box>
  );
};

TabbedContainer.propTypes = {
  light: PropTypes.bool,
  handleSelect: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      func: PropTypes.func,
      body: PropTypes.node,
    }),
  ).isRequired,
};

TabbedContainer.defaultProps = {
  light: false,
  handleSelect: true,
};
