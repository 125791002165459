//
// Used in the redux and components to organize action related data and provide helper methods.
//

import Action from './Action';
import FrontEndStore from './FrontEndStore';

export const ACTION_TYPES = [
  'custom',
  'email',
  'linkedin',
  'facebook',
  'other',
  'petition',
  'phone',
  'twitter',
];
export const FIXED_ACTION_TYPES = ACTION_TYPES.filter(t => t !== 'custom');

class ActionsStore extends FrontEndStore {
  constructor(actionData) {
    super();
    this.storeType = ActionsStore;
    this.loading = !actionData; // If no data object supplied, assume unloaded.
    this.store = ((actionData && actionData.store) || actionData || [])
      // In case cached code doesn't include new types ↓
      .filter(datum => ACTION_TYPES.includes(datum.actionType))
      .map(datum => new Action(datum, datum.actionType));
  }

  // FILTERS / Arrays

  all() {
    return this.store;
  }

  excluding(otherActionStore) {
    const actionData = this.store.filter(
      action => !otherActionStore.find(action.actionType, action.id),
    );
    return new ActionsStore(actionData);
  }

  unsnoozed() {
    return new ActionsStore(this.all().filter(action => !action.isSnoozed()));
  }

  filter(options) {
    let actionArr = this.all();
    if (options.enabledState !== 'all') {
      actionArr = actionArr.filter(act => act[`is${capitalize(options.enabledState)}`]());
    }
    if (options.region !== 'all') {
      actionArr = actionArr.filter(act =>
        act.regions.find(region => `${region.id}` === `${options.region}`),
      );
    }
    if (options.actionType !== 'all') {
      actionArr = actionArr.filter(
        act =>
          act.actionType === options.actionType ||
          (act.actionTypeTemplate &&
            act.actionTypeTemplate.name.toLowerCase() === options.actionType.toLowerCase()),
      );
    }
    if (options.search !== '') {
      actionArr = actionArr.filter(act => act.contentMatches(options.search));
    }
    return new ActionsStore(
      actionArr.sort((a, b) => (a.frozenUpdatedAt > b.frozenUpdatedAt ? -1 : 1)),
    );
  }

  removeActionsByType(actionTypeSet) {
    this.store = this.store.filter(action => !actionTypeSet.has(action.actionType));
  }

  find(actionType, actionId) {
    return (
      this.store.find(
        nextAction => nextAction.id === Number(actionId) && nextAction.actionType === actionType,
      ) || false
    );
  }

  findAll(typesAndIds) {
    const actions = typesAndIds
      .map(([actionType, actionId]) => this.find(actionType, actionId))
      .filter(a => !!a);
    return new ActionsStore(actions);
  }

  presentActionTypes() {
    return ACTION_TYPES.filter(type => this.store.find(a => a.actionType === type));
  }

  orderedByRecency() {
    return this.all().sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
  }

  orderedByCreation() {
    return this.all().sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  }

  // Leaves order alone unless prioirity supercedes
  orderedByPriority() {
    return this.all().sort((a, b) => {
      if (a.priority === b.priority) return 0;
      return a.priority > b.priority ? -1 : 1;
    });
  }

  // Mutations

  addStore(otherActionsStore) {
    otherActionsStore.store.forEach(action => this.addAction(action));
  }

  addAction(action) {
    if (this.find(action.actionType, action.id)) return;
    this.store.push(action);
  }

  markCompleted(actionType, actionId) {
    const action = this.find(actionType, actionId);
    // Action may not exist in this store
    if (action) {
      action.completed = true;
    }
  }

  markUncompleted(actionType, actionId) {
    const action = this.find(actionType, actionId);
    action.completed = false;
  }

  remove(actionType, actionId) {
    this.store = this.store.filter(
      action => !(action.id === actionId && action.actionType === actionType),
    );
  }

  updateAction(actionData) {
    const action = this.find(actionData.actionType, actionData.id);
    if (!action) return;
    action.update(actionData);
  }

  // Data

  totalActionsCount() {
    return this.all().length;
  }

  isEmpty() {
    return this.totalActionsCount() === 0;
  }

  areAllCompleted() {
    return !this.isLoading() && this.all().every(action => action.completed);
  }

  isLoading() {
    return this.loading;
  }

  supplementCampaigns(campaignStore) {
    this.store.map(a => a.supplementCampaign(campaignStore));
  }
}

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

// Adds nextEmail, nextPhone .. functions.
for (const actionType of ACTION_TYPES) {
  // eslint-disable-next-line func-names
  ActionsStore.prototype[`next${capitalize(actionType)}Action`] = function() {
    return this.store.find(action => action.actionType === actionType);
  };
  // eslint-disable-next-line func-names
  ActionsStore.prototype[`${actionType}Actions`] = function() {
    return this.store.filter(action => action.actionType === actionType);
  };
}

export default ActionsStore;
