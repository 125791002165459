import { getTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';

import timeZones from '+/data/timeZones';

// Filter and format tzdb time zones for dropdown
export const TIME_ZONE_OPTIONS = getTimeZones()
  .filter(timeZone => !!timeZones.includes(timeZone.name))
  .map((timeZone) => {
    const [hoursOffset, timeZoneLabel] = timeZone.currentTimeFormat.trim().split(/\s+(.*)/s);
    return {
      label: `(UTC${hoursOffset}) ${timeZoneLabel}`,
      value: timeZone.name,
      group: timeZone.group,
      offset: timeZone.currentTimeOffsetInMinutes,
    };
  });

// Get tzdb group to which local time zone belongs
export const getLocalTimeZoneGroup = () =>
  TIME_ZONE_OPTIONS.find((timeZone) => {
    const currentZone = DateTime.local().zoneName;
    return timeZone.value === currentZone || timeZone.group.includes(currentZone);
  })?.value;

const validateDateTime = date => (date.isValid ? date : null);

// Compute timezone offset for a date-time (note: ignores any offset included in the dateString)
export const addTimeZoneOffset = (dateString, timeZone) => {
  let stringWithZone;
  if (dateString) {
    stringWithZone = validateDateTime(
      DateTime.fromISO(dateString.slice(0, 19), {
        zone: timeZone || undefined,
      }),
    )?.toString();
  }
  return stringWithZone || null;
};

// Convert a date-time to the specified time zone
export const convertToTimezone = (dateString, timeZone, shouldReturnDateTime) => {
  let convertedDateTime;
  if (dateString) {
    convertedDateTime = validateDateTime(
      DateTime.fromISO(dateString, {
        zone: timeZone || undefined,
      }),
    );
    if (!shouldReturnDateTime) convertedDateTime = convertedDateTime?.toString();
  }
  return convertedDateTime || null;
};

// Get ISO-formatted date-time string without converting date-time to GMT
export const getLocalDateString = date =>
  (date && date.constructor === Date ?
    validateDateTime(DateTime.fromJSDate(date))?.toISO({
      includeOffset: false,
    }) :
    date);

// Get mm/dd formatted date string (accounting for user-set timezone)
export const buildDayMonthString = (date, timeZone) => {
  const convertedDate = convertToTimezone(date?.toISOString(), timeZone, true);
  return (convertedDate && `${convertedDate.month}/${convertedDate.day}`) || '';
};

// Compute date range (accounting for user-set timezone)
export const getDateRangeString = (startTime, endTime, timeZone) => {
  if (!startTime && !endTime) return null;

  const startDateString = buildDayMonthString(startTime, timeZone);
  const endDateString = buildDayMonthString(endTime, timeZone);
  return (startDateString || endDateString) && `${startDateString} - ${endDateString}`;
};
