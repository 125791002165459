import React from 'react';
import PropTypes from 'prop-types';

import { ActionCard } from '+/components/ActionCard';
import useSessionTakenActionCount from '+/utils/hooks/useSessionTakenActionCount';

function scrollTo(action) {
  if (action) {
    const card = document.querySelector(`#action-card-${action.type}-${action.id}`);
    window.scrollTo({
      top: card.getBoundingClientRect().top + window.scrollY - 100,
      behavior: 'smooth',
    });
  }
}

export const ActionFeedItem = React.forwardRef((props, ref) => {
  const {
    action,
    nextAction,
    basePath,
    returnPath,
    currentUser,
    isSelected,
    removeEligibleAction,
    completeEligibleAction,
    uncompleteEligibleAction,
    sessionTakenActionCount,
  } = props;

  const usedSessionTakenActionCount = useSessionTakenActionCount();

  return (
    <ActionCard
      key={action.id + action.actionType}
      ref={ref}
      action={action}
      currentUser={currentUser}
      isSelected={isSelected}
      basePath={basePath}
      returnPath={returnPath}
      removeEligibleAction={removeEligibleAction}
      actionRemovalCallback={() => nextAction && setTimeout(() => scrollTo(nextAction), 100)}
      completeEligibleAction={completeEligibleAction}
      uncompleteEligibleAction={uncompleteEligibleAction}
      sessionTakenActionCount={sessionTakenActionCount || usedSessionTakenActionCount || 0}
    />
  );
});

ActionFeedItem.propTypes = {
  action: PropTypes.models.Action.isRequired,
  basePath: PropTypes.string,
  completeEligibleAction: PropTypes.func.isRequired,
  currentUser: PropTypes.models.User.isRequired,
  isSelected: PropTypes.bool.isRequired,
  nextAction: PropTypes.models.Action,
  removeEligibleAction: PropTypes.func.isRequired,
  returnPath: PropTypes.string,
  uncompleteEligibleAction: PropTypes.func.isRequired,
  sessionTakenActionCount: PropTypes.number,
};

ActionFeedItem.defaultProps = {
  nextAction: null,
  sessionTakenActionCount: null,
  returnPath: '',
  basePath: '',
};
