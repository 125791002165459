//
// Used in the redux and components to organize room related data and provide helper methods.
//

import Room from './Room';
import FrontEndStore from './FrontEndStore';

class RoomsStore extends FrontEndStore {
  constructor(data) {
    super();
    this.store = ((data && data.store) || data || []).map(
      d => new Room(d),
    );
    this.storeType = RoomsStore;
  }
}

export default RoomsStore;
