import AnnouncementsStore from '+/models/AnnouncementsStore';

const defaultStore = {
  all: new AnnouncementsStore(),
  eligibleAnnouncements: new AnnouncementsStore(),
};

const announcementsStore = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
  case 'SET_ALL_ANNOUNCEMENTS': {
    const announcementsData = action.payload;
    const newAnnouncementsStore = new AnnouncementsStore(announcementsData);
    Object.assign(newStore, { all: newAnnouncementsStore });
    break;
  }
  case 'UPDATE_ANNOUNCEMENT': {
    const announcementData = action.payload;
    const newAllAnnouncements = store.all.dup();
    newAllAnnouncements.updateAnnouncement(announcementData);
    Object.assign(newStore, { all: newAllAnnouncements });
    break;
  }
  case 'SET_ELIGIBLE_ANNOUNCEMENTS': {
    Object.assign(newStore, { eligibleAnnouncements: new AnnouncementsStore(action.payload) });
    break;
  }
  case 'REMOVE_ELIGIBLE_ANNOUNCEMENT': {
    const newEligibleAnnouncements = store.eligibleAnnouncements.dup();
    newEligibleAnnouncements.remove(action.payload);
    Object.assign(newStore, { eligibleAnnouncements: newEligibleAnnouncements });
    break;
  }
  case 'SIGN_OUT_USER': {
    Object.assign(newStore, {
      all: new AnnouncementsStore(),
      eligibleAnnouncements: new AnnouncementsStore(),
    });
    break;
  }
  default:
  }
  return newStore;
};

export default announcementsStore;
