import PushSubscriptionModel from '+/models/PushSubscription';
import PushSubscriptionsStore from '+/models/PushSubscriptionsStore';

const defaultStore = {
  user: new PushSubscriptionsStore([]),
};

const pushSubscriptions = (store = defaultStore, action) => {
  const newStore = { ...store };
  switch (action.type) {
  case 'SET_USER_PUSH_SUBSCRIPTIONS': {
    const pushSubscriptionsData = action.payload;
    const newPushSubscriptions = new PushSubscriptionsStore(pushSubscriptionsData);
    Object.assign(newStore, { user: newPushSubscriptions });
    break;
  }
  case 'ADD_USER_PUSH_SUBSCRIPTION': {
    const pushSubscriptionsData = action.payload;
    const newPushSubscription = new PushSubscriptionModel(pushSubscriptionsData);
    const newPushSubscriptionsStore = store.user.dup();
    newPushSubscriptionsStore.add(newPushSubscription);
    Object.assign(newStore, { user: newPushSubscriptionsStore });
    break;
  }
  case 'SIGN_OUT_USER': {
    const newPushSubscriptions = new PushSubscriptionsStore([]);
    Object.assign(newStore, { user: newPushSubscriptions });
    break;
  }
  default:
  }
  return newStore;
};

export default pushSubscriptions;
