// Takes SiteConfig data from server and stores it in object with helper methods for accessing it.

const defaults = {
  userGroups: [],
};

class SiteConfig {
  constructor(siteConfigData) {
    Object.assign(this, defaults, siteConfigData);
  }

  isEmpty() {
    // There should always be a emailSenderAddress
    return !this.emailSenderAddress;
  }
}

export default SiteConfig;
