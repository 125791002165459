import React from 'react';

const LazyRequestPasswordReset = React.lazy(() => import('./RequestPasswordReset.container.js'));
const SuspendedRequestPasswordReset = () => (
  <React.Suspense
    fallback={
      <div />
    }
  >
    <LazyRequestPasswordReset />
  </React.Suspense>
);
export default SuspendedRequestPasswordReset;
