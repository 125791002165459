/**
 * Exports Front End model class for inheritance.
 * @module FrontEndModel
 */

export default class FrontEndStore {
  /**
   * @public
   * @return {array} returns an array sorted by updatedAt.
   -*/
  sorted() {
    return this.store.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
  }

  /**
   * @public
   * @return {object} first in store.
   */
  latest() {
    return this.store[0];
  }

  /**
   * @public
   * @param {integer} id - the id of the model to return.
   * @return {object} returns an object matching the input id.
   */
  find(id) {
    return this.store.find(data => `${data.id}` === `${id}`);
  }

  /**
   * @public
   * @param {object} element - the element whose presence to check.
   * @return {boolean} returns a boolean matching the input object.
   */
  includes(element) {
    return !!this.find(element.id);
  }

  /**
   * @public
   * @param {object} id - the id of the model to remove.
   */
  remove(id) {
    this.store = this.store.filter(a => a.id !== id);
  }

  /**
   * @public
   * @param {object} model - the front end model to add.
   */
  add(model) {
    this.store = this.store.concat([model]).sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  /**
   * @public
   * @return {boolean} returns a boolean reflecting whether there are any elements in the store.
   */
  isEmpty() {
    return this.length === 0;
  }

  /**
   * @public
   * @return {array} returns an array of the stores models.
   */
  toArray() {
    return [...this.store];
  }

  /**
   * @public
   * @return {array} calls reduce on the store.
   */
  reduce(...args) {
    return this.store.reduce(...args);
  }

  /**
   * @public
   * @return {array} calls slice on the store.
   */
  slice(...args) {
    const Constructor = this.storeType;
    return new Constructor(this.store.slice(...args));
  }

  /**
   * @public
   * @return {array} returns the result of mapping the func to the store's models.
   */
  map(func) {
    return this.store.map(func);
  }

  get length() {
    return this.store.length;
  }

  // slice(...args) {
  //   return this.store.slice(...args);
  // }

  /**
   * @public
   * @return {object} Returns a duplicate of the store.
   */
  // eslint-disable-next-line new-cap
  dup() {
    const Constructor = this.storeType;
    return new Constructor(this.store);
  }
}
