import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

import Theme from '+/instances/Theme';
import NavLink from '+/components/Links/NavLink';
import media from '+/utils/media';
import themeVars, { ifProp } from '+/utils/themeVars';

import PropTypes from '../instances/propTypesInstance';

const StyledHeader = {};

const Container = styled.header`
  align-items: center;
  display: flex;
  height: ${themeVars('headerHeight')};
  justify-content: space-between;
  left: 0;
  margin-top: -${ifProp('sticky', '0', themeVars('headerHeight'))};
  position: ${ifProp('sticky', 'fixed', 'relative')};
  top: 0;
  width: 100%;
  z-index: ${themeVars('headerZIndex')};

  background: ${themeVars('headerBackground')};

  color: ${themeVars('colorWhite')};
`;

StyledHeader.Container = ({ children, sticky }) => (
  <Theme dark>
    <Container sticky={sticky}>{children}</Container>
  </Theme>
);

StyledHeader.Container.propTypes = {
  children: PropTypes.node.isRequired,
  sticky: PropTypes.bool,
};

StyledHeader.Container.defaultProps = {
  sticky: true,
};

StyledHeader.LogoIcon = styled(NavLink)`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 24px;
  z-index: ${themeVars('headerZIndex')};

  color: ${themeVars('colorBrightRed')};

  cursor: pointer;

  transition: transform 0.2s ${themeVars('easeOutExpo')}, opacity 0.2s ${themeVars('easeOutExpo')};

  &:focus {
    outline: none;
  }

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    opacity: 0.75;
    transform: scale(1.1);
    transition-duration: 0s;
  }

  ${media.md`
  left: 50%;
  margin-left: calc(${themeVars('headerLogoWidth')} / -2);
  position: absolute;
  width: ${themeVars('headerLogoWidth')};

  &:hover {
    transform: none;
  }

  &:active {
    transform: none;
    opacity: 1;
  }
  `}

  svg {
    width: 100%;
  }
`;

StyledHeader.LogoMobile = styled(ReactSVG)`
  transform: translateY(2px);

  ${media.md`
  display: none;
  `}
`;

StyledHeader.LogoDesktop = styled(ReactSVG)`
  display: none;
  transform: none;
  height: ${themeVars('headerLogoHeight')};
  width: ${themeVars('headerLogoWidth')};

  div {
    height: 100%;
    width: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${media.md`
  display: block;
  `}
`;

StyledHeader.BurgerMenu = styled.div`
  flex-grow: 0;
  height: 100%;
  position: relative;
  width: ${themeVars('burgerWidth')};
  z-index: ${themeVars('headerZIndex')};

  color: ${themeVars('colorWhite')};

  cursor: pointer;

  ${media.sm`
  width: ${themeVars('burgerWidthLarge')};
  `}
`;

StyledHeader.BurgerFilling = styled.div`
  left: 50%;
  margin: -1px 0 0;
  opacity: 0.6;
  top: 50%;

  transform: translateX(-50%);
  transition: opacity 0.1s ease-out;

  &,
  &:before,
  &:after {
    height: 2px;
    position: absolute;
    width: 20px;

    background: currentColor;

    content: '';
  }

  &:before {
    transform: translateY(-400%);
  }

  &:after {
    transform: translateY(400%);
  }

  ${StyledHeader.BurgerMenu}:hover & {
    opacity: 1;
  }
`;

StyledHeader.StatBlock = styled.div`
  flex-grow: 1;
  text-align: right;

  &:last-child {
    padding-right: 1.25rem;
  }
`;

StyledHeader.IconBox = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: ${themeVars('headerHeight')};
  justify-content: center;
  margin: 0;
  padding: ${themeVars('headerItemPadding')};

  color: ${themeVars('colorWhite')};
`;

StyledHeader.Icon = styled(ReactSVG)`
  display: block;
  height: ${themeVars('headerLogoHeight')};
  width: ${themeVars('headerLogoWidth')};
`;

StyledHeader.MessageBox = styled.li`
  align-items: center;
  display: flex;
  margin: ${themeVars('headerItemPadding')};
  margin-right: 10px;

  color: ${ifProp('anyUnseen', themeVars('colorPink'), themeVars('colorWhite'))};

  transition: all 0.3s;

  &:hover {
    transform: scale(1.1);
  }

  a {
    color: inherit;
  }
`;

StyledHeader.FlatLink = styled.li`
  height: ${themeVars('headerHeight')};
  margin: 0;
  padding: ${themeVars('headerItemPadding')};

  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  color: ${themeVars('colorWhite')};
  text-decoration: none;

  cursor: pointer;

  &:hover,
  a.active & {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

StyledHeader.DropDownMenuBox = styled.div`
  margin: 0;
  position: relative;

  color: ${themeVars('colorWhite')};

  text-align: center;

  &.active,
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  ${media.xs` 
  height: ${themeVars('headerHeight')};
  padding: ${themeVars('headerItemPadding')};
`}
`;

StyledHeader.DropDownTitle = styled.div`
  display: none;

  ${media.xs`
  display: inline;
  `}
`;

StyledHeader.DropDownMenu = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 98%;
  width: 100%;

  ${media.xs`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  background: ${themeVars('headerItemColor')};;

${StyledHeader.DropDownMenuBox}:hover & {
  border: 1px solid rgba(0,0,0,.2);
  border-top: none;
  }
`}
`;

StyledHeader.MenuItem = styled.li`
  display: block;
  margin: 0;
  width: 100%;

  ${media.xs`
    display: none;
    padding: 0;
    a {
    padding: .3em;
  }
  `}

  a, span {
    display: block;
    padding: ${themeVars('headerItemPadding')};
    width: 100%;

    color: ${themeVars('colorWhite')};
    text-align: center;

    cursor: pointer;
  }

  a.active,
  &:hover a,
  &:hover span {
    background-color: rgba(0, 0, 0, 0.2);
  }

  ${StyledHeader.DropDownMenuBox}:hover & {
    display: block;
  }
`;

StyledHeader.HiddenTitle = styled.h1`
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  white-space: nowrap;
`;

StyledHeader.IconButton = styled.button`
  align-items: center;
  display: inline-flex;
  flex-grow: 0;
  height: 100%;
  justify-content: center;
  position: relative;
  width: ${themeVars('burgerWidthLarge')};
  z-index: ${themeVars('headerZIndex')};

  color: ${themeVars('colorWhite')};
  opacity: 0.6;

  cursor: pointer;

  transition: opacity 0.1s ease-out;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.75;
    transform: scale(1.1);
    transition-duration: 0s;
  }
`;

export default StyledHeader;
