import React, { PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';

import themeDark from '+/styles/settings/dark/_themeDark';
import themeVibrant from '+/styles/settings/vibrant/_themeVibrant';
import themeLight from '+/styles/settings/default/_themeDefault';
import PropTypes from './propTypesInstance';

class Theme extends PureComponent {
  render() {
    const { dark, vibrant, light, children } = this.props;
    const wrapped = [dark && themeDark, vibrant && themeVibrant, light && themeLight]
      .filter(a => a)
      .reduce((accum, themeName) => (
        <ThemeProvider theme={themeName}>
          {accum}
        </ThemeProvider>
      ), children);
    return wrapped;
  }
}

Theme.propTypes = {
  dark: PropTypes.bool,
  vibrant: PropTypes.bool,
  light: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Theme.defaultProps = {
  dark: null,
  vibrant: null,
  light: null,
};

export default Theme;
