import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import useCurrentUser from '+/utils/hooks/useCurrentUser';
import * as actions from '+/actions';

import { ActionFeed } from './ActionFeed.component';

const mapDispatchToProps = dispatch => ({
  removeEligibleAction: (actionType, actionId) => {
    dispatch(actions.removeEligibleAction(actionType, actionId));
  },
  completeEligibleAction: (actionType, actionId) => {
    dispatch(actions.completeEligibleAction(actionType, actionId));
  },
  uncompleteEligibleAction: (actionType, actionId) => {
    dispatch(actions.uncompleteEligibleAction(actionType, actionId));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(
  withRouter(props => {
    const currentUser = useCurrentUser();

    return <ActionFeed {...props} currentUser={currentUser} />;
  }),
);
