/**
 * Exports PushSubscription Store class
 * @module PushSubscriptionsStore
 */

import FrontEndStore from './FrontEndStore';
import PushSubscriptionModel from './PushSubscription';

export default class PushSubscriptionsStore extends FrontEndStore {
  constructor(pushSubscriptionData) {
    super();
    this.status = pushSubscriptionData ? 'loaded' : 'unloaded';
    this.store = (
      (pushSubscriptionData && pushSubscriptionData.store) ||
      pushSubscriptionData ||
      []
    ).map(ps => new PushSubscriptionModel(ps));
    this.storeType = PushSubscriptionsStore;
  }

  includesEnabledEndpoint(endpoint) {
    if (!endpoint) return false;
    return this.store.some(ps => ps.enabled && ps.subscription.endpoint === endpoint);
  }
}
