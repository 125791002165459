import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPinterest,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

import StyledFooter from '+/styledComponents/StyledFooter';
import TextReader from '+/utils/TextReader';

const { t } = new TextReader('footer');

const Footer = () => (
  <StyledFooter.Footer>
    <StyledFooter.Container>
      <StyledFooter.Social>
        {t('social.twitterUrl') && (
          <StyledFooter.SocialItem>
            <StyledFooter.SocialAnchor
              href={t('social.twitterUrl')}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaTwitter />
            </StyledFooter.SocialAnchor>
          </StyledFooter.SocialItem>
        )}
        {t('social.facebookUrl') && (
          <StyledFooter.SocialItem>
            <StyledFooter.SocialAnchor
              href={t('social.facebookUrl')}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </StyledFooter.SocialAnchor>
          </StyledFooter.SocialItem>
        )}
        {t('social.linkedInUrl') && (
          <StyledFooter.SocialItem>
            <StyledFooter.SocialAnchor
              href={t('social.linkedInUrl')}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn />
            </StyledFooter.SocialAnchor>
          </StyledFooter.SocialItem>
        )}
        {t('social.youTubeUrl') && (
          <StyledFooter.SocialItem>
            <StyledFooter.SocialAnchor
              href={t('social.youTubeUrl')}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <FaYoutube />
            </StyledFooter.SocialAnchor>
          </StyledFooter.SocialItem>
        )}
        {t('social.instagramUrl') && (
          <StyledFooter.SocialItem>
            <StyledFooter.SocialAnchor
              href={t('social.instagramUrl')}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </StyledFooter.SocialAnchor>
          </StyledFooter.SocialItem>
        )}
      </StyledFooter.Social>
      <StyledFooter.LinkList>
        <StyledFooter.LinkListItem
          onClick={() => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}
          onKeyPress={e =>
            e.key === 'enter' && window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
          }
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{t('cookiePreferencesText')}</a>
        </StyledFooter.LinkListItem>
        <StyledFooter.LinkListItem>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={t('privacyLinkUrl')} target="_blank" rel="noopener">
            {t('privacyLinkText')}
          </a>
        </StyledFooter.LinkListItem>
        <StyledFooter.LinkListItem>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={t('termsLinkUrl')} target="_blank" rel="noopener">
            {t('termsLinkText')}
          </a>
        </StyledFooter.LinkListItem>
        <StyledFooter.LinkListItem>
          <a href={t('feedbackLinkUrl')} target="_blank" rel="noopener noreferrer">
            {t('feedbackLinkText')}
          </a>
        </StyledFooter.LinkListItem>
      </StyledFooter.LinkList>
      <StyledFooter.Copyright>{t('copyrightText')}</StyledFooter.Copyright>
    </StyledFooter.Container>
  </StyledFooter.Footer>
);

export default Footer;
