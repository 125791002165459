import React from 'react';
import { FaCog, FaHeart, FaPowerOff, FaRegCommentDots, FaUserCircle } from 'react-icons/fa';
import { AnimatePresence, motion } from 'framer-motion';
import { Swipeable } from 'react-swipeable';

import NavLink from '+/components/Links/NavLink';
import TextReader from '+/utils/TextReader';
import { path } from '+/utils/pathFinder';
import appConfig from '+/appConfig';
import PropTypes from '+/instances/propTypesInstance';
import StyledSlideOutMenu from '+/styledComponents/StyledSlideOutMenu';

import { Avatar } from '../Avatar';

const { t } = new TextReader('slideOutMenu');

const overlayVariants = {
  hidden: { opacity: 0, transition: { delay: 0.1, duration: 0.2 } },
  visible: { opacity: 1, transition: { duration: 0.2 } },
};

const menuVariants = {
  hidden: { x: -314, transition: { duration: 0.33, ease: [0.16, 1, 0.3, 1], bounce: 0 } },
  visible: {
    x: 0,
    transition: { delay: 0.1, duration: 0.33, ease: [0.16, 1, 0.3, 1], bounce: 0 },
  },
};

export const SlideOutMenu = ({
  currentUser,
  userBadges,
  isOpen,
  toggleCondensed,
  areUnseenMessages,
  handleShowAwardDetails,
}) => (
  <>
    <AnimatePresence>
      {isOpen && (
        <StyledSlideOutMenu.Overlay
          onClick={() => toggleCondensed()}
          variants={overlayVariants}
          as={motion.div}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.2 }}
        />
      )}
    </AnimatePresence>
    <AnimatePresence>
      {isOpen && (
        <StyledSlideOutMenu.Container
          variants={menuVariants}
          as={motion.div}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Swipeable onSwipedLeft={() => toggleCondensed()} trackMouse>
            <StyledSlideOutMenu.Header>
              <NavLink to={path('home')}>
                <StyledSlideOutMenu.Logo src={appConfig.headerLogo} alt={appConfig.siteTitle} />
              </NavLink>
            </StyledSlideOutMenu.Header>
            <StyledSlideOutMenu.Body>
              <StyledSlideOutMenu.User>
                <Avatar
                  badge={userBadges[userBadges.length - 1]}
                  userName={currentUser.name()}
                  handleShowAwardDetails={handleShowAwardDetails}
                />
                <StyledSlideOutMenu.UserName>{currentUser.name()}</StyledSlideOutMenu.UserName>
              </StyledSlideOutMenu.User>
              <StyledSlideOutMenu.Main>
                <StyledSlideOutMenu.MainList>
                  <StyledSlideOutMenu.MainListItem>
                    <NavLink to={path('home')} exact>
                      {t('homeLinkText')}
                    </NavLink>
                  </StyledSlideOutMenu.MainListItem>
                  {currentUser.isAdmin() && (
                    <>
                      <StyledSlideOutMenu.MainListItem>
                        <NavLink to={path('admin.index')} exact>
                          {currentUser.isJustAuthor()
                            ? t('authorManageLinkText')
                            : t('manageLinkText')}
                        </NavLink>
                      </StyledSlideOutMenu.MainListItem>
                      <StyledSlideOutMenu.MainListItem>
                        <NavLink to={path('admin.actions.new')} exact>
                          {t('newActionLinkText')}
                        </NavLink>
                      </StyledSlideOutMenu.MainListItem>
                      <StyledSlideOutMenu.MainListItem>
                        <NavLink to={path('admin.messages.index')} exact>
                          {t('messagesLinkText')}
                          {areUnseenMessages && '*'}
                        </NavLink>
                      </StyledSlideOutMenu.MainListItem>
                    </>
                  )}
                  {appConfig.donateUrl && (
                    <StyledSlideOutMenu.MainListItem donate>
                      <a href={appConfig.donateUrl} target="_blank" rel="noopener noreferrer">
                        <FaHeart /> {t('donateLinkText')}
                      </a>
                    </StyledSlideOutMenu.MainListItem>
                  )}
                </StyledSlideOutMenu.MainList>
              </StyledSlideOutMenu.Main>
              <StyledSlideOutMenu.Hr />
              <StyledSlideOutMenu.Utility>
                <StyledSlideOutMenu.UtilityList>
                  <StyledSlideOutMenu.UtilityListItem>
                    <NavLink to={path('account')}>
                      <StyledSlideOutMenu.UtilityIcon>
                        <FaUserCircle />
                      </StyledSlideOutMenu.UtilityIcon>
                      {t('accountLinkText')}
                    </NavLink>
                  </StyledSlideOutMenu.UtilityListItem>
                  <StyledSlideOutMenu.UtilityListItem>
                    <NavLink to={path('preferences')}>
                      <StyledSlideOutMenu.UtilityIcon>
                        <FaCog />
                      </StyledSlideOutMenu.UtilityIcon>
                      {t('settingsLinkText')}
                    </NavLink>
                  </StyledSlideOutMenu.UtilityListItem>
                  <StyledSlideOutMenu.UtilityListItem>
                    <a href={t('feedbackLinkUrl')} target="_blank" rel="noopener noreferrer">
                      <StyledSlideOutMenu.UtilityIcon>
                        <FaRegCommentDots />
                      </StyledSlideOutMenu.UtilityIcon>
                      {t('feedbackLinkText')}
                    </a>
                  </StyledSlideOutMenu.UtilityListItem>
                  <StyledSlideOutMenu.UtilityListItem>
                    <NavLink to={path('signOut')}>
                      <StyledSlideOutMenu.UtilityIcon>
                        <FaPowerOff />
                      </StyledSlideOutMenu.UtilityIcon>
                      {t('logoutLinkText')}
                    </NavLink>
                  </StyledSlideOutMenu.UtilityListItem>
                </StyledSlideOutMenu.UtilityList>
              </StyledSlideOutMenu.Utility>
            </StyledSlideOutMenu.Body>
          </Swipeable>
        </StyledSlideOutMenu.Container>
      )}
    </AnimatePresence>
  </>
);

SlideOutMenu.propTypes = {
  currentUser: PropTypes.models.User.isRequired,
  toggleCondensed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  areUnseenMessages: PropTypes.bool.isRequired,
  userBadges: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  handleShowAwardDetails: PropTypes.func,
};

SlideOutMenu.defaultProps = {
  isOpen: false,
  handleShowAwardDetails: null,
};
