// ====================================================================
// Theme Settings (CSS)
// ====================================================================
// /
// --------------------------------------------------------------------
// This file should only contain SCSS VARIABLES to be used within
// theme styles and styledComponents.
//
// It extends _themeCustom and is extended by _themeDarkCustom.
// --------------------------------------------------------------------

// Colors
// --------------------------------------------------------------------

// Sections
// --------------------------------------------------------------------

export default variables => ({
  ...variables,
  bodyBackground: variables.colorDarkGrey,
  headerBackground: variables.colorDarkGrey,

  textColorDefault: variables.colorWhite,
  // colorBackgroundShade: variables.colorLightGrey,

  // Links
  // --------------------------------------------------------------------

  linkInTextColor: variables.colorPrimaryInvert,
  linkInTextColorHover: variables.colorPrimaryInvert,

  linkStandaloneColor: variables.colorPrimaryInvert,
  // Inputs
  //---------------------------------------------------------------------

  labelColorError: variables.colorWhite,

  // Tab
  tabColorSelected: variables.colorWhite,
  tabColorUnselected: variables.colorWhite,
  tabColorUnselectedHover: variables.colorWhite,
  tabBackgroundSelected: variables.colorBackgroundShade,
  tabBackgroundUnselected: variables.colorMediumGrey,
  tabBackgroundUnselectedHover: variables.colorMediumLightGrey,
  tabColorAccent: variables.colorAccent,
});
